import { chAxios } from '@/shared/lib/axios';

export async function fetchSiteMessageByName(name: string) {
  const url = `/site-message/?name=${name}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function fetchSiteCarouselPages(group: string) {
  const url = `/site-carousel/?group=${group}`;
  const { data } = await chAxios().get(url);
  return data;
}
