<template>
  <div>
    <div class="d-flex" :class="isReply ? 'ml-2' : ''">
      <CommenterImage
        v-if="!isEdit"
        class="mr-2 clickable"
        @click.prevent="openPostAsList"
        :type="get(lastPostAs, 'type') === postedAsTypes.USER ? 'user' : 'character'"
        size="md"
        :src="
          resizeUpload(
            get(lastPostAs, 'type') === postedAsTypes.USER
              ? get(user, 'profile_picture_url')
              : get(lastPostAs, 'item.info.cropProfilePicture'),
            '80x80'
          )
        "
        :ring="
          get(lastPostAs, 'type') === postedAsTypes.USER
            ? get(user, 'customize_profile.profile_ring.image')
            : get(lastPostAs, 'item.profile_ring.image')
        "
        :ringWidth="60"
        :top="-5"
      />

      <div class="w-100">
        <div v-if="!opendetails">
          <ion-textarea
            ref="textbox"
            v-model="blabReply.text"
            rows="3"
            class="c-textarea mb-1"
            placeholder="Type your message here..."
            required
          />
        </div>

        <div v-else>
          <ion-textarea
            ref="textbox2"
            v-model="blabReply.text"
            rows="3"
            class="c-textarea mb-1"
            placeholder="Type your message here..."
            required
          />
        </div>

        <div class="d-flex replying" :class="isMobSmallScreen ? 'flex-column' : 'align-items-center'">
          <div v-if="!isEdit">
            Replying as
            <span :class="{ 'text-primary': !isPostAsUser }">{{ isPostAsUser ? 'yourself' : lastPostAsName }}</span
            >.
            <a href="#" @click.prevent="openPostAsList"
              ><b
                ><i>{{ switchPostAsText }}</i></b
              ></a
            >
          </div>
          <div class="d-flex ml-auto">
            <ion-button
              v-if="isEdit"
              class="publish-btn text-black"
              style="--background: #e6e6e6"
              @click.prevent="onCancel"
              >Cancel</ion-button
            >
            <ion-button
              v-if="get(user, 'is_email_verified', true)"
              class="publish-btn"
              :color="postSuccess ? 'success' : 'primary'"
              :disabled="!isValid || !isAuthenticated || isPosting"
              @click="onPost"
            >
              <i v-if="postSuccess" class="ti-check-box" />
              <ChLoading size="sm" v-else-if="isPosting" class="spinner" />
              <span v-else>{{ isEdit ? 'Update' : 'Comment' }}</span>
            </ion-button>
            <ion-button
              v-if="!isEdit && isReply"
              class="publish-btn text-black"
              style="--background: #e6e6e6"
              @click.prevent="setIsReplying(false)"
              >Cancel</ion-button
            >
            <VerificationButton
              :disabled="!isValid || !isAuthenticated || isPosting"
              :actionName="'Comment'"
              v-if="isEdit"
            />
          </div>
        </div>

        <post-as-select-modal
          :is-open="openPostAsModal"
          @dismiss-modal="closePostAsModal"
          @item-selected="selectPostAs"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios, { AxiosError } from 'axios';
import { Options, Vue } from 'vue-class-component';
import { resizeUpload } from '@/shared/utils/upload';
import { Prop, Watch } from 'vue-property-decorator';
import { toast } from '@/shared/native';
import PostAsSelectModal from '@/shared/modals/PostAsSelectModal.vue';
import { blabReplyMessage, blabUserReplyMessage, editBlabReply } from '@/shared/actions/blabs';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { uiStore } from '@/shared/pinia-store/ui';

@Options({
  name: 'PostChatMessage',
  components: { PostAsSelectModal, CommenterImage, VerificationButton },
})
export default class PostChatMessage extends Vue {
  @Prop({}) blab!: string;
  @Prop({ required: false }) replyToMentionName!: string;
  @Prop({ required: false }) blabEditingTitle!: string;
  @Prop({ required: false }) blabEditingText!: string;
  @Prop({ default: false }) isReply!: boolean;
  @Prop({}) parent!: string;
  @Prop({ default: false }) opendetails!: boolean;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop({ default: false }) editId!: string;
  @Prop({ default: false }) isOpen!: string;

  get = get;
  resizeUpload = resizeUpload;
  postedAsTypes = constants.postAsTypes;
  public postSuccess = false;

  public setIsReplying = (val: boolean) => {
    const { setIsReplying } = uiStore();
    setIsReplying(val);

    if (!val) {
      this.$emit('done');
    }
  };

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get lastPostAsName() {
    const { lastPostAsName } = authStore();
    return lastPostAsName.value;
  }

  public get lastPostAs() {
    const { lastPostAs } = authStore();
    return lastPostAs.value;
  }

  public get isPostAsUser() {
    return !this.lastPostAs || this.lastPostAs.type === 'user';
  }

  public get switchPostAsText() {
    return this.isPostAsUser ? 'Reply as your character instead!' : `Switch who you're replying as.`;
  }

  public isPosting = false;
  public get windowWidth() {
    return window.innerWidth;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 500;
  }

  public get tinyMCEKey() {
    const {
      public: { tinyMCEKey },
    } = useRuntimeConfig();
    return tinyMCEKey;
  }

  public openPostAsModal = false;

  blabReply = {
    title: '',
    text: '',
    id: '',
  };

  public rerenderEditorKey = 0;
  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }

  public onCancel() {
    this.$emit('cancelled');
  }

  @Watch('dark')
  public rerenderTextarea() {
    this.rerenderEditorKey = this.rerenderEditorKey + 1;
  }

  @Watch('isOpen')
  public onIsOpen(val: boolean = false) {
    if (val) {
      const text = this.opendetails ? (this.$refs.textbox2 as any) : (this.$refs.textbox as any);
      const textElWrapper = text.$el as any;
      textElWrapper.setFocus();
    }
  }

  @Watch('isEdit')
  public onIsEditChanged(val: boolean = false) {
    if (val) {
      this.blabReply.title = '';
      this.blabReply.text = `${this.blabEditingTitle}\n${this.blabEditingText}`;
    } else {
      this.blabReply.title = '';
      this.blabReply.text = '';
    }
  }

  @Watch('replyToMentionName')
  public onIsMentionNameChanged() {
    if (this.replyToMentionName) {
      this.blabReply.text = `@${this.replyToMentionName} `;
    }
  }

  mounted() {
    if (this.isEdit) {
      this.blabReply.title = '';
      this.blabReply.text = `${this.blabEditingTitle ? `${this.blabEditingTitle}\n` : ''}${this.blabEditingText}` || '';
    }
  }

  public async edit() {
    await editBlabReply(this.parent, {
      title: this.blabReply.title,
      text: this.blabReply.text,
    });
    await toast.show('Message updated', 'nonative', 'success');
    this.$emit('send', {
      id: this.parent,
      title: this.blabReply.title,
      text: this.blabReply.text,
    });
  }

  public async onPost() {
    this.isPosting = true;
    const { lastPostAsName, lastPostAs } = authStore();
    if (this.isEdit) return this.edit();
    try {
      if (this.isReply) {
        if (lastPostAsName.value === this.user.username) {
          const resp = await blabUserReplyMessage(this.blab, this.blabReply.title, this.blabReply.text, this.parent);
          this.$emit('send', this.parent);
        } else {
          const resp = await blabReplyMessage(
            this.blab,
            this.blabReply.title,
            this.blabReply.text,
            lastPostAs.value.id,
            this.parent
          );

          this.$emit('send', this.parent);
        }
      } else if (lastPostAsName.value === this.user.username) {
        const resp = await blabUserReplyMessage(this.blab, this.blabReply.title, this.blabReply.text);
        this.$emit('posted');
        this.$emit('count');
        this.$emit('open');
      } else {
        const resp = await blabReplyMessage(this.blab, this.blabReply.title, this.blabReply.text, this.lastPostAs.id);
        this.$emit('posted');
        this.$emit('count');
        this.$emit('open');
      }

      this.postSuccess = true;
      setTimeout(() => (this.postSuccess = false), 2000);
      this.blabReply.title = '';
      this.blabReply.text = '';
    } catch (_e) {
      if (axios.isAxiosError(_e)) {
        const ae = _e as AxiosError;
        if (ae.response?.status === 403) {
          toast.show('Please pick another identity to post under.', 'nonative', 'primary');
          return this.openPostAsList();
        }
      }
      toast.show('Cannot send message. Please try again.', 'nonative', 'danger');
    } finally {
      this.isPosting = false;
    }
  }

  public selectPostAs(data: { id: string; type: string; item: {} }) {
    const { updateLastPostAs } = authStore();
    updateLastPostAs({
      lastPostAs: data,
      lastPostAsName:
        data.type === constants.postAsTypes.USER
          ? get(data.item, 'username', 'Unknown')
          : get(data.item, 'info.name', 'Unknown'),
    });
  }

  public openPostAsList() {
    if (this.isEdit) return toast.show('Cannot change identity while editing', 'nonative', 'primary');
    this.openPostAsModal = true;
  }

  public get isValid() {
    return !!this.blabReply.text;
  }

  public get actionName() {
    return this.isEdit ? 'Update' : 'Comment';
  }

  public closePostAsModal() {
    this.openPostAsModal = false;
  }

  public async created() {
    const { updateLastPostAs, lastPostAsName, lastPostAs } = authStore();
    if (!lastPostAs.value || !lastPostAsName.value) {
      updateLastPostAs({
        lastPostAs: { id: this.user.id, type: constants.postAsTypes.USER, item: cloneDeep(this.user) },
        lastPostAsName: this.user.username,
      });
    }

    if (this.replyToMentionName) {
      this.blabReply.text = `@${this.replyToMentionName} `;
    }
  }
}
</script>

<style lang="sass" scoped>
.replying
  font-size: 13px
.dark .text-black
  color: black !important
.img
  width: 40px
  height: 40px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover

.img-details
  width: 50px
  height: 50px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover

.publish-btn
  width: 100px
  height: 26px
  --border-radius: 20px
  .spinner
    width: 15px
    height: 15px
</style>
