import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';
import type { Paging } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function follow(followingType: string, followingId?: string, type?: string) {
  const { data } = await chAxios().post(`/follow/`, {
    following_type: followingType,
    following_id: followingId,
    type,
  });
  sendAnalyticsEvent('Followed', { type, followingType, followingId });
  return data;
}

export async function createCheer(activityId: string) {
  const { data } = await chAxios().post(`/follow/cheer/`, {
    activity: activityId,
  });
  sendAnalyticsEvent(`Created Cheer`);
  return data;
}

export async function getCheerCounts(activityIds: string[]) {
  const url = `/follow/cheer/cheer_count/`;
  const { data } = await chAxios().post(url, { activity_ids: activityIds });
  return data;
}

export async function updateFollowType(
  id: string,
  followData: {
    type: string;
  }
) {
  const url = `/follow/${id}/type-update/`;
  const { data } = await chAxios().patch(url, followData);
  sendAnalyticsEvent('Updated follow status');
  return data;
}

export async function getFeed(page = 1, pageSize = constants.defaultPageSize) {
  const url = `/follow/home-follow-feeds/`;
  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
  return data;
}

export async function getUserFeed(user_id: string, page = 1) {
  const url = `/follow/user-feeds/`;
  const { data } = await chAxios().get(url, { params: { user_id, page } });
  return data;
}

export async function getCheerChart() {
  const url = `/follow/cheer/cheer-chart/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function unfollowUser(id: string) {
  const { data } = await chAxios().delete(`/follow/${id}/unfollow/`);
  sendAnalyticsEvent('Unfollowed User');
  return data;
}

export async function getStatusOfFollowing(following_id?: string, following_type?: string) {
  const url = `/follow/status/`;
  const { data } = await chAxios().get(url, { params: { following_id, following_type } });
  return data;
}

export async function getFeedPage(params = {}) {
  const url = `/feeds/v1/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getFeedPageV2(params = {} as any) {
  const url = `/feeds/v1/`;
  params['version'] = 2;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getRecommendedFeedPage(params = {}) {
  const url = `/feeds/fm/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getPinnedTopics() {
  const url = `/tags/pinned/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getTopContributors(params = {}) {
  const url = `/tags/users/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getTopChatrooms(params = {}) {
  const url = `/tags/chatrooms/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function searchTopics(params = {}) {
  const url = `/tags/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getTrendingTopics() {
  const url = `/tags/trending/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function pinTopic(payload: any) {
  const url = `/tags/follow/`;
  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function pinTopics(listOfTagNames: any) {
  const url = `/tags/follow-many/`;
  const { data } = await chAxios().post(url, { tags: listOfTagNames });
  return data;
}

export async function unPinTopic(payload: any) {
  const url = `/tags/unfollow/`;
  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function reOrderTopics(payload: any) {
  const url = `/tags/reorder-pinned/`;
  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function getFollowing(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/follow/my-following/`;
  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
  return data;
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getFollowers(user_id: string, page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/follow/my-followers/`;
  const { data } = await chAxios().get(url, { params: { user_id, page, page_size: pageSize, ...params } });
  return data;
}
