<template>
  <div class="collapsable-section" :style="{ border: sectionColor }">
    <ion-accordion-group ref="accordionGroup" :value="accordionValue">
      <ion-accordion value="first">
        <ion-item slot="header" color="light" :class="{ dark }">
          <ion-label class="ion-text-wrap">{{ title }} </ion-label>

          <div
            class="clickable-item-hov"
            v-if="item === showBoxItem && !hideGiftBox && featureFlags.currency && showBox"
            @click.stop.once
          >
            <currencyTransBox
              @delete="deleteCurrGiftBox"
              @updated="deleteCurrGiftBox"
              :progressId="progressId"
              :is-gift-box="true"
            />
          </div>
        </ion-item>

        <div slot="content" class="ion-padding content" tabIndex="-1">
          <slot />
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </div>
</template>

<script lang="ts" setup>
import { characterProfileStore, SectionsCollapsed } from '@/shared/pinia-store/character-profile';
import { mainStore } from '@/shared/pinia-store/main';
import currencyTransBox from '@/shared/components/currencyTransGiftBox.vue';
import { featureFlags } from '@/shared/config/feature-flags';

const props = defineProps({
  title: {
    type: String,
  },
  customize: {
    type: Object,
    default: {},
  },
  item: {
    type: String,
    default: '',
  },
  showBox: {
    type: Boolean,
    default: false,
  },
  progressId: {
    type: String,
    default: '',
  },
  showBoxItem: {
    type: String,
    default: '',
  },
});

const { isTempDefault } = useCustTempDefault(props);

const title = toRef(props, 'title');
const customize = toRef(props, 'customize');
const hideGiftBox = ref(false);
const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const deleteCurrGiftBox = () => {
  hideGiftBox.value = true;
};
const { sectionsCollapsed } = characterProfileStore();
const accordionGroup = ref(null);

const accordionValue = computed(() => (!sectionsCollapsed.value ? 'first' : undefined));

const collapse = (value: number) => {
  if (!accordionGroup.value) {
    return;
  }

  const nativeEl = (accordionGroup.value as any).$el;

  nativeEl.value = value ? 'first' : undefined;
};

watch(sectionsCollapsed, (value: SectionsCollapsed) => {
  collapse(value);
});

const sectionEdges = computed(() => {
  if (get(customize.value, 'data.theme.styles.sectionEdges') === 'sharp') return '0px';
  else if (get(customize.value, 'data.theme.styles.sectionEdges') === 'round') return '10px';
  return '20px';
});
const titlesTextBold = computed(() => {
  return get(customize.value, 'data.theme.text.titles.bold') ? 'bold' : 'normal';
});

const titlesTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.titles.italics') ? 'italic' : 'normal';
});

const titleTextColor = computed(() => {
  return isTempDefault.value && dark.value ? '#FFFFFF' : get(customize.value, 'data.theme.text.titles.color');
});
const titlesTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.titles.font') || 'Roboto';
});
const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font') || 'Roboto';
});

const infoSectionColor = computed(() => {
  const defaultClr = dark.value ? '#17074C' : '#E6E6E6';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const defaultTextColor = computed(() => {
  const defaultClr = dark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});

const defaultTextBold = computed(() => {
  return isTempDefault.value ? 'normal' : get(customize.value, 'data.theme.text.default.bold') ? '800' : 'normal';
});

const sectionShadow = computed(() => {
  return get(customize.value, 'data.theme.styles.infoSections.shadow') === 'none'
    ? '0px 0px 0px'
    : get(customize.value, 'data.theme.styles.infoSections.shadow') === 'alittle'
    ? '0 2px 13px rgb(0 0 0 / 15%)'
    : '3px 2px 20px #635d5d';
});

const sectionColor = computed(() => {
  const border = get(customize.value, 'data.theme.styles.infoSections.border', 'none');
  const color = get(customize.value, 'data.theme.styles.infoSections.color');
  return `3px ${border} ${color} !important`;
});

const defaultTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

onMounted(() => {
  collapse(sectionsCollapsed.value);
});
</script>
<style lang="sass" scoped>
.ion-text-wrap
  @media(max-width: 500px)
    font-size: 18px !important
.collapsable-section
  border-radius: v-bind(sectionEdges)
  --background: #E6E6E6
  --ion-color-base: #E6E6E6
  box-shadow: v-bind(sectionShadow)

  ion-accordion.accordion-expanded > [slot=header] .ion-accordion-toggle-icon,
  ::v-deep ion-accordion.accordion-expanded > [slot=header] .ion-accordion-toggle-icon
    color: v-bind(titleTextColor) !important
  ion-accordion.accordion-animated > [slot=header] .ion-accordion-toggle-icon,
  ::v-deep ion-accordion.accordion-animated > [slot=header] .ion-accordion-toggle-icon
    color: v-bind(titleTextColor) !important
    @media(max-width: 500px)
      font-size: 20px !important
  ion-item
    --inner-padding-end: 15px !important
    --inner-padding-start: 5px !important
    --inner-padding-top: 5px !important
    --inner-padding-bottom: 5px !important
    --ion-color-base: v-bind(infoSectionColor) !important
    font-size: 24px
    font-weight: 800
    color: #333333
    --background: v-bind(infoSectionColor) !important
    &.dark
      --ion-color-base: v-bind(infoSectionColor) !important
      --background: v-bind(infoSectionColor) !important
  overflow: hidden
  .content
    background: v-bind(infoSectionColor) !important
    padding: 5px 22px 15px 22px
    font-size: 16px
    color: v-bind(defaultTextColor) !important
    font-weight: v-bind(defaultTextBold)
    font-style: v-bind(defaultTextItalic)
    font-family: v-bind(defaultTextFont) !important
    &.dark
      background: v-bind(infoSectionColor) !important
      color: v-bind(defaultTextColor) !important
      font-weight: v-bind(defaultTextBold)
      font-style: v-bind(defaultTextItalic)
      font-family: v-bind(defaultTextFont) !important

  ion-label
    line-height: 1.25em
    color: v-bind(titleTextColor)
    font-weight: v-bind(titlesTextBold)
    font-style: v-bind(titlesTextItalic)
    font-family: v-bind(titlesTextFont)
    &.dark
      color: v-bind(titleTextColor)
.dark .content
  background: v-bind(infoSectionColor) !important
.dark .collapsable-section .content
  background: v-bind(infoSectionColor) !important
</style>
