<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100 px-4" style="overflow: auto">
        <form @submit.prevent="onSubmit">
          <div class="mb-2">
            <strong>Message</strong>
          </div>
          <ion-textarea
            v-model="request.message"
            rows="8"
            class="c-textarea mb-3"
            :placeholder="`Say more about why you chose ${get(
              character,
              'info.name',
              'this character'
            )} to roleplay with. `"
            required
          />

          <div class="w-100 d-flex justify-content-center">
            <ion-button class="submit-btn" :disabled="isSubmitting || !isFormComplete" type="submit">
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { acceptReqRolechatStarter } from '@/shared/actions/rolechats';
import { toast } from '@/shared/native';

@Options({
  name: 'RequestRolechatModal',
})
export default class RequestRolechatModal extends Vue {
  @Prop({ default: () => 'Request Roleplay', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ required: true }) character!: any;

  get = get;

  isSubmitting = false;
  areCharsLoading = false;
  request = { message: '' };

  public get isFormComplete() {
    return !!this.request.message;
  }

  public get isFormLoading() {
    return this.isSubmitting || this.areCharsLoading;
  }

  public dismissModal() {
    this.$emit('dismissModal');
    this.request.message = '';
    this.isSubmitting = false;
  }

  public async onSubmit() {
    if (!this.isFormComplete) return;

    this.isSubmitting = true;

    try {
      const resp = await acceptReqRolechatStarter({ message: this.request.message, character: this.character.id });
      await toast.show(`Request sent. Rolechat will start as soon as it's accepted.`, 'nonative', 'success');
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }

    this.dismissModal();
    this.isSubmitting = false;
  }

  mounted() {}
}
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  @media(max-width: 599px) and (max-height: 767px)
    --height: 85%
    --width: 85%
</style>
