import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared//lib/axios';

export async function getShopVirtualItems(params = {}) {
  const url = `/currency/virtual-items/shop/`;
  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function getVirtualItemsCurrencyConfig() {
  const url = `/currency/virtual-items/currency-config/`;
  const { data } = await chAxios().get(url);
  return data;
}


export async function getVirtualItemDetails(id?: any) {
  const url = `/currency/virtual-items/${id}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getFeaturedVirtualItems(params = {}) {
  const url = `/currency/virtual-items/top/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getVirtualItems(params = {}) {
  const url = `/currency/virtual-items/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getVirtualItemsCategories(params = {}) {
  const url = `/currency/virtual-items/categories/`;
  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function purchaseVirtualItem(itemId: string) {
  const url = `/currency/virtual-items/purchase/`;
  const { data } = await chAxios().post(url, { item_id: itemId });
  sendAnalyticsEvent('purchased an item');
  return data;
}
