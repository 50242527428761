// In your file where useImageTuple is defined
export const useImageTuple = (tup: any) => {
  const gemSvgFromNumber = (num: number) => {
    if (num < 1050) {
      return '/store/gp1.svg';
    } else if (num < 2200) {
      return '/store/gp2.svg';
    } else if (num < 4600) {
      return '/store/gp3.svg';
    } else if (num < 12000) {
      return '/store/gp4.svg';
    } else if (num < 25000) {
      return '/store/gp5.svg';
    } else if (num < 38250) {
      return '/store/gp6.svg';
    } else if (num < 55000) {
      return '/store/gp7.svg';
    } else if (num >= 55000) {
      return '/store/gp8.svg';
    }
    return '/gem.svg';
  };
  const hubuxSvgFromNumber = (num: number) => {
    return '/hubuck.svg';
  };
  const imageFromTuple = () => {
    const [type, amt] = tup;

    if (type === 'amethyst') {
      return gemSvgFromNumber(amt);
    } else if (type === 'cbomb') {
      return '/store/cbomb.svg';
    } else if (type === 'hubux') {
      return hubuxSvgFromNumber(amt);
    }
    return null;
  };
  return { imageFromTuple };
};
