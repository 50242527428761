<template>
  <div class="w-100 position-relative">
    <div
      ref="scrollEl"
      @scroll="scroll"
      class="py-2 wrapper d-flex flex-wrap align-items-center w-100"
      :class="{ scrollSm, scrollMd, scrollLg, scrollXl, [layout]: layout }"
    >
      <slot />
      <ion-button @click="scrollToNext" v-if="showRightArrow" color="dark" class="arrow-btn right position-absolute">
        <inline-svg src="/icons/arrow-right.svg" />
      </ion-button>
      <ion-button @click="scrollToStart" v-if="showLeftArrow" color="dark" class="arrow-btn position-absolute">
        <inline-svg src="/icons/arrow-right.svg" class="rotate" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>


const props = defineProps({
  layout: {
    default: 'grid',
    type: String,
  },
  scrollSm: {
    type: Boolean,
    default: false,
  },
  scrollMd: {
    type: Boolean,
    default: false,
  },
  scrollLg: {
    type: Boolean,
    default: false,
  },
  scrollXl: {
    type: Boolean,
    default: false,
  },
  arrows: {
    type: Boolean,
    default: false,
  },
  spacing: {
    default: 0,
  },
  lg: {
    default: 3,
  },
  xl: {
    default: 3,
  },
  md: {
    default: 3,
  },
  sm: {
    default: 3,
  },
});

const halSpacing = computed(() => `${props.spacing || 6}px`);
const valSpacing = computed(() => `${props.spacing || 6}px`);
const halfSpacing = computed(() => `${props.spacing / 2 || 5}px`);

const { width } = useWindowSize();

const isScrollable = computed(() => {
  const scrollOnSm = width.value < 520 && props.scrollSm;
  const scrollOnMd = width.value < 768 && width.value > 520 && props.scrollMd;
  const scrollOnLg = width.value > 768 && width.value < 1184 && props.scrollLg;
  const scrollOnXl = width.value > 1184 && props.scrollXl;

  return scrollOnSm || scrollOnMd || scrollOnLg || scrollOnXl;
});

const widthModifierForScroll = computed(() => {
  if (isScrollable.value) {
    return 3;
  }

  return 0;
});

const scrollEl = ref<HTMLElement | null>(null);
const scrollPos = ref(0);
const scrollWidth = ref(0);
const hasMore = computed(() => scrollWidth.value - scrollPos.value > 50);
const hasLess = computed(() => scrollPos.value > 50);

const scrollToStart = () => {
  if (!scrollEl.value) return;

  scrollEl.value.scrollBy({ left: -999, behavior: 'smooth' });
};

const scrollToNext = () => {
  if (!scrollEl.value) return;

  scrollEl.value.scrollBy({ left: 200, behavior: 'smooth' });
};

const showRightArrow = computed(() => hasMore.value && props.arrows);
const showLeftArrow = computed(() => hasLess.value && props.arrows);

onMounted(() => {
  if (!scrollEl.value) return;

  scrollEl.value;
  scrollWidth.value = scrollEl.value.scrollWidth - scrollEl.value.clientWidth;
});

const scroll = debounce((e: any) => {
  const target = e.target;

  if (!target) return;

  scrollPos.value = e.target.scrollLeft;
});

const widthValue = computed(() => {
  if (width.value < 520) {
    return `${100 / (props.sm || 3) + widthModifierForScroll.value}`;
  }

  if (width.value < 768 && width.value > 520) {
    return `${100 / (props.md || 3) + widthModifierForScroll.value}`;
  }

  if (width.value > 768 && width.value < 1184) {
    return `${100 / (props.lg || 3) + widthModifierForScroll.value}`;
  }

  if (width.value > 1184) {
    return `${100 / (props.xl || 3) + widthModifierForScroll.value}`;
  }
});

const widthPercentage = computed(() => `calc(${widthValue.value}% - ${halfSpacing.value})`);
</script>

<style lang="sass" scoped>
.rotate
  transform: rotate(180deg)
.arrow-btn
  width: 24px
  height: 24px
  --border-radius: 20px
  --padding-start: 0
  --padding-end: 0
  --background: rgba(10, 9, 40, 0.8) !important
  --ion-color-dark: rgba(10, 9, 40, 0.8) !important
  &.right
    right: 0
.wrapper
  ::v-deep
    .grid-item
      width: v-bind(widthPercentage)
      min-width: v-bind(widthPercentage)
      align-self: flex-start
.wrapper
  grid-gap: v-bind(halSpacing) v-bind(valSpacing)
  &.scrollSm, .scrollMd, .scrollLg, .scrollXl
    flex-wrap: nowrap !important
    overflow-x: scroll
    overflow-y: auto
    &::-webkit-scrollbar
      display: none
  &.scrollSm
    @media(max-width: 520px)
      flex-wrap: nowrap !important
      overflow-x: scroll
      &::-webkit-scrollbar
        display: none
  &.scrollMd
    @media(min-width: 521px) and (max-width: 768px)
      flex-wrap: nowrap !important
      overflow-x: scroll
      &::-webkit-scrollbar
        display: none
  &.scrollLg
    @media(min-width: 769px) and (max-width: 1184px)
      flex-wrap: nowrap !important
      overflow-x: scroll
      &::-webkit-scrollbar
        display: none
  &.scrollXl
    @media(min-width: 1184px)
      flex-wrap: nowrap !important
      overflow-x: scroll
      &::-webkit-scrollbar
        display: none
  &.list
    flex-direction: column !important
    overflow-x: hidden !important
    overflow-y: auto !important
</style>
