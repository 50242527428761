<template>
  <div
    class="product-card position-relative p-1 clickable-item-hov d-flex justify-content-center flex-column"
    :class="ringCardClass"
    :style="shopCardStyle"
    @click="onCardPress"
  >
    <div v-if="firstPurchaseOffer" class="above mb-1" :style="{ color: vItemColor }">
      On First Purchase<br /><strong>{{ firstPurchaseOffer }}</strong>
    </div>
    <div v-else class="above-inv mb-1">
      <div>&nbsp;<br />&nbsp;</div>
    </div>
    <div class="position-relative d-flex align-items-center justify-content-center mx-auto">
      <img :src="product?.image" class="pic" />
    </div>
    <div :class="{ 'set-direction': !whiteCardColor }" class="mt-auto">
      <div class="align-items-center d-flex text-center justify-content-center position-relative mt-1">
        <div class="truncate">{{ product?.name }}</div>
      </div>
      <div
        class="mt-auto align-items-center d-flex text-center justify-content-center flex-column mx-2 position-relative"
      >
        <div class="d-flex align-items-center mx-auto justify-content-center" v-if="itemInStoreTimer">
          <ion-badge class="timer-badge">
            <vue-countdown
              v-slot="{ days, hours, minutes, seconds }"
              :time="formatFromNowInMilliseconds(itemInStoreTimer)"
              @end="reloadListings"
            >
              <i class="ti-time mr-1 time-icon" />
              <span class="timer">
                <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
              </span>
            </vue-countdown>
          </ion-badge>
        </div>
        <ion-button
          v-if="product?.amount_gems"
          class="align-items-center d-flex text-center justify-content-center item-btn"
          @click.stop="startPurchase"
        >
          <div class="position-relative">
            <inline-svg v-if="product?.amount_gems" src="/gem.svg" class="svg mr-1" />
          </div>
          <span class="amount">{{ formatGems(product?.amount_gems) }}</span>
        </ion-button>
        <ion-button
          v-else-if="product?.amount"
          class="align-items-center d-flex text-center justify-content-center item-btn"
          @click.stop="startPurchase"
        >
          <!-- <i v-if="product?.amount_gems" class="ti-check mr-1" style="color: #6adf32" /> -->
          <span class="amount">${{ ((product?.discounted_amount || product?.amount) / 100).toLocaleString() }}</span>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { openPurchaseModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
import { modalController } from '@ionic/vue';
import { mainStore } from '@/shared/pinia-store/main';
import { StoreProduct } from '@/shared/types/static-types';
import PurchaseSuccessModal from '@/shared/modals/PurchaseSuccessModal.vue';
import SiteHtmlModal from '@/shared/modals/SiteHtmlModal.vue';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { COMPLIMENT_BOMB } from '@/shared/statics/constants';
import { openSpecialBundleShopModal } from '@/shared/utils/modals';

const props = defineProps({
  whiteCardColor: { type: Boolean, default: false },
  product: { type: Object as PropType<StoreProduct> },
  selectedRing: { type: Boolean, default: false },
  selectedUserChar: { type: Object, default: {} },
});
const { width } = useWindowSize();
const emits = defineEmits(['updated', 'reload']);
const whiteCardColor = toRef(props, 'whiteCardColor');

const product = toRef(props, 'product');
const { usersCurrency, getUsersCurrency } = authStore();
const { dark } = mainStore();
const ringCardClass = computed(() => ({
  'feature-clr': whiteCardColor.value,
  'border-clr': !whiteCardColor.value,
}));

const itemInStoreTimer = computed(() => {
  const discountedUntil = get(product.value, 'discounted_until');
  const expiresIn = get(product.value, 'expires_at');
  return discountedUntil ? new Date(discountedUntil) : expiresIn ? new Date(expiresIn) : null;
});

const firstPurchaseOffer = computed(() => {
  const fpo = get(product.value, 'first_purchase_offer');
  if (fpo && product.value?.post_action?.startsWith('amethyst')) {
    // Specific format for gempack offers
    const num =
      +product.value.post_action.split('amethyst')[1] * +fpo.split('x')[0] -
      +product.value.post_action.split('amethyst')[1];
    if (isNaN(num)) return fpo;
    return '+' + num + ' Gems';
  } else {
    return fpo; // otherwise just take the offer verbatim
  }
});

const shopCardStyle = computed(() => ({
  'min-height':
    whiteCardColor.value && width.value >= 400
      ? '228px'
      : whiteCardColor.value && width.value <= 320
      ? '228px'
      : whiteCardColor.value && width.value <= 400
      ? '208px'
      : !whiteCardColor.value && width.value >= 400
      ? '198px'
      : '160px',
}));

const imageWdhAndHgt = computed(() => {
  return width.value >= 650
    ? 120
    : width.value <= 390 && width.value >= 320
    ? 80
    : width.value <= 320 && width.value >= 290
    ? 67
    : width.value <= 290
    ? 54
    : 100;
});

const vItemColor = computed(() => {
  return '#328FD2'; // TODO change based on prop code
});

const reloadListings = () => {
  emits('reload');
};

const onCardPress = async (ev: any) => {
  if (product.value?.description_html || product.value?.description) {
    const res = (await openDescriptionModal(ev)) as any;
    if (res === 'continue') tryStartPurchase(ev);
  } else {
    tryStartPurchase(ev);
  }
};

const openBundleShop = (ev: MouseEvent) => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) return;
  openSpecialBundleShopModal(ev);
};

const tryStartPurchase = (ev: any) => {
  if (product.value?.amount_gems) {
    if (usersCurrency.value.gems >= get(product.value, 'amount_gems')!) {
      startPurchase(ev);
    } else {
      openBundleShop(ev);
    }
  }
  if (product.value?.amount) startPurchase(ev);
};

const startPurchase = (ev: any) => {
  if (product.value?.amount_gems) {
    startPurchaseWithGems(ev);
  } else if (product.value?.amount_coins) {
    alert('Coming soon!');
  } else {
    startPurchaseWithFiat(ev);
  }
};

const formatGems = (amount?: number) => {
  if (!amount) return;
  if (amount >= 1000000) {
    return (Math.floor(amount / 10000) / 100).toFixed(2) + 'M';
  } else {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

const startPurchaseWithGems = async (event: any) => {
  const productCode = product.value?.code;
  const quantity = product.value?.quantity || 1;
  if (!productCode) return;
  await getUsersCurrency();
  const route = useRoute();
  const router = useRouter();
  const currentRouteName = String(route.name);
  const modalDismissRes = (await openBuyWithGemsModal(event, product.value)) as any;
  if (modalDismissRes?.data === COMPLIMENT_BOMB) {
    // thing to do in the background while the post-purchase modal is open
    const action = (await openPurchaseSuccessModal()) as any;
    if (action == 'inventory') {
      return router.push({ path: 'home', query: { modal: 'compliment-bomb-hub' } });
    }
  } else if (modalDismissRes?.data && product.value?.post_action) {
    (await openPurchaseSuccessModal()) as any;
  }
};

const startPurchaseWithFiat = async (event: any) => {
  const productCode = product.value?.code;
  const quantity = product.value?.quantity || 1;
  if (!productCode) return;

  const route = useRoute();
  const router = useRouter();
  const currentRouteName = String(route.name);
  const openedFrom = `${currentRouteName}-buy-${productCode}-${quantity}`;
  const modalDismissRes = (await openPurchaseModal(
    event,
    productCode,
    product.value?.name || '',
    quantity,
    product.value?.post_action,
    openedFrom,
    product.value?.first_purchase_offer
  )) as any;
  if (modalDismissRes?.data === COMPLIMENT_BOMB) {
    // thing to do in the background while the post-purchase modal is open
    const action = (await openPurchaseSuccessModal()) as any;
    if (action == 'inventory') {
      return router.push({ path: 'home', query: { modal: 'compliment-bomb-hub' } });
    }
  } else if (modalDismissRes?.data && product.value?.post_action) {
    if (product.value?.post_action?.includes('amethyst') || product.value?.post_action?.includes('hubux')) {
      // update hubux and amethyst count for user
      await getUsersCurrency();
    }
    (await openPurchaseSuccessModal()) as any;
  }
};

const openPurchaseSuccessModal = async () => {
  if (!product.value) return;
  if (product.value?.discounted_until || product.value?.expires_at || product.value?.first_purchase_offer) {
    reloadListings();
  }
  const modal = await modalController.create({
    backdropDismiss: false,
    component: PurchaseSuccessModal,
    cssClass: 'purchase-success-modal',
    componentProps: {
      postAction: product.value.post_action,
      productCode: product.value.code,
      firstPurchaseOffer: product.value?.first_purchase_offer,
    },
  });
  const action = await modal.present();
  return action;
};

const openDescriptionModal = async (ev: any) => {
  if (!product.value) return;
  const cprops = {
    productName: product.value.name,
    htmlContent: product.value.description_html || product.value.description,
  } as any;
  if (product.value.amount_gems) {
    cprops.productPurchasableGems = product.value;
  } else if (product.value.amount) {
    cprops.productPurchasable = product.value;
  }
  const modal = await modalController.create({
    component: SiteHtmlModal,
    cssClass: 'modal-big md modal-default show-modal',
    componentProps: cprops,
  });
  const action = await modal.present();
  modal
    .onDidDismiss()
    .then((res: any) => {
      try {
        if (res?.data === 'continue') {
          startPurchase(ev);
        }
      } catch (e) {}
    })
    .catch(() => {});
  return action;
};
</script>

<style lang="sass" scoped>
.time-icon
  font-size: 14px
  font-weight: bold
  @media(max-width:383px)
    font-size: 0.7rem !important
  @media(max-width:335px)
    font-size: 0.5rem !important
.timer
  font-size: 12px
  font-weight: bold
  align-items: center
  text-align: center
  justify-content: center
  padding: 0 !important
.types
  @media(max-width:450px)
    font-size: 13px
.pic
  width: v-bind('`${imageWdhAndHgt}px`')
  height: v-bind('`${imageWdhAndHgt}px`')
  display: flex
  align-items: center
  justify-content: center
  object-fit: cover

.set-direction
  display: flex
  flex-direction: column-reverse
.check-icon
  font-size: 12px
  color: var(--ion-color-primary)
.amount
  font-weight: bold
  @media(max-width:560px)
    font-size: 12px
  @media(max-width:380px)
    font-size: 12px !important
.dark .amount
  color: white

.token
  width: 15px
  height: 14.4px
  @media(max-width:350px)
    width: 12px !important
    height: 11.4px !important
  @media(max-width:305px)
    width: 10px !important
    height: 9.4px !important
.item-btn
  --background: transparent
  border: 2px solid #c6c8d6
  color: #214163
  border-radius: 10px
  width: 85px
  height: 2rem

.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 14px

.rings
  margin: 8px
  border-radius: 50px
  width: 67px
  height: auto
  aspect-ratio: 1/1

.border-clr
  border: 2px solid white !important
.feature-clr
  border: 2px solid #f5ebff
  background: white
  aspect-ratio: 1
  box-shadow: 1px 4px 1px 1px rgba(33, 65, 99, 0.19)
.dark .feature-clr
  background: #17074c
.product-card
  max-width: 100%
  border-radius: 16px
  position: relative
.svg
  height: 16px
  width: 16px
.icon
  position: relative
  top: 1px
.timer-badge
  padding: 0.12rem 0.5rem
  display: flex
  align-items: center
  justify-content: center
  background: linear-gradient(to right, #FF004D, #EE4035)
  border-radius: 7px
  position: absolute
  bottom: -10px
.above
  left: 0
  right: 0
  padding-top: 1px
  padding-bottom: 1px
  font-size: 12px
  line-height: 14px
  text-align: center
  color: rgba(67, 28, 137, 1) !important
  border-radius: 16px
  background: linear-gradient(90deg, #FFF384 0%, rgba(255, 243, 63, 0) 100%)
  background-color: #EF8625
  box-shadow: 0px 1px 0px 0px rgba(136, 0, 0, 0.6)
  strong
    font-size: 14px
.above-inv
  left: 0
  right: 0
  font-size: 12px
  line-height: 14px
  text-align: center
</style>
