import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared//lib/axios';

export async function getUsersProfileRings() {
  const url = `/users/my-profile-rings/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function addUserProfileRing(payload: {}) {
  const url = `/user-profiles/add-profile-ring/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Added Profile Ring');
  return data;
}
