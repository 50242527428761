<template>
  <div
    id="o-profile-picture"
    class="profile-picture d-flex align-items-center justify-content-center position-relative"
    :class="{ bordered, [size]: size }"
  >
    <Loading
      v-if="(changingProfilePicture && !temp && !tempPhoto) || (tempPhoto && temp && changingProfilePicture)"
      :class="{ [size]: size }"
      :user-image="changingProfilePicture"
      class="position-absolute"
    />

    <img
      v-else
      :class="{
        dimmed: (changingProfilePicture && !temp && !tempPhoto) || (tempPhoto && temp && changingProfilePicture),
      }"
      loading="lazy"
      v-user-image
      :src="
        temp
          ? resizeUpload(userCurrentTempProfilePic)
          : currentStep === 15 || currentStep === 16
          ? resizeUpload(get(user, 'profile_picture_url'), '250x250')
          : resizeUpload(currentProfile?.profile_picture_url, '250x250')
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import Loading from './loading.vue';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import { resizeUpload } from '@/shared/utils/upload';
import { profileStore } from '@/shared/pinia-store/profile';

const { userCurrentTempProfilePic, user, changingProfilePicture, temp: tempPhoto } = authStore();
const { currentProfile } = profileStore();

defineProps({
  bordered: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  commitAfterChange: {
    type: Boolean,
    default: false,
  },
  temp: {
    type: Boolean,
    default: false,
  },
});

const { currentStep } = onboardingStore();
const emits = defineEmits(['event']);
</script>

<style lang="sass" scoped>
.profile-picture
  width: 100px
  height: 100px
  border-radius: 50% !important
  z-index: 1
  &.md
    width: 100px
    height: 100px
  &.lg
    width: 110px
    height: 110px
  &.bordered
    border: 2px solid #FFF
  .round-button
    bottom: 0px
    right: 5px
    --ion-color-dark: rgba(10, 9, 40, 0.8)
    --padding-start: 0
    --padding-end: 0
    width: 24px
    height: 24px
  img
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 50%
</style>
