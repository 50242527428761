export async function getLatestAnnouncement() {
  try {
    const cacheKey = 'ap-announcement';
    const value = await useChFetch<any>(cacheKey, `/announcements/latest/`, undefined, 5000 * 60);

    return value;
  } catch (e) {
    throw e;
  }
}
