<template>
  <div
    v-if="image?.image"
    :id="`i_${id}`"
    class="inner-img grid-item position-relative"
    :class="{
      'inner-i-d': isEditor,
      'pointer-events-none': isReadOnlyCollection,
      'drag-prio': !isReordering,
    }"
    :draggable="isEditor && isReordering"
  >
    <div v-if="isEditor && !watermarkLoading && !isReadOnlyCollection && !isReordering" class="top w-100">
      <div class="position-relative ml-1">
        <ion-button
          v-if="get(image, 'type', 'standard') == 'standard'"
          fill="solid"
          :color="imageButtonColor"
          class="one-button left"
          @click="editImage"
        >
          <i class="ti-pencil" />
        </ion-button>
        <ion-button
          v-if="get(image, 'type', 'standard') == 'standard' && image?.id"
          fill="solid"
          :color="image?.is_watermarked ? 'medium' : imageButtonColor"
          class="one-button ml-1"
          :title="image?.is_watermarked ? 'Remove watermark' : 'Watermark image'"
          @click="toggleImageWatermark"
        >
          <span>
            <inline-svg v-if="image?.is_watermarked === false" src="/icons/watermark-on.svg" />
            <inline-svg v-if="image?.is_watermarked === true" src="/icons/watermark-off.svg" />
          </span>
        </ion-button>

        <ion-button
          v-if="image?.is_spoiled"
          fill="solid"
          :color="imageButtonColor"
          class="one-button left"
          style="--padding-start: 0; --padding-end: 0; height: 30px; width: 30px"
          disabled
        >
          <ion-icon class="icon" style="font-size: 18px !important" :icon="eyeOffOutline" />
        </ion-button>
        <ion-button
          fill="solid"
          :color="imageButtonColor"
          class="one-button right position-absolute"
          @click="startRemoveImage"
        >
          <i class="ti-close" />
        </ion-button>
      </div>
    </div>
    <div class="img-content">
      <ion-button
        v-if="image?.user !== user.id"
        class="report-btn position-absolute report-top"
        shape="round"
        color="dark"
        @click.stop="dropDownToggle($event, image)"
      >
        <i class="ti-more-alt" />
      </ion-button>

      <div
        v-if="!isEditor && !excludedImageReactionRoutes.includes($route.name as string)"
        class="hold-left reaction-bottom position-absolute"
        :class="{ top: !isMobSmallScreen }"
      >
        <Reaction
          type="visual"
          :reactions="image.reaction_counts"
          :user-reaction="image.user_reaction"
          :index="index + 1"
          :is-mob-small-screen="isMobSmallScreen"
          :left-indent-popup="index % 2 === 0"
          :right-indent-popup="index % 2 === 1"
          @changed="(reaction: any) => reactionChanged(reaction.key)"
          @click.stop
          @hideReactions="onHideReactions"
          @showReactions="onShowReactions"
        >
          <ion-button
            :color="!!image.user_reaction && image.user_reaction.reaction ? 'secondary' : ''"
            class="reaction-btn"
            :style="`--background: ${buttonColor};`"
          >
            <div class="d-flex actions">
              <i class="ti-thumb-up" :style="`color: ${textColor} !important`" />
              <span class="mx-1 reaction-count" :style="`color: ${textColor} !important`">{{
                image.reaction_counts?.total_count || 0
              }}</span>
            </div>
          </ion-button>
        </Reaction>
      </div>

      <div @click="pressImage" v-if="image?.is_spoiled && !isEditor && !image?.spoiled_images.user" class="image-blur">
        {{ image?.spoiled_text ? image?.spoiled_text : 'Spoiler' }}
      </div>
      <div class="w-100 fc my-1">
        <div
          class="vis p-1"
          :class="[(!isEditor || image?.description) && 'rounded-bottom', reordering && 'drag-prio-off']"
        >
          <img
            :src="get(image, 'type') === 'decoration' ? image?.image : resizeUpload(image?.image, '240h')"
            @click="pressImage"
            @contextmenu="handleContextMenu"
            :style="image?.is_spoiled && !isEditor && !image?.spoiled_images.user ? 'filter:blur(50px)' : ''"
          />
          <div class="reordering-indicator ml-1 mt-1" v-if="isReordering">
            <i class="ti-move" style="font-size: 20px"></i>
          </div>
        </div>

        <div
          v-if="watermarkLoading"
          class="watermarking-card w-100"
          :class="isEditor || imageDescription ? '' : 'rounded-bottom'"
        >
          <div class="h-100 position-relative">
            <div class="d-flex justify-content-center waiting-icon"><i class="ti-time" /></div>
            <div class="text-center mt-2 process-text">Image Processing...</div>
            <div v-if="!isMobSmallScreen" class="text-center mt-2">{{ watermarkLoadingText }}</div>
          </div>
        </div>

        <div v-if="isEditor" class="vis-desc rounded-bottom" :class="{ focused: isFocused }" @click.stop="selectInput">
          <input
            ref="inputField"
            v-model="imageDescription"
            type="text"
            class="vis-desc-editor mt-0 p-2 rounded-bottom"
            :class="{ focused: isFocused }"
            placeholder="Enter caption here"
            maxlength="255"
            @focus="onFocus"
            @blur="updateOnBlur"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import ImagePopover from '@/shared/components/storage/popovers/ImagePopover.vue';
import { mainStore } from '@/shared/pinia-store/main';
import { imageStore } from '@/shared/pinia-store/images';
import { textColorChanged } from '@/shared/utils/textConverter';
import { excludedImageReactRoutes } from '@/shared/statics/constants';
import Reaction from '@/shared/components/Reaction/index.vue';
import { unSpoilImage } from '@/shared/actions/imagesCollections';
import { authStore } from '@/shared/pinia-store/auth';
import { eyeOffOutline } from 'ionicons/icons';
import { popovers } from '@/shared/native';
import { resizeUpload } from '@/shared/utils/upload';

const { isAuthenticated, user } = authStore();

const {
  changeReaction,
  removeImageFromCollectionLocal,
  setDraggingImageId,
  setWatermarkImages,
  updateImageLocal,
  openViewImageModal,
} = imageStore();
const excludedImageReactionRoutes = excludedImageReactRoutes;
const props = defineProps({
  isEditor: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: () => '',
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  reordering: {
    type: Boolean,
    default: false,
  },
});
const inputField = ref<HTMLInputElement | null>(null);
const isEditor = toRef(props, 'isEditor');
const id = toRef(props, 'id');
const index = toRef(props, 'index');
const customize = toRef(props, 'customize');
const emits = defineEmits(['onReaction', 'openEditModal', 'hideReactions', 'showReactions', 'touched']);
const isFocused = ref(false);
const isReordering = toRef(props, 'reordering');

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const imageButtonColor = computed(() => {
  return dark.value ? 'light' : 'dark';
});

const image = computed(() => {
  const { imageById } = imageStore();
  return imageById.value(id.value);
});

const currentImageCollectionId = computed(() => {
  const { visibleImageCollectionId } = imageStore();
  return visibleImageCollectionId.value;
});

const imageDescription = computed({
  get() {
    const { imageById } = imageStore();
    const img = imageById.value(id.value) || {};
    return img.description || '';
  },
  set(val: string) {
    updateImageLocal({ ...image.value, description: val });
    emits('touched');
  },
});

const watermarkLoading = computed(() => {
  const { isWatermarkLoading } = imageStore();
  return isWatermarkLoading.value;
});

const watermarkLoadingText = computed(() => {
  const { watermarkLoadText } = imageStore();
  return watermarkLoadText.value;
});

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 580;
});

const buttonColor = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    get(customize.value, 'data.theme.backgrounds.buttons') === '#AE38E5'
    ? '#ae38e5'
    : get(customize.value, 'data.theme.backgrounds.buttons', '#ae38e5');
});

const isReadOnlyCollection = computed(() => {
  const { isVisibleImageCollectionReadOnly } = imageStore();
  return isVisibleImageCollectionReadOnly.value;
});

const textColor = computed(() => {
  return buttonColor.value ? textColorChanged(buttonColor.value) : '#fff';
});

const startMove = (e: any) => {
  if (isReadOnlyCollection.value) return;
  const element = document.getElementById(`i_${id.value}`) as HTMLDivElement;
  if (element) e?.dataTransfer.setDragImage(element, 0, 0);
  setDraggingImageId(id.value);
};

const endMove = () => {
  setTimeout(() => {
    setDraggingImageId(null);
  }, 100);
};

const selectInput = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
  inputField.value?.focus();
};

onMounted(() => {
  if (!isEditor.value) return;
  if (!id.value) return;
  if (isReadOnlyCollection.value) return;
  const el = document.getElementById(`i_${id.value}`);
  // get other elements with this id
  const els = document.querySelectorAll(`#i_${id.value}`);
  el?.addEventListener('dragstart', startMove);
  el?.addEventListener('dragend', endMove);
});

// on unmount
onUnmounted(() => {
  if (!isEditor.value) return;
  if (!id.value) return;
  const el = document.getElementById(`i_${id.value}`);
  el?.removeEventListener('dragstart', startMove);
  el?.removeEventListener('dragend', endMove);
});

const reactionChanged = (reaction: string) => {
  changeReaction(id.value, reaction);
};

const onFocus = () => {
  isFocused.value = true;
};

const updateOnBlur = () => {
  updateImageLocal(image.value);
  isFocused.value = false;
};

const toggleImageWatermark = () => {
  if (!image.value?.id) return;
  setWatermarkImages({ imageIds: [image.value?.id!], watermarking: !image.value.is_watermarked });
};

const editImage = () => {
  emits('openEditModal', id.value);
};

const startRemoveImage = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `You are removing this image from the current album.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      removeImageFromCollectionLocal({ imageId: id.value });
    }
  } catch (e) {}
};

const pressImage = async () => {
  if (isEditor.value) return;
  if (!image.value?.id || !currentImageCollectionId.value) return;

  if (image.value.is_spoiled && !image.value?.spoiled_images?.user) {
    if (isAuthenticated.value && image.value?.user !== user.value.id) {
      await unSpoilImage({ image_id: image.value.id });
    }
    updateImageLocal({ ...image.value, is_spoiled: false });

    return;
  }
  const forceData = {} as any;
  forceData.latest = currentImageCollectionId.value;
  openViewImageModal({ imageId: image.value!.id, collectionId: currentImageCollectionId.value! });
};
const onShowReactions = () => {
  if (index.value < 2) emits('showReactions');
};
const dropDownToggle = (ev: CustomEvent, imageObj: any) => {
  popovers.open(ev, ImagePopover, { image: imageObj });
};
const onHideReactions = () => {
  if (index.value < 2) emits('hideReactions');
};
const handleContextMenu = (e: any) => {
  if (isEditor.value) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    return false;
  }
};
</script>
<style scoped lang="sass">
.image-blur
  font-size: 12px
  top: 50%
  background: rgb(85, 84, 84)
  color: white
  border-radius: 20px
  padding: 15px
  text-align: center
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  z-index: 1
  position: absolute
.grid-item
  align-self: stretch

.inner-img
  position: relative
  width: 100%
  height: 100%
  cursor: pointer
  border-radius: 10px
.rounded-bottom
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
.fc
  align-items: stretch
  display: inline-block
.vis
  width: 100% !important
  object-position: center
  aspect-ratio: 8/5
  border-top-left-radius: 10px !important
  border-top-right-radius: 10px !important
  outline: 1px solid rgba(0,0,0,0.05) !important
  position: relative
  img
    width: auto
    height: auto
    max-width: calc(100% - 8px)
    max-height: calc(100% - 8px)
    object-fit: contain
    border-radius: 20%
    object-fit: contain
    margin-bottom: -2px
    border-radius: 6px
    top: 0
    bottom: 0
    right: 0
    left: 0
    margin: auto
    position: absolute

.drag
  transform: scale(0.5)

.vis-desc-editor
  color: rgb(51, 51, 51) !important
  width: 100%
  background-color: #CCC !important
  font-size: 12px
  border: none
.vis-desc
  outline: 1px solid rgba(0,0,0,0.05) !important
  margin-top: -3px
  background-color: #aaa
.bottom-0
  bottom: 0
.top
  position: absolute
  z-index: 1
.right
  right: 0.4rem
.hold-left
  margin-left: 0.4rem
.report-top
  top: 15px
  right: 8px
  z-index: 1
.report-btn
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  --ion-color-contrast: #FFF !important
  --padding-start: 0
  --padding-end: 0
  width: 23px
  height: 18px
  font-size: 14px
.dark .report-btn
  color: white
ion-button
  cursor: pointer
.one-button
  --border-radius: 8px
  width: 1.6rem !important
  height: 1.6rem !important
  --padding-start: 10px
  --padding-end: 10px
input[type=text]::placeholder
  color: #888 !important
.watermarking-card
  position: absolute
  background-color: rgba(255, 255, 255, 0.65) !important
  font-size: 12px !important
  border-radius: 10px !important
  border: none !important
  outline: none !important
  top: 0
  right: 0
  left: 0
  bottom: 0
  display: block !important
  z-index: 1
  justify-content: center !important
  align-items: stretch !important
  padding: 0 !important
  margin: 0 !important
  div
    top: 25%
  .waiting-icon
    font-size: 36px !important
    color: var(--color) !important
  .process-text
    color: var(--color) !important
    font-size: 14px !important
    font-weight: 400 !important
.reaction-count
  font-weight: bold
.reaction-btn
  --padding-top: 0
  --padding-bottom: 0
  --padding-start: 0
  --padding-end: 0
  padding: 0
  margin: 0
  --border-radius: 6px
  width: 38px
  height: 24px
  @media(max-width: 500px)
    --border-radius: 3px
    width: 38px
    height: 18px
    font-size: 12px
.reaction-bottom
  bottom: 14px !important
  left: 0px !important
  @media(max-width: 500px)
    left: -2px !important
    bottom: 12px !important
.img-content
  flex: 1
.focused
  background: white !important
  background-color: white !important
.reordering-indicator
  -webkit-user-drag: element
  position: absolute
  background-color: #59bcff
  color: white
  font-size: 20px
  border-radius: 10px
  width: 40px
  height: 40px
  z-index: 1
  display: flex
  justify-content: center
  align-items: center
</style>
