<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: 'Crop Preview' }" @dismiss-modal="dismissModal">
      <div v-if="loading" class="d-flex justify-content-center align-items-center h-100 w-100">
        <ChLoading size="md" />
      </div>
      <div :key="rerenderKey" class="d-flex justify-content-center align-items-center crop-preview">
        <vue-picture-cropper
          ref="cropper"
          :class="{ invisible: loading, ...cropperClasses }"
          :box-style="{ width: '100%', height: '100%', backgroundColor: 'transparent' }"
          :img="imageUrl"
          :options="{
            viewMode: 1,
            dragMode,
            aspectRatio: cropperAspectRatio,
            ready: onCropEvent,
            minCropBoxWidth: 100,
            minCropBoxHeight: 100,
          }"
        />
      </div>
      <div v-if="!loading" class="d-flex justify-content-end mt-2">
        <ion-button color="danger" @click="dismissModal">Cancel</ion-button>
        <ion-button class="btn-size" @click="change">Save</ion-button>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import VuePictureCropper, { cropper } from 'vue-picture-cropper';
import { upload } from '@/shared/services/upload';

@Options({
  name: 'CropImageModal',
  components: { VuePictureCropper },
})
export default class CropImageview extends Vue {
  @Prop({ required: true }) imageUrl!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: () => '' }) outlineStyle!: string;
  public isSaving = false;
  public loading = true;
  public cropperAspectRatio = 1;
  public rerenderKey = 1;

  @Watch('isOpen')
  changeisOpenValue() {
    this.isSaving = false;
    this.loading = true;
    this.cropperAspectRatio = this.outlineStyle === 'cover' ? 770 / 241 : 1;
    if (this.isOpen) {
      this.$nextTick(() => {
        this.rerenderKey += 1;
      });
    }
  }

  @Watch('imageUrl')
  changeImageUrl() {
    setTimeout(() => {
      this.rerenderKey += 1;
    }, 300);
  }

  public get dragMode() {
    if (this.outlineStyle === 'round') return 'crop';
    if (this.outlineStyle === 'cover') return 'move';
    return 'crop';
  }

  public get cropperClasses() {
    return {
      'round-cropper': this.outlineStyle === 'round',
      'cover-cropper': this.outlineStyle === 'cover',
    };
  }

  public async change() {
    const imagefile = (await cropper!.getBlob()) as Blob;
    this.isSaving = true;

    if (!imagefile.type.startsWith('image/') || imagefile.type === 'image/gif') {
      throw new TypeError('File should be an image.');
    }

    this.$emit('crop-done', imagefile);
    this.isSaving = false;
  }

  public onCropEvent() {
    this.loading = false;
  }

  public dismissModal() {
    this.isSaving = false;
    this.loading = true;
    this.$emit('dismissModal');
  }
}
</script>

<style lang="sass">
.invisible
  visibility: hidden

.crop-preview
  height: calc(100% - 52px)

.btn-size
  width: 82px

ion-spinner
  width: 5em
  height: 5em

.round-cropper .cropper-container .cropper-crop-box .cropper-view-box
  box-shadow: 0 0 0 1px #39f
  border-radius: 50%
  outline: 0

.round-cropper .cropper-container .cropper-crop-box .cropper-face
  background-color: inherit !important

.round-cropper .cropper-container .cropper-crop-box .cropper-view-box
  outline: inherit !important
</style>
