import { modalController } from '@ionic/vue';

import ShareProfileModal from '@/shared/modals/ShareProfileModal.vue';

export const step18 = (stepsTimer: any) => {
  nextTick(() => {
    const { isDesktopSize } = useWindowSize();
    const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
    const opfp = isDesktopSize.value
      ? document.querySelector('#o-pfp-1')
      : (document.querySelector('#o-pfp') as HTMLElement);
    const ohole = document.querySelector('#o-hole') as HTMLElement;
    const oarrow = document.querySelector('#o-arrow') as HTMLElement;

    if (!opfp || !ohole || !oarrow) {
      clearTimeout(stepsTimer);
      stepsTimer = setTimeout(step18, 1000);
      return;
    }

    const { clientWidth: pfpWidth, clientHeight: pfpHeight } = opfp;
    const { top: pfpTop, left: pfpLeft } = opfp.getBoundingClientRect();

    layout.style.overflow = 'hidden';
    opfp.classList.add('all-pointer');
    ohole.style.width = `${pfpWidth + 40}px`;
    ohole.style.height = `${pfpHeight + 40}px`;
    ohole.style.left = `${pfpLeft - 20}px`;
    ohole.style.top = `${pfpTop - 20}px`;
    oarrow.style.top = `${pfpTop + pfpHeight}px`;
    oarrow.style.left = `${pfpLeft + pfpWidth}px`;
    oarrow.style.rotate = `45deg`;
    oarrow.style.display = `block`;
  });
};

export const step19 = (stepsTimer: any) => {
  nextTick(() => {
    const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
    const opfp = document.querySelector('#o-pfp-1') as HTMLElement;
    const ohole = document.querySelector('#o-hole') as HTMLElement;
    const oarrow = document.querySelector('#o-arrow') as HTMLElement;

    if (!opfp || !ohole || !oarrow) {
      clearTimeout(stepsTimer);
      stepsTimer = setTimeout(step19, 1000);
      return;
    }

    const { clientWidth: pfpWidth, clientHeight: pfpHeight } = opfp;
    const { top: pfpTop, left: pfpLeft } = opfp.getBoundingClientRect();

    layout.style.overflow = 'hidden';
    opfp.classList.add('all-pointer');
    ohole.style.width = `${pfpWidth + 40}px`;
    ohole.style.height = `${pfpHeight + 40}px`;
    ohole.style.left = `${pfpLeft - 20}px`;
    ohole.style.top = `${pfpTop - 20}px`;
    oarrow.style.top = `${pfpTop + pfpHeight}px`;
    oarrow.style.left = `${pfpLeft + pfpWidth}px`;
    oarrow.style.rotate = `45deg`;
    oarrow.style.display = `block`;
  });
};

export const step20 = (stepsTimer: any, showOnboarding: Ref<boolean>) => {
  const router = useRouter();

  if (router.currentRoute.value.name !== 'profile-self' && showOnboarding?.value) {
    router.push({ name: 'profile-self' });
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step20, 1000);
    return;
  }

  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
  const opfp = document.querySelector('#o-profile-picture') as HTMLElement;
  const ohole = document.querySelector('#o-hole') as HTMLElement;
  const oarrow = document.querySelector('#o-arrow') as HTMLElement;
  const omsg = document.querySelector('#o-msg') as HTMLElement;

  if (!opfp || !ohole || !oarrow || !omsg) {
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step20, 1000);
    return;
  }

  const { clientWidth: pfpWidth, clientHeight: pfpHeight } = opfp;
  const { top: pfpTop, left: pfpLeft } = opfp.getBoundingClientRect();

  layout.style.overflowY = 'hidden';
  opfp.classList.add('all-pointer');
  ohole.style.width = `${pfpWidth + 40}px`;
  ohole.style.height = `${pfpHeight + 40}px`;
  ohole.style.left = `${pfpLeft - 20}px`;
  ohole.style.top = `${pfpTop - 20}px`;
  oarrow.style.top = `${pfpTop}px`;
  oarrow.style.left = `${pfpLeft + pfpWidth + 50}px`;
  oarrow.style.display = `block`;
  omsg.style.top = `${pfpTop + pfpHeight}px`;
  omsg.style.left = `${pfpLeft + pfpWidth}px`;
};

export const step21 = (stepsTimer: any, showOnboarding: Ref<boolean>) => {
  const router = useRouter();

  if (router.currentRoute.value.name !== 'profile-self' && showOnboarding?.value) {
    router.push({ name: 'profile-self' });
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step21, 1000);
    return;
  }

  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
  const ohimg = document.querySelector('#o-header-image') as HTMLElement;
  const ohole = document.querySelector('#o-hole') as HTMLElement;
  const oarrow = document.querySelector('#o-arrow') as HTMLElement;
  const omsg = document.querySelector('#o-msg') as HTMLElement;

  if (!ohimg || !ohole || !oarrow || !omsg) {
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step21, 1000);
    return;
  }

  const { clientWidth: ohimgWidth } = ohimg;
  const { top: ohimgTop, left: ohimgLeft } = ohimg.getBoundingClientRect();

  ohimg.classList.add('all-pointer');
  layout.style.overflowY = 'hidden';
  ohole.style.width = `${ohimgWidth}px`;
  ohole.style.height = `${ohimgWidth}px`;
  ohole.style.left = `${ohimgLeft}px`;
  ohole.style.top = `${ohimgTop - 40}px`;
  oarrow.style.top = `${ohimgTop - 30}px`;
  oarrow.style.left = `${ohimgLeft + ohimgWidth + 50}px`;
  oarrow.style.display = `block`;
  omsg.style.top = `${ohimgTop + 70}px`;
  omsg.style.left = `${ohimgLeft + ohimgWidth}px`;
};

export const step22 = (stepsTimer: any, showOnboarding: Ref<boolean>) => {
  const router = useRouter();

  if (router.currentRoute.value.name !== 'profile-self' && showOnboarding?.value) {
    router.push({ name: 'profile-self' });
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step22, 1000);
    return;
  }

  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
  const oedit = document.querySelector('#o-edit-profile') as HTMLElement;
  const ohole = document.querySelector('#o-hole') as HTMLElement;
  const oarrow = document.querySelector('#o-arrow') as HTMLElement;

  if (!oedit || !ohole || !oarrow) {
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step22, 1000);
    return;
  }

  const { clientWidth: oeditWidth } = oedit;
  const { top: oeditTop, left: oeditLeft } = oedit.getBoundingClientRect();

  oedit.classList.add('all-pointer');
  layout.style.overflowY = 'hidden';
  ohole.style.width = `${oeditWidth}px`;
  ohole.style.height = `${oeditWidth}px`;
  ohole.style.left = `${oeditLeft}px`;
  ohole.style.top = `${oeditTop - 40}px`;
  oarrow.style.top = `${oeditTop - 30}px`;
  oarrow.style.left = `${oeditLeft - oeditWidth - 50}px`;
  oarrow.style.rotate = `180deg`;
  oarrow.style.display = `block`;
};

let counter23 = 0;
let counter24 = 0;

export const step23 = (stepsTimer: any, stepForward: any, showOnboarding: Ref<boolean>) => {
  const router = useRouter();

  if (router.currentRoute.value.name !== 'profile-self' && showOnboarding?.value) {
    router.push({ name: 'profile-self' });
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(() => step23(stepsTimer, stepForward, showOnboarding), 1000);
    return;
  }

  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
  const oage1 = document.querySelector('#o-age-section-1') as HTMLElement;
  const editProfileScroll = document.querySelector('#o-edit-profile-modal') as HTMLElement;
  const ohole = document.querySelector('#o-hole') as HTMLElement;
  const oarrow = document.querySelector('#o-arrow') as HTMLElement;

  if (!oage1 || !ohole || !oarrow || !editProfileScroll) {
    counter23++;
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(() => step23(stepsTimer, stepForward, showOnboarding), 1000);

    if (counter23 === 5) stepForward(-1);
    return;
  }

  editProfileScroll.scrollBy(0, 500);
  const { clientHeight: oage1Height } = oage1;
  const { top: oage1Top, left: oage1Left } = oage1.getBoundingClientRect();

  oage1.classList.add('all-pointer');
  editProfileScroll.style.overflow = 'hidden !important';
  layout.style.overflowY = 'hidden';
  ohole.style.width = `${oage1Height + 50}px`;
  ohole.style.height = `${oage1Height + 50}px`;
  ohole.style.left = `${oage1Left - 50}px`;
  ohole.style.top = `${oage1Top - 20}px`;
  oarrow.style.top = `${oage1Top - 20}px`;
  oarrow.style.left = `${oage1Left - oage1Height + 100 + oage1Height}px`;
  oarrow.style.rotate = `315deg`;
  oarrow.style.display = `block`;
};

export const step24 = (stepsTimer: any, stepForward: any, showOnboarding: Ref<boolean>) => {
  const router = useRouter();

  if (router.currentRoute.value.name !== 'profile-self' && showOnboarding?.value) {
    router.push({ name: 'profile-self' });
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(() => step24(stepsTimer, stepForward, showOnboarding), 1000);

    return;
  }

  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
  const oage2 = document.querySelector('#o-age-section-2') as HTMLElement;
  const editProfileScroll = document.querySelector('#o-edit-profile-modal') as HTMLElement;
  const ohole = document.querySelector('#o-hole') as HTMLElement;
  const oarrow = document.querySelector('#o-arrow') as HTMLElement;

  if (!oage2 || !ohole || !oarrow || !editProfileScroll) {
    counter24++;
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(() => step24(stepsTimer, stepForward, showOnboarding), 1000);

    if (counter24 === 5) stepForward(-2);
    return;
  }

  clearTimeout(stepsTimer);

  const { clientHeight: oage2Height } = oage2;
  const { top: oage2Top, left: oage2Left } = oage2.getBoundingClientRect();

  setTimeout(() => {
    oage2.classList.add('all-pointer');
    layout.style.overflowY = 'hidden';
    ohole.style.width = `${oage2Height + 60}px`;
    ohole.style.height = `${oage2Height + 60}px`;
    ohole.style.left = `${oage2Left - 50}px`;
    ohole.style.top = `${oage2Top - 30}px`;
    oarrow.style.top = `${oage2Top - 50}px`;
    oarrow.style.left = `${oage2Left - 50 + oage2Height}px`;
    oarrow.style.rotate = `315deg`;
    oarrow.style.display = `block`;
  }, 20);
};

export const step25 = async (stepForward: any) => {
  const modal = await modalController.create({
    component: ShareProfileModal,
    cssClass: `share-profile-modal`,
  });

  modal.onDidDismiss().then(() => stepForward());

  await modal.present();
};

export const step26 = async (stepsTimer: any, showOnboarding: Ref<boolean>) => {
  const { isDesktopSize } = useWindowSize();
  const router = useRouter();

  if (router.currentRoute.value.name !== 'profile-self' && showOnboarding?.value) {
    router.push({ name: 'profile-self' });
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step26, 1000);
    return;
  }

  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;
  const ohome = isDesktopSize.value
    ? (document.querySelector('#o-logo') as HTMLElement)
    : (document.querySelector('#o-home') as HTMLElement);

  const ohole = document.querySelector('#o-hole') as HTMLElement;
  const oarrow = document.querySelector('#o-arrow') as HTMLElement;

  if (!ohome || !ohole || !oarrow) {
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step26, 1000);
    return;
  }

  const { clientWidth: ohomeWidth, clientHeight: ohomeHeight } = ohome;
  const { top: ohomeTop, left: ohomeLeft } = ohome.getBoundingClientRect();

  ohome.classList.add('all-pointer');
  layout.style.overflowY = 'hidden';
  ohole.style.width = `${isDesktopSize.value ? ohomeWidth + 40 : 70}px`;
  ohole.style.height = `${isDesktopSize.value ? ohomeHeight : 70}px`;
  ohole.style.left = `${ohomeLeft - 20}px`;
  ohole.style.top = `${ohomeTop}px`;
  oarrow.style.top = `${isDesktopSize.value ? ohomeTop + 50 : ohomeTop - 100}px`;
  oarrow.style.left = `${isDesktopSize.value ? ohomeLeft + 20 : ohomeLeft - ohomeWidth + 60}px`;
  oarrow.style.rotate = isDesktopSize.value ? `90deg` : `315deg`;
  oarrow.style.display = `block`;
};

export const step27 = async (stepsTimer: any, showOnboarding: Ref<boolean>) => {
  const layout = document.querySelector('.layout-scroll-area') as HTMLElement;

  if (!layout) {
    clearTimeout(stepsTimer);
    stepsTimer = setTimeout(step27, 1000);
    return;
  }

  layout.style.overflowY = 'auto';

  clearTimeout(stepsTimer);
};
