import { modalController } from '@ionic/vue';
import { handleColorMode } from '~/apps/mobile/services/native';
import { mainStore } from '~/shared/pinia-store/main';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  try {
    const { initHeight } = mainStore();
    const doc = document.documentElement;

    doc.style.setProperty('--doc-height', `${initHeight.value}px`);
  } catch (error) {}

  if (to.path !== from.path && process.client) {
    try {
      const scroller = document.querySelector('.layout-scroll-area');

      if (scroller) {
        scroller.scrollTo(0, 0);
      }

      handleColorMode();
    } catch (e) {}

    try {
      modalController.getTop().then((topModal) => {
        if (topModal && !to.query?.km_) modalController.dismiss(null, 'cancel');
      });
    } catch (e) {}
  }
});
