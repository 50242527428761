export const useCommentHighlighter = (loading: Ref<boolean>) => {
  if (process.server) return;

  watch(loading, (val) => {
    try {
      if (!val) {
        setTimeout(() => {
          const router = useRouter();
          const commentID = router.currentRoute.value.query.cmnt;

          if (!commentID) return;

          const commentEl =
            document.querySelector(`#comment-${commentID}`) || document.querySelector(`#comment-reply-${commentID}`);

          if (!commentEl) return;

          commentEl.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
      }
    } catch (error) {}
  });
};
