<template>
  <div
    class="character-card d-flex"
    ref="chCard"
    :class="{ 'reorder-handle': isReorderable, 'image-only': imageOnly, horizontal }"
    @contextmenu="handleContext"
  >
    <!-- BEGIN OF LIST VIEW CARD -->
    <div v-if="horizontal" class="w-100 d-flex">
      <div class="image d-flex align-items-center justify-content-center">
        <img
          v-character-card-image
          :src="resizeUpload(get(character, 'info.cropProfilePicture') || get(character, 'image'), res)"
        />
        <div class="tags">
          <div
            v-if="!limited && !imageOnly && !hideTypeTag"
            class="tag d-flex align-items-center justify-content-center"
          >
            {{ tag }}
          </div>
          <MatureTag class="mt-1" v-if="isNsfw" />
          <div
            v-if="characterPrivacy === 'M' || characterPrivacy === 'U'"
            class="tag d-flex align-items-center justify-content-center"
          >
            <span v-if="characterPrivacy === 'M'">🔒&nbsp;Private</span>
            <span v-if="characterPrivacy === 'U'">🔒&nbsp;Unlisted</span>
          </div>
        </div>
        <div v-if="imageOnly && username" class="username mr-1 mb-2" :title="username">
          <a class="clickable" @click.stop.prevent="goToUser(String(username))" :href="userHref"> @{{ username }} </a>
        </div>
      </div>
      <div class="rest-hal ml-3 mt-2 d-flex flex-column justify-content-between w-100">
        <div>
          <div class="name d-flex align-items-center justify-content-between w-100">
            <a :href="charHref" class="routerlink clickable" @click.prevent="goToCharacter()">
              <span v-if="characterPrivacy === 'M'">🔒&nbsp;</span><span v-if="characterPrivacy === 'U'">🔗&nbsp;</span>
              <span style="color: #ffde67">
                {{ text }}
              </span>
              <span v-if="includeFeedAction"> Update on </span>
              <span :style="includeFeedAction ? 'text-decoration: underline; color: #ffde67' : ''">
                {{ get(character, 'info.name') || get(character, 'name') }}
              </span>
              <span v-if="includeFeedAction">! Go check it out! </span>
            </a>
            <div
              v-if="!isSelectable && isOwner && !limited"
              class="clickable drop-down d-flex align-items-center justify-content-center"
              @click.stop.prevent="openDropDown"
            >
              <i class="ti-more-alt" />
            </div>
          </div>
          <a
            v-if="!imageOnly && username"
            class="small"
            @click.stop.prevent="goToUser(String(username))"
            :href="userHref"
          >
            @{{ username }}
          </a>
          <div class="description">
            <span v-if="get(character, 'description_text')">
              {{ truncateText(character.description_text, 240) }}
            </span>
            <span
              v-else
              v-html="sanitizeHtml(get(character, 'info.description') || get(character, 'description') || '')"
              :style="includeFeedAction ? 'text-decoration: underline; color: #ffde67' : ''"
            >
            </span>
          </div>
          <div
            v-for="t in characterTags"
            class="t-tag d-inline-block align-items-center justify-content-center"
            :title="t"
          >
            <span v-if="!tagOCPageLink(t)">#{{ truncateText(t, 80) }}</span>
            <a v-else :href="tagOCPageLink(t)"
              ><span>#{{ truncateText(t, 80) }}</span></a
            >
          </div>
        </div>

        <div class="actions ml-n1 w-75 d-flex justify-content-start" :class="{ disabled: isDraft }">
          <reaction
            :class="{ disabled: isDraft }"
            @click.stop
            :showBox="get(character, 'showBox')"
            :progressId="get(character, 'progressId')"
            :reactions="mutatedReactionsData?.reaction_counts"
            :user-reaction="mutatedReactionsData?.user_reaction"
            @changed="(reaction) => reactionChanged(reaction.key, reaction.isInstant)"
            @updated="emits('updated')"
            @delete="deleteCurrGiftBox"
            :disabled="readOnly"
          >
            <ion-button color="transparent" class="inline-button icon-button" @click.prevent>
              <div
                class="d-flex align-items-center justify-content-center text-primary"
                :class="{
                  'text-secondary': !!mutatedReactionsData?.user_reaction,
                }"
              >
                <i class="ti-thumb-up mr-2" />
                <span class="reaction-count">{{ mutatedReactionsData?.reaction_counts?.total_count || 0 }}</span>
              </div>
            </ion-button>
          </reaction>

          <a @click.stop="goToCharacter()">
            <ion-button color="transparent" class="inline-button icon-button ml-2" :disabled="readOnly">
              <div class="d-flex align-items-center justify-content-center">
                <i class="ti-comment mr-2 icon" />
                <span class="reaction-count primary-color">{{ charCommentsCount || 0 }}</span>
              </div>
            </ion-button>
          </a>
        </div>
      </div>
    </div>
    <!-- END OF LIST VIEW CARD -->

    <!-- BEGIN OF GRID VIEW CARD -->
    <div v-else class="w-100 h-100 position-relative">
      <ion-button
        v-if="!isReorderable && isRemovable"
        class="position-absolute remove-selection-btn"
        fill="clear"
        @click="remove"
        ><i class="ti-close"
      /></ion-button>
      <a :href="charHref" class="a-wrap" @click.prevent="goToCharacter">
        <div
          class="rest-info position-absolute flex-grow-1 d-flex flex-column justify-content-between char-info"
          @click="toggleSelection"
        >
          <div v-if="!imageOnly" class="upper d-flex justify-content-between">
            <div>
              <div
                v-if="!limited && !imageOnly && !hideTypeTag"
                class="tag d-flex align-items-center justify-content-center"
              >
                {{ tag }}
              </div>
              <div v-else-if="isDraft" class="tag d-flex align-items-center justify-content-center">
                {{ tag }}
              </div>
              <div v-for="t in characterTags" class="t-tag d-flex align-items-center justify-content-center" :title="t">
                {{ truncateText(t, 10) }}
              </div>
              <div
                v-if="characterPrivacy === 'M' || characterPrivacy === 'U'"
                class="tag sm mt-1 d-flex align-items-center justify-content-center"
              >
                <span v-if="characterPrivacy === 'M'">🔒&nbsp;Private</span>
                <span v-else-if="characterPrivacy === 'U'">🔗&nbsp;Unlisted</span>
              </div>
              <MatureTag class="mt-1" v-if="isNsfw" />
            </div>

            <ion-checkbox
              v-if="isSelectable && isOwner"
              :disabled="true"
              class="select"
              mode="ios"
              :checked="isSelected"
            />
            <div
              v-if="!isSelectable && isOwner && !limited"
              class="clickable drop-down d-flex align-items-center justify-content-center"
              @click.stop.prevent="openDropDown"
            >
              <i class="ti-more-alt" />
            </div>
          </div>

          <div class="rest">
            <div v-if="!imageOnly" class="name d-flex align-items-center justify-content-start">
              <a class="routerlink clickable" @click.prevent="goToCharacter()">
                <span style="color: #ffde67">
                  {{ text }}
                </span>
                <span v-if="includeFeedAction"> Update on </span>
                <span :style="includeFeedAction ? 'text-decoration: underline; color: #ffde67' : ''">
                  {{ get(character, 'info.name') || get(character, 'name') }}
                </span>
                <span v-if="includeFeedAction">! Go check it out! </span>
              </a>
            </div>

            <div v-if="!limited && !imageOnly" class="lower mb-n1 d-flex justify-content-between align-items-end">
              <div class="actions ml-n1 w-75 d-flex justify-content-start" :class="{ disabled: isDraft }">
                <reaction
                  v-if="!isDraft"
                  @click.stop
                  :showBox="get(character, 'showBox')"
                  :progressId="get(character, 'progressId')"
                  :reactions="mutatedReactionsData?.reaction_counts"
                  :user-reaction="mutatedReactionsData?.user_reaction"
                  @changed="(reaction) => reactionChanged(reaction.key, reaction.isInstant)"
                  @updated="emits('updated')"
                  @delete="deleteCurrGiftBox"
                  :disabled="readOnly"
                >
                  <ion-button color="transparent" class="inline-button icon-button" @click.prevent>
                    <div
                      class="d-flex align-items-center justify-content-center text-primary"
                      :class="{
                        'text-secondary': !!mutatedReactionsData?.user_reaction,
                      }"
                    >
                      <i class="ti-thumb-up mr-2" />
                      <span class="reaction-count">{{ mutatedReactionsData?.reaction_counts?.total_count || 0 }}</span>
                    </div>
                  </ion-button>
                </reaction>

                <a @click.stop.prevent="goToCharacter">
                  <ion-button color="transparent" class="inline-button icon-button ml-2">
                    <div class="d-flex align-items-center justify-content-center" :class="{ disabled: readOnly }">
                      <i class="ti-comment mr-2 icon" />
                      <span class="reaction-count primary-color">{{ charCommentsCount || 0 }}</span>
                    </div>
                  </ion-button>
                </a>
              </div>
              <div
                v-if="!imageOnly && username"
                class="username mr-1 mb-2 d-flex"
                :style="{ width: usernameWidth }"
                :title="username"
              >
                <a class="clickable mr-1" @click.stop.prevent="goToUser(String(username))" :href="userHref">
                  @{{ username }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="image d-flex align-items-center justify-content-center">
          <img
            v-character-card-image
            :src="resizeUpload(get(character, 'info.cropProfilePicture') || get(character, 'image'), res)"
          />
        </div>
      </a>
    </div>
    <!-- END OF GRID VIEW CARD -->
  </div>
</template>

<script lang="ts" setup>
import Reaction from '../Reaction/index.vue';
import { alertController, modalController } from '@ionic/vue';
import CharacterCardPopover from './popovers/CharacterCardPopover.vue';
import { Character } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import { react } from '@/shared/helpers/characters';
import { toast } from '@/shared/native';
import MatureTag from '@/shared/components/MatureTag.vue';
import { deleteCharacter, deleteCharacterDraft } from '@/shared/actions/characters';
import { resizeUpload } from '@/shared/utils/upload';
import { sanitizeHtml } from '@/shared/utils/html';
import { isMobileFn } from '@/apps/mobile/native/device';
import CharacterProfileModal from '@/shared/modals/CharacterProfileModal.vue';
import { truncateText } from '@/shared/utils/string';

const chCard = ref<HTMLElement | null>(null);
const chCardWidth = ref(0);

const openCharacterProfileModal = async (characterSlug: string) => {
  const presentingElement = document.querySelector('.global-mobile') as HTMLElement;

  if (!presentingElement) return;

  const modal = await modalController.create({
    mode: 'ios',
    presentingElement,
    component: CharacterProfileModal,
    backdropDismiss: false,
    cssClass: 'character-profile-modal',
    componentProps: {
      characterSlug,
      title: 'Character Preview',
    },
  });

  await modal.present();
  const code = await modal.onDidDismiss();
  return code;
};

const tag = computed(() =>
  includeFeedAction.value
    ? 'Character Update'
    : feed.value.action === 'created_character'
    ? 'New Character'
    : isDraft.value
    ? 'Draft Character'
    : 'Character'
);

const includeFeedAction = computed(() =>
  ['updated_char_text', 'updated_char_fields', 'updated_char_visuals', 'updated_char_profile_img'].includes(
    feed.value.action
  )
);

onMounted(() => {
  window.addEventListener('resize', onResizeFn);
});

const onResizeFn = () => {
  chCardWidth.value = chCard.value?.offsetWidth || 0;
};

onUnmounted(() => {
  window.removeEventListener('resize', onResizeFn);
});

const res = computed(() => {
  return chCardWidth.value > 260 ? '600x600' : '250x250';
});

const props = defineProps({
  hideTypeTag: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  lazyUserReaction: {
    type: Boolean,
    default: false,
  },
  openBlank: {
    type: Boolean,
    default: false,
  },
  character: {
    type: Object,
    default: () => ({}),
  },
  isSelected: {
    type: Boolean,
  },
  isSelectable: {
    type: Boolean,
  },
  isRemovable: {
    type: Boolean,
  },
  isReorderable: {
    type: Boolean,
  },
  limited: {
    type: Boolean,
  },
  imageOnly: {
    type: Boolean,
  },
  feed: {
    type: Object,
    default: () => ({}),
  },
  charCommentsCount: {
    type: Number,
    default: 0,
  },
  reactedChars: {
    type: Object,
    default: null,
  },
  showCharacterTags: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});
const reactedChars = toRef(props, 'reactedChars') as any;
const openBlank = computed(() => props.openBlank as boolean);
const character = computed(() => props.character as Character);
const isSelected = computed(() => props.isSelected as boolean);
const isSelectable = computed(() => props.isSelectable as boolean);
const isRemovable = computed(() => props.isRemovable as boolean);
const readOnly = computed(() => props.readOnly as boolean);
const imageOnly = computed(() => props.imageOnly as boolean);
const limited = computed(() => props.limited as boolean);
const showCharacterTags = computed(() => props.showCharacterTags as boolean);
const isNsfw = computed(() => character.value.is_nsfw);
const charCommentsCount = computed(() => props.charCommentsCount as Number);
const isDraft = computed(() => character.value.type === 'draft');
const characterPrivacy = computed(() => character.value?.privacy || character.value?.info?.privacy);
const lazyUserReaction = toRef(props, 'lazyUserReaction');
const usernameWidth = computed(() => `calc(100% - ${digitsCountBesideEachOther.value * 6.5}px - 60px`);

const digitsCountBesideEachOther = computed(
  () => `${character.value?.reaction_counts?.total_count || 0}${charCommentsCount.value}`.length
);

const characterTags = computed(() => {
  if (!showCharacterTags.value) return [];
  const listOut = character.value?.tag_ocpages.map((x: string) => x.toLowerCase()) || [];
  const tagsMinusOCPs = character.value?.tags?.filter((x: string) => !listOut.includes(x.toLowerCase())) || [];
  return listOut.concat(tagsMinusOCPs).slice(0, 6);
});

const tagOCPageLink = (tag: string) => {
  const currentPath = router.currentRoute.value.path;
  if (currentPath.endsWith(`/${tag.toLowerCase()}`)) {
    return undefined;
  }
  const ocps = character.value?.tag_ocpages.map((x: string) => x.toLowerCase());
  if (ocps.includes(tag.toLowerCase())) {
    return `/original-character/${tag.toLowerCase()}`;
  }
  return undefined;
};

const goToUser = async (username: string) => {
  if (limited.value || imageOnly.value || isSelectable.value || isRemovable.value || isReorderable.value) return;
  const { isAuthenticated } = authStore();

  const router = useRouter();
  const route = {
    name: 'profile',
    params: { username },
    query: { v: 1 } as any,
  };
  if (!isAuthenticated.value) {
    route.query = {};
  }

  const isMobile = await isMobileFn();

  if (openBlank.value && isMobile) {
    const characterId = character.value.id;

    if (!characterId) return;

    openCharacterProfileModal(characterId);
    return;
  }

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(route);
    window.open(href, '_blank');
    return;
  }

  router.push(route);
};

const feed = toRef(props, 'feed');

const isReorderable = computed(() => props.isReorderable as boolean);
const handleContext = (e: any) => {
  if (props.isReorderable) return e.preventDefault();
};

const router = useRouter();

const route = computed(() =>
  isDraft.value
    ? { name: 'character-profile-draft-new', params: { id: character.value.id } }
    : { name: 'character-profile-new', params: { slug: character.value.slug }, query: { v: 1 } }
);

const charHref = computed(() => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) {
    return `/character/${character.value?.slug}`;
  }
  const { href } = router.resolve(route.value);
  return href;
});

const userHref = computed(() => {
  const { isAuthenticated } = authStore();
  const route = {
    name: 'profile',
    params: { username: character.value?.author?.username },
    query: { v: 1 } as any,
  };
  if (!isAuthenticated.value) {
    route.query = {};
  }
  const { href } = router.resolve(route);

  return href;
});

const goToCharacter = async () => {
  if (imageOnly.value || isSelectable.value || isRemovable.value || isReorderable.value) return;

  const isMobile = await isMobileFn();

  if (openBlank.value && isMobile) {
    const characterSlug = character.value.slug;

    if (!characterSlug) return;

    openCharacterProfileModal(characterSlug);
    return;
  }

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(route.value);
    window.open(href, '_blank');
    return;
  }

  router.push(route.value);
};

const reactionCount = ref(character.value.reaction_counts);

const mutatedReactionsData = lazyUserReaction.value
  ? ref({
      reaction_counts: reactionCount.value,
      user_reaction: reactedChars.value || null,
    })
  : ref(pick(character.value, ['reaction_counts', 'user_reaction']));

watch([reactionCount, reactedChars], () => {
  if (lazyUserReaction.value) {
    mutatedReactionsData.value = {
      reaction_counts: reactionCount.value,
      user_reaction: reactedChars.value,
    };
  }
});
const isOwner = computed(() => {
  try {
    return character.value.author!.username === user.value.username;
  } catch (error) {
    return false;
  }
});

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const remove = (e: any) => {
  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;

  select(data);
};

const text = computed(() => {
  if (feed.value.action === 'created_character') return;
  return `${
    feed.value.action === 'updated_char_text'
      ? 'Key Info'
      : // : feed.value.action === 'updated_char_fields'
      // ? 'About'
      feed.value.action === 'updated_char_profile_img'
      ? 'Profile Image'
      : ''
  }`;
});

const toggleSelection = (e: any) => {
  if (!isSelectable.value || isRemovable.value) {
    return;
  }

  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;

  select(data);
};

const select = (e: CustomEvent) => {
  emits('select', e.detail.checked);
};

const emits = defineEmits(['select', 'react', 'updated', 'delete']);

const deleteCurrGiftBox = () => {
  emits('delete', id.value);
};
const reactionChanged = async (reaction: string, isInstant = false) => {
  if (readOnly.value) {
    return;
  }
  const reactionResp = await react(
    reaction,
    character.value,
    mutatedReactionsData.value.user_reaction,
    mutatedReactionsData.value.reaction_counts,
    false,
    isInstant
  );

  mutatedReactionsData.value = {
    reaction_counts: reactionResp.reaction_counts,
    user_reaction: reactionResp.user_reaction,
  };

  emits('react', !!reactionResp.user_reaction);
};

const username = computed(() => {
  if (character.value.author?.username) {
    return character.value.author?.username;
  }

  if (typeof character.value.user === 'string' && !character.value.author?.username) {
    return character.value.user;
  }
});

onMounted(() => {
  if (limited.value || imageOnly.value) return;
  if (CSS.supports('aspect-ratio: 1')) {
    return;
  }

  handleHeight();

  if (chCard.value) {
    window.addEventListener('resize', () => handleHeight);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', () => handleHeight);
});

const handleHeight = debounce(() => {
  try {
    if (chCard.value) {
      chCard.value.style.height = `${chCard.value.clientWidth}px`;
    }
  } catch (error) {}
}, 500);

const id = computed(() => character?.value?.id);

const doDelete = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this ${isDraft.value ? 'Character Draft' : 'Character'}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const router = useRouter();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      if (id.value) {
        if (isDraft.value) {
          await deleteCharacterDraft(id.value);
          location.reload();
        } else {
          await deleteCharacter(id.value);
          location.reload();
        }
      }
      toast.show(
        isDraft.value ? 'Character Draft deleted successfully' : 'Character deleted successfully',
        'nonative',
        'success'
      );
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const openDropDown = async (ev: CustomEvent) => {
  const popover = await popovers.open(ev, CharacterCardPopover, { character: character.value });
  const { data } = await popover.onDidDismiss();
  if (data === 'delete') {
    doDelete();
  }
};
</script>

<style lang="sass" scoped>
.character-card
  &.horizontal
    background: #FFF
    width: 100%
    min-width: 100%
    aspect-ratio: unset !important
    padding: 12px !important
    .image
      width: 132px
      position: relative
      min-width: 132px
    .tags
      position: absolute
      top: 10px
      left: 10px
    .username
      position: absolute
      left: 10px
      bottom: 0
    .name
      font-size: 16px !important
      a
        color: rgba(33, 65, 99, 1)
    .description
      margin-top: 6px
      span
        color: rgba(33, 65, 99, 1) !important
      *
        margin: 0 !important
      span
        text-overflow: ellipsis
        width: 100%
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
    .actions
      *
        color: rgba(33, 65, 99, 1)
.remove-selection-btn
  z-index: 22
  background: rgba(0,0,0,0.5) !important
  pointer-events: all
  --width: 25px
  --height: 25px
  --padding-start: 8px !important
  --padding-end: 8px !important
  border-radius: 20px
  color: #FFF !important
  min-width: none
  right: -10px
  top: -10px
  overflow: hidden
.disabled
  pointer-events: none
.text-secondary
  *
    color: #3dc2ff !important
.a-wrap
  width: 100%
.loading
  width: 100%
  height: 100%
  z-index: 11
  background: #4A9FC3
.drop-down
  width: 20px
  height: 20px
  color: #FFF
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
.username
  a
    color: #FFDE67
    font-size: 12px
    font-weight: bold
    text-overflow: ellipsis
    width: 100%
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
    text-align: end
.tag
  min-width: 90px
  padding-left: 10px
  padding-right: 10px
  height: 20px
  border: 1.5px solid #FFDE67
  color: #FFDE67
  *
    color: #FFDE67
  background: #0A0928E5
  font-size: 12px
  font-weight: bold
  border-radius: 10px
  &.sm
    min-width: 75px !important
    max-width: 75px !important
.t-tag
  padding-right: 5px
  overflow: hidden
  font-size: 12px
  line-height: 12px
.icon
  font-size: 16px
.actions
  max-width: 200px
  .primary-color
    color: #FFF
  .mr-2
    margin-right: 5px !important
  .ml-2
    margin-left: 5px !important
  *
    font-size: 12px !important
    color: #FFF
  .inline-button
    --ion-color-base: transparent !important
    &:not(:first-of-type)
      margin: 0 5px
.edit-button
  color: #7f10b3
  font-size: 14px
  font-weight: bold
.character-card
  width: 100%
  aspect-ratio: 1
  border-radius: 12px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  cursor: pointer
  &.image-only
    width: 60px
    height: 60px
    img,.char-info,.image
      border-radius: 6px !important
  ::v-deep
    .checkbox-disabled
      opacity: 1 !important
  .image
    background: #4A9FC3
    width: 100%
    height: 100%
    border-radius: 12px
  img
    width: 100%
    height: 100%
    border-radius: 12px
    object-fit: cover
  .name
    font-size: 14px
    font-weight: bold
    a
      color: #FFF
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
  .description
    font-size: 13px
  .icon-button
    color: #ae38e5
    font-size: 20px
    cursor: pointer
    .d-flex
      font-size: 20px
  .icon-button:hover
    opacity: 0.7
  .reaction-count
    font-size: 18px
    font-weight: bold
.primary-color
  color: #7f10b3
.secondary-color
  color: #3dc2ff
.routerlink
  color: #214163
.char-info
  border-radius: 12px
  width: 100%
  height: 100%
  padding: 6px
  background: linear-gradient(180deg, rgba(247,6,207,0) 0%, rgba(0,0,0,0.3) 74%, #170624CF 100%)
</style>
