<template>
  <div
    class="character-card d-flex position-relative"
    :class="{
      active: selectedChar,
      'not-active': !selectedChar,
      'set-width': compBombHubChar,
      'disable-char': character.compliment_bombed && isComplimentBomb,
    }"
  >
    <div
      v-if="isComplimentBomb"
      class="position-absolute checkmark-circle"
      :class="{ 'checkmark-active': selectedChar, 'checkmark-hidden': !selectedChar }"
    >
      <i class="ti-check check-icon" />
    </div>
    <div v-if="isComplimentBomb">
      <MatureTag
        class="ml-2"
        size="sm"
        :class="get(character, 'compliment_bombed') ? 'badge-alignment' : 'mature-badge'"
        v-if="get(character, 'is_nsfw')"
      />
      <div class="position-absolute featured" v-if="get(character, 'compliment_bombed')">
        <ion-badge class="featured-badge">Featured</ion-badge>
      </div>
    </div>
    <div
      class="position-absolute d-flex flex-column justify-content-end char-info"
      :class="{ 'set-width': compBombHubChar }"
    >
      <div class="name">
        <router-link
          class="clickable-item-hov"
          style="color: white"
          :to="{ name: 'character-profile-new', params: { slug: character.slug } }"
          v-if="complimentBombHub"
          @click="emits('close')"
        >
          {{ truncateText(character.info.name) }}</router-link
        >
        <span v-else>
          {{ character.info.name }}
        </span>
      </div>
    </div>
    <div class="image d-flex align-items-center justify-content-center" :class="{ 'set-width': compBombHubChar }">
      <img loading="lazy" v-character-card-image :src="get(character, 'info.cropProfilePicture')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { truncateText } from '@/shared/utils/string';
import MatureTag from '@/shared/components/MatureTag.vue';
import { Character } from '@/shared/types/static-types';

const props = defineProps({
  character: {
    type: Object,
    default: () => {},
  },
  selectedChar: {
    type: Boolean,
    default: false,
  },
  compBombHubChar: {
    type: Boolean,
    default: false,
  },
  isComplimentBomb: {
    type: Boolean,
    default: false,
  },
  complimentBombHub: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close']);
const character = computed(() => props.character as Character);
</script>

<style lang="sass" scoped>
.mature-badge
  position: absolute
  top: 8px
  left: 0px
.badge-alignment
  right:7px !important
  position: absolute
  top: 10px
.featured
  top: 6px
  left: 9px
.featured-badge
  padding: 6px 6px 6px 6px
  display: flex
  align-items: center
.disable-char
  opacity: 0.5
  pointer-events: none
.checkmark-active
  display: none
  visibility: visible
.checkmark-hidden
  visibility: hidden
.check-icon
  font-size: 12px
  color: var(--ion-color-primary)
.checkmark-circle
  border: 3px solid var(--ion-color-primary)
  background: white
  width: 25px
  height: 25px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 20px
  right: 5px
  top: 3px
.set-width
  box-shadow: none !important
  width: 125px !important
  height: 125px !important
  max-width: 100% !important

.active
  border: 4px solid var(--ion-color-primary)
.not-active
  border: none !important

.character-card
  width: 100%
  aspect-ratio: 1
  border-radius: 15px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  .image
    background: #4A9FC3
    width: 100%
    height: 100%
    border-radius: 10px

  img
    width: 100%
    height: 100%
    border-radius: 10px
    object-fit: cover
  .name
    font-size: 14px
    font-weight: bold
    color: white
.char-info
  border-radius: 10px
  width: 100%
  height: 100%
  padding: 9px
  background: linear-gradient(180deg, rgba(247,6,207,0) 0%, rgba(0,0,0,0.3) 74%, #170624CF 100%)
</style>
