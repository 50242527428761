import { defineStore, storeToRefs } from 'pinia';
import {
  Blab,
  Collectible,
  Collection,
  MarketplaceListing,
  Paging,
  SocialSpace,
  UserProfile,
  World,
} from './../types/static-types';
import { authStore } from './auth';
import { Character } from '@/shared/types/static-types';
import {
  getOwnInventory,
  getUserProfileByUsername,
  setPublicInventory,
  updateUserProfile,
} from '@/shared/actions/users';
import {
  getNextPage as getWorldsNextPage,
  getUserStoryWorld,
  getUserStoryWorldByUsername,
} from '@/shared/actions/worlds';
import { getCharacterCollections } from '@/shared/actions/collections';
import { toast } from '@/shared/native';
import { getPosts } from '@/shared/actions/blabs';
import { getUserMarketplaceListings } from '@/shared/actions/marketplace';
import { getNextPage as getCharactersNextPage } from '@/shared/helpers/pagination';
import { postUserBadge } from '@/shared/actions/profileBadges';
import { addCharacterProfileRing, getCharactersByUsername } from '@/shared/actions/characters';
import { addUserProfileRing } from '@/shared/actions/profileRings';
import { onboardingStore } from './onboarding';

export interface IProfileState {
  _currentProfile: UserProfile | null;
  _isLoading: boolean;
  _profileCharacters: Character[];
  _profileCollections: Collection[];
  _profileSocialSpaces: SocialSpace[];
  _profileWorlds: World[];
  _profileMarketplaceListings: MarketplaceListing[];
  _profileMarketplaceListingsPaging: Paging | null;
  _profileCharactersPaging: Paging | null;
  _profileSocialSpacesPaging: Paging | null;
  _profileWorldsPaging: Paging | null;
  _isEditingProfile: boolean;
  _worldsLoading: boolean;
  _postsLoading: boolean;
  _marketplaceLoading: boolean;
  _socialSpacesLoading: boolean;
  _charactersLoading: boolean;
  _collectionsLoading: boolean;
  _profileCollectionsPaging: Paging | null;
  _profileOwnerCollectables: Collectible[];
  _profilePosts: Blab[];
  _profilePostsPaging: Paging | null;
  _followersCount: number;
  _isChangingProfilePicture: boolean;
  _isSaving: boolean;
}

const useProfile = defineStore('profile', {
  state: (): IProfileState => ({
    _currentProfile: null,
    _isLoading: false,
    _postsLoading: false,
    _profileCharacters: [],
    _profileWorlds: [],
    _profileSocialSpaces: [],
    _profileCollections: [],
    _profileCharactersPaging: null,
    _profileSocialSpacesPaging: null,
    _profileWorldsPaging: null,
    _isEditingProfile: false,
    _worldsLoading: false,
    _collectionsLoading: false,
    _charactersLoading: false,
    _socialSpacesLoading: false,
    _profileCollectionsPaging: null,
    _profileOwnerCollectables: [],
    _followersCount: 0,
    _profilePosts: [],
    _profilePostsPaging: null,
    _marketplaceLoading: false,
    _profileMarketplaceListings: [],
    _profileMarketplaceListingsPaging: null,
    _isChangingProfilePicture: false,
    _isSaving: false,
  }),
  actions: {
    async loadProfileOwnerStuff() {
      if (!this.isProfileOwner) {
        return;
      }

      const res: Collectible[] = await getOwnInventory();
      this._profileOwnerCollectables = res;
    },
    onWorldReaction(worldIndex: number, reaction: any) {
      this._profileWorlds[worldIndex] = {
        ...this._profileWorlds[worldIndex],
        ...reaction.updatedReactionsData,
      };
    },
    onSocialSpaceReaction(socialId: string, reaction: any) {
      const index = this._profileSocialSpaces.findIndex((social: any) => social.id === socialId);
      this._profileSocialSpaces[index] = { ...this._profileSocialSpaces[index], ...reaction.updatedReactionsData };
    },
    resetStuff() {
      this._isLoading = false;
      this._profileCharacters = [];
      this._profileWorlds = [];
      this._profileSocialSpaces = [];
      this._profileCollections = [];
      this._profileCharactersPaging = null;
      this._profileSocialSpacesPaging = null;
      this._profileWorldsPaging = null;
      this._isEditingProfile = false;
      this._worldsLoading = false;
      this._collectionsLoading = false;
      this._charactersLoading = false;
      this._socialSpacesLoading = false;
      this._profileCollectionsPaging = null;
      this._profileOwnerCollectables = [];
      this._followersCount = 0;
      this._profilePosts = [];
      this._profilePostsPaging = null;
      this._marketplaceLoading = false;
      this._profileMarketplaceListings = [];
      this._profileMarketplaceListingsPaging = null;
      this._profilePosts = [];
      this._profilePostsPaging = null;
      this._postsLoading = false;
      this._isSaving = false;
    },
    reset() {
      this._currentProfile = null;
      this._isLoading = false;
      this._profileCharacters = [];
      this._profileWorlds = [];
      this._profileSocialSpaces = [];
      this._profileCollections = [];
      this._profileCharactersPaging = null;
      this._profileSocialSpacesPaging = null;
      this._profileWorldsPaging = null;
      this._isEditingProfile = false;
      this._worldsLoading = false;
      this._collectionsLoading = false;
      this._charactersLoading = false;
      this._socialSpacesLoading = false;
      this._profileCollectionsPaging = null;
      this._profileOwnerCollectables = [];
      this._followersCount = 0;
      this._profilePosts = [];
      this._profilePostsPaging = null;
      this._marketplaceLoading = false;
      this._profileMarketplaceListings = [];
      this._profileMarketplaceListingsPaging = null;
      this._profilePosts = [];
      this._profilePostsPaging = null;
      this._postsLoading = false;
      this._isSaving = false;
    },
    toggleEditMode(value: boolean) {
      this._isEditingProfile = value;
    },
    toggleWorldsLoading(value: boolean) {
      this._worldsLoading = value;
    },

    async updateUserProfile(profile: Partial<UserProfile>) {
      try {
        const { currentStep } = onboardingStore();
        const { user } = authStore();

        this._isSaving = true;
        if (
          currentStep.value === 15 ||
          currentStep.value === 16 ||
          currentStep.value === 17 ||
          currentStep.value === 18
        ) {
          this._currentProfile = user.value;
        }
        if (!this._currentProfile) return;

        const userProfileInstance: UserProfile = await updateUserProfile(this._currentProfile.id, profile);

        const { updateUserProfilePicture, hydrateUser, updateUsernameColor, updateUserCoverPicture } = authStore();

        updateUserProfilePicture(userProfileInstance.profile_picture_url);
        updateUserCoverPicture(userProfileInstance.cover_cropped_url);
        updateUsernameColor(userProfileInstance.customize_profile);

        this._currentProfile = {
          ...this._currentProfile,
          ...userProfileInstance,
        };

        hydrateUser();
      } catch (error) {
      } finally {
        this._isSaving = false;
      }
    },
    async updateFeaturedItems(ids: string[]) {
      const featured_collectibles: Collectible[] = await setPublicInventory(ids);

      if (!this._currentProfile) return;

      this._currentProfile = {
        ...this._currentProfile,
        featured_collectibles,
      };
    },
    async getProfileCharacters() {
      try {
        this._charactersLoading = true;
        if (!this._currentProfile) return;

        const { results, ...paging } = await getCharactersByUsername(this._currentProfile.username);

        this._profileCharactersPaging = paging;
        this._profileCharacters = results;
      } catch (error) {
      } finally {
        this._charactersLoading = false;
      }
    },
    async getProfileWorlds() {
      try {
        this._worldsLoading = true;
        if (!this._currentProfile) return;

        const { results, ...paging } = await getUserStoryWorldByUsername(this._currentProfile.username);

        this._profileWorldsPaging = paging;
        this._profileWorlds = results;
      } catch (error) {
      } finally {
        this._worldsLoading = false;
      }
    },
    async loadProfile(username: string, isBadge = false) {
      this._isLoading = true;
      try {
        if (this._currentProfile?.username === username && !isBadge) return;
        const data = await getUserProfileByUsername(username);

        if (!data.value) return;

        const userProfileInstance = data.value;

        this._currentProfile = userProfileInstance;

        this._followersCount = userProfileInstance.follower_count || 0;

        return userProfileInstance;
      } catch (e: any) {
        const router = useRouter();
        router.replace('/');
        if (!e.response) {
          const message = 'Could not reach server. Please try again later.';
          toast.show(message, 'nonative', 'danger');
        } else if (e.response.status === 404) {
          const message = 'User not found';
          toast.show(message, 'nonative', 'danger');
        } else if (e.response.status === 400 && e.response.data.err_msg === 'nsfw_user') {
          const message = 'Profile of a mature user cannot be viewed';
          toast.show(message, 'nonative', 'danger');
        } else {
          const message = 'Unknown error - please try again later.';
          toast.show(message, 'nonative', 'danger');
        }
      } finally {
        this._isLoading = false;
      }
    },
    async postUserProfileBadges(value: any, username: any) {
      try {
        this._isSaving = true;
        if (!this._currentProfile) return;
        const { hydrateUser } = authStore();
        await postUserBadge(value);
        this.loadProfile(username, true);
        hydrateUser();
      } catch (error) {
      } finally {
        this._isSaving = false;
      }
    },
    async postUserProfileRing(value: any, username: any) {
      try {
        this._isSaving = true;
        if (!this._currentProfile) return;
        const { hydrateUser } = authStore();
        get(value, 'char_id') ? await addCharacterProfileRing(value) : await addUserProfileRing(value);
        this.loadProfile(username, true);
        hydrateUser();
      } catch (error) {
      } finally {
        this._isSaving = false;
      }
    },
    loadProfileStuff() {
      this.getProfileCharacters();
      this.getProfileCollections();
      this.getProfileSocialSpaces();
      this.getProfileWorlds();
      this.getProfilePosts();
      this.getProfileMarketplaceListings();
    },
    async getProfileSocialSpaces() {
      try {
        this._socialSpacesLoading = true;
        if (!this._currentProfile) return;

        const { results, ...paging } = await getUserStoryWorld(this._currentProfile.id, 'socialspace', 1);
        this._profileSocialSpaces = results;
        this._profileSocialSpacesPaging = paging;
      } catch (error) {
      } finally {
        this._socialSpacesLoading = false;
      }
    },
    async getProfileCollections() {
      try {
        this._collectionsLoading = true;
        if (!this._currentProfile) return;

        const response = await getCharacterCollections(this._currentProfile.id);
        this._profileCollections = response;
      } catch (error) {
      } finally {
        this._collectionsLoading = false;
      }
    },
    async getProfilePosts() {
      try {
        this._postsLoading = true;
        if (!this._currentProfile) return;

        const { results, ...paging } = await getPosts(1, 3, {
          user: this._currentProfile.id,
          ordering: '-created',
        });
        this._profilePosts = results;
        this._profilePostsPaging = paging;
      } catch (error) {
      } finally {
        this._postsLoading = false;
      }
    },
    async getProfileMarketplaceListings() {
      try {
        this._marketplaceLoading = true;
        if (!this._currentProfile) return;

        const { results, ...paging } = await getUserMarketplaceListings(this._currentProfile.id, 1, 6);
        this._profileMarketplaceListings = results;
        this._profileMarketplaceListingsPaging = paging;
      } catch (error) {
      } finally {
        this._marketplaceLoading = false;
      }
    },
    async loadMoreProfileWorlds() {
      try {
        this._worldsLoading = true;
        if (!this._profileWorldsPaging) return;

        const { results, ...paging } = await getWorldsNextPage(this._profileWorldsPaging);
        this._profileWorlds = this._profileWorlds.concat(results);
        this._profileWorldsPaging = paging;
      } catch (error) {
      } finally {
        this._worldsLoading = false;
      }
    },
    async loadMoreProfileSocialSpaces() {
      try {
        this._socialSpacesLoading = true;
        if (!this._profileSocialSpacesPaging) return;

        const { results, ...paging } = await getWorldsNextPage(this._profileSocialSpacesPaging);
        this._profileSocialSpaces = this._profileSocialSpaces.concat(results);
        this._profileSocialSpacesPaging = paging;
      } catch (error) {
      } finally {
        this._socialSpacesLoading = false;
      }
    },
    async loadMoreProfileCharacters() {
      try {
        this._charactersLoading = true;
        if (!this._profileCharactersPaging) return;

        const { results, ...paging } = await getCharactersNextPage(this._profileCharactersPaging);
        this._profileCharacters = this._profileCharacters.concat(results);
        this._profileCharactersPaging = paging;
      } catch (error) {
      } finally {
        this._charactersLoading = false;
      }
    },
    async loadMoreProfileCollections() {},
  },
  getters: {
    worldsLoading(): boolean {
      return this._worldsLoading;
    },
    socialSpacesLoading(): boolean {
      return this._socialSpacesLoading;
    },
    currentProfile(): UserProfile | null {
      return this._currentProfile;
    },
    isLoading(): boolean {
      return this._isLoading || !this._currentProfile;
    },
    profileCharacters(): Character[] {
      return this._profileCharacters;
    },
    profileCharactersPaging(): Paging | null {
      return this._profileCharactersPaging;
    },
    profileWorldsPaging(): Paging | null {
      return this._profileWorldsPaging;
    },
    profileCollectionsPaging(): Paging | null {
      return this._profileCollectionsPaging;
    },
    profileSocialSpacesPaging(): Paging | null {
      return this._profileSocialSpacesPaging;
    },
    isEditingProfile(): boolean {
      return this._isEditingProfile;
    },
    isChangingProfilePicture(): boolean {
      return this._isChangingProfilePicture;
    },
    profileCollections(): Collection[] {
      return this._profileCollections;
    },
    profileWorlds(): World[] {
      return this._profileWorlds;
    },
    profileSocialSpaces(): SocialSpace[] {
      return this._profileSocialSpaces;
    },
    profileWorldsCount(): number {
      return this._profileWorldsPaging?.count || 0;
    },
    profileSocialSpacesCount(): number {
      return this._profileSocialSpacesPaging?.count || 0;
    },
    profileCharactersCount(): number {
      return this._profileCharactersPaging?.count || 0;
    },
    profileCollectionsCount(): number {
      return this._profileCollections?.length || 0;
    },
    isProfileOwner(): boolean {
      const { user } = authStore();
      return !!this._currentProfile?.id && user.value.id === this._currentProfile?.id;
    },
    profileOwnerCollectables(): Collectible[] {
      return this._profileOwnerCollectables;
    },
    followersCount(): number {
      return this._followersCount;
    },
    charactersLoading(): boolean {
      return this._charactersLoading;
    },
    postsLoading(): boolean {
      return this._postsLoading;
    },
    profilePosts(): Blab[] {
      return this._profilePosts;
    },
    profilePostsPaging(): Paging | null {
      return this._profilePostsPaging;
    },
    marketplaceLoading(): boolean {
      return this._marketplaceLoading;
    },
    profileMarketplaceListings(): MarketplaceListing[] {
      return this._profileMarketplaceListings;
    },
    profileMarketplaceListingsPaging(): Paging | null {
      return this._profileMarketplaceListingsPaging;
    },
    collectionsLoading(): boolean {
      return this._collectionsLoading;
    },
    isSaving(): boolean {
      return this._isSaving;
    },
  },
});

export const profileStore = () => {
  const store = useProfile();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
