import { Onboarding, OnboardingCreateBody, OnboardingUpdateBody } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function updateOnboarding(body: OnboardingUpdateBody): Promise<Onboarding | null> {
  try {
    const { data } = await chAxios().patch(`/onboarding/mine/update/`, body);
    return data;
  } catch (e) {
    return null;
  }
}

export async function getUserOnboarding(): Promise<Onboarding | null> {
  try {
    const { data } = await chAxios().get(`/onboarding/mine/`);
    return data;
  } catch (e) {
    return null;
  }
}

export async function createOnboarding(body: OnboardingCreateBody): Promise<Onboarding | null> {
  try {
    const { data } = await chAxios().post(`/onboarding/`, body);
    return data;
  } catch (e) {
    return null;
  }
}
