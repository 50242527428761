import { authStore } from '@/shared/pinia-store/auth';
import { isMobileFn } from '@/apps/mobile/native/device';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;

  const { requireAuth, isPublic } = to.meta;
  const { authToken } = authStore();
  const isMobile = await isMobileFn();

  if (!authToken.value) {
    to.meta.layout = 'no-auth';
  }

  if (isMobile && to.fullPath === '/') {
    return navigateTo('/login');
  }

  if (isPublic) {
    return;
  }

  if (!requireAuth && authToken.value) {
    return navigateTo('/home');
  }
});
