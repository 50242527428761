import axios from 'axios';
import { getAuthTokenHeader } from '@/shared/services/auth';
import { chAxios } from '@/shared/lib/axios';
import { toast } from '@/shared/native/toast';

export const requestUploadImageUrl = async (payload = {}) => {
  const url = `/up-req/`;
  const headers = await getAuthTokenHeader();
  return chAxios()
    .post(url, payload, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const sendImageToS3 = async (
  url: string,
  params: { AWSAccessKeyId: string; key: string; policy: string; signature: string },
  fileToSave: any
) => {
  let res;
  const fd = new FormData();
  fd.append('Content-Type', fileToSave.type);
  fd.append('Cache-Control', 'max-age=31536000');
  fd.append('AWSAccessKeyId', params.AWSAccessKeyId);
  fd.append('key', params.key);
  fd.append('policy', params.policy);
  fd.append('signature', params.signature);
  fd.append('file', fileToSave);
  res = await axios.post(url, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.status;
};

export const upload = async (imagefile: Blob) => {
  try {
    await toast.show('Uploading Image...', 'nonative', 'primary');

    const threePostRequests = await requestUploadImageUrl();

    const statusCode = await sendImageToS3(threePostRequests.l.url, threePostRequests.l.fields, imagefile);

    if (statusCode >= 400) throw new Error('Encountered an error while uploading image.');

    const imageData = await sendImageUrlToBackend(threePostRequests.l.fields.key, threePostRequests.l.url);

    return imageData.image;
  } catch (e) {
    const error: any = e;
    const body = 'Could not upload file.';
    const message = error ? error.message || body : body;
    await toast.show(message, 'nonative', 'danger');
  }
};

export const sendImageUrlToBackend = async (key: string, s3Url: string, params = {}) => {
  const {
    public: { baseCDNUrl },
  } = useRuntimeConfig();
  const cdnImageUrl = `${baseCDNUrl}/${key}`;
  const postUrl = `/images/`;
  const out = { image: cdnImageUrl, ...params };
  const res = await chAxios().post(postUrl, out);
  return res.data;
};

export const getImageObject = async () => {
  // const {
  //   public: { baseCDNUrl },
  // } = useRuntimeConfig();
  // const cdnImageUrl = `${baseCDNUrl}/${key}`;
  const postUrl = `/images/`;
  const out = { image: null };
  const res = await chAxios().post(postUrl, out);
  return res.data;
};
