<template>
  <ion-page class="page character-profile-page position-relative">
    <Transition>
      <div v-if="loader" class="loading-content position-absolute">
        <HeaderLoading />
      </div>
      <div v-else-if="character" class="content">
        <Header
          :character="character"
          :is-draft="isDraft"
          :customize="currentTemplate"
          :is-compliment-bomb="!isEmpty(charSlug)"
          :char-status="charStatus"
          :chars-count="charsCount"
          :cb-status="cbCharStatus"
          :inventory="inventory"
          :allowComplimentBomb="allowComplimentBomb"
          @fetch="getCharCustomize"
          @updated="fetchCbCharStatus"
          @fetchCharacter="fetchCharacterDetails"
        />
        <Actions v-if="hasSections" class="my-3" :sections-collapsed="sectionsCollapsed" :customize="currentTemplate" />
        <blab-card
          v-if="mainPostCount"
          :user-post="userPosts"
          :count="countPost"
          :is-owner="isCharacterOwner"
          :customize="currentTemplate"
          class="my-3"
          :blabs-count="blabsCount"
          :character="true"
          :char-name="character.info.name"
          :char-id="character.id"
          :slug="character.slug"
          @tabchanged="fetchUserPosts"
        />
        <Sections
          :character="character"
          :is-compliment-bomb="isComplimentBomb"
          :customize="currentTemplate"
          @fetch="loadCharacterBySlug(slugAsString)"
        />

        <CollapsableSection v-if="!isEmpty(characterWorlds)" class="mb-4" title="Worlds" :customize="currentTemplate">
          <Grid :xl="2" :lg="3" :md="2" :sm="1" :scrollSm="false">
            <StoryWorldCard
              limited
              v-for="(world, index) of characterWorlds"
              :key="index"
              class="grid-item clickable-item-hov"
              :world="world"
              @click.prevent="goToWorldNewTab(world.slug)"
            />
          </Grid>
        </CollapsableSection>

        <CharacterComments v-if="character && isEmpty(charSlug)" :character="character" :customize="currentTemplate" />
      </div>
    </Transition>
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController, toastController } from '@ionic/vue';
import Header from './components/Header.vue';
import Sections from './components/Sections.vue';
import Actions from './components/Actions.vue';
import CharacterComments from './components/CharacterComments.vue';
import HeaderLoading from './components/HeaderLoading.vue';
import CollapsableSection from './components/CollapsableSection.vue';
import { Character } from '@/shared/types/static-types';
import { getInventoryDetails } from '@/shared/actions/complimentBomb';
import Grid from '@/shared/components/storage/Grid.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';
import { getPosts, getPostsCount } from '@/shared/actions/blabs';
import { authStore } from '@/shared/pinia-store/auth';
import BlabCard from '@/shared/components/BlabCard.vue';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { getUserCharStatus } from '@/shared/actions/users';
import { COMPLIMENT_BOMB, unreorderableEditorKeys } from '@/shared/statics/constants';
import { toast } from '@/shared/native';

import { getComplimentBombCharacterStatus } from '@/shared/actions/complimentBomb';

const { user: loggedInUser, isCreatedToast, isSavedToast, isAuthenticated, loadCurrencyBoxImage } = authStore();
const props = defineProps({
  charSlug: { type: String, default: '' },
  isComplimentBomb: { type: Boolean, default: false },
});

const router = useRouter();

const picture = ref('');
const title = ref('');
const description = ref('');
const blabsCount = ref({});
const cbCharStatus: any = ref({});
const pageTitle = ref('');
const allowCrawlers = ref(true);
const allowAiBots = ref(false);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);
const { updatePreviousCustomize } = authStore();
const charSlug = toRef(props, 'charSlug');
const {
  character,
  loadCharacterBySlug,
  loadDraftCharacterById,
  sectionsCollapsed,
  reset,
  fetchPendingCharacterTransferRequest,
  fetchCharacterOwnershipHistory,
  loadCharacterRelationships,
} = characterProfileStore();

const {
  loadCharacterStylize,
  currentTemplate,
  loadCharacterTemplate,
  clearAll: clearAllCustomization,
} = characterStylizeStore();

const countPost = ref('');
const mainPostCount = ref('');
const userPosts = ref([]);
const inventory: any = ref({});
const charStatus = ref(false);
const charsCount = ref(0);
const loader = ref(false);
const slugAsString = ref('');
const idAsString = ref('');
const isComplimentBomb = toRef(props, 'isComplimentBomb');
const characterWorlds = computed(() => {
  return get(character.value, 'worlds');
});

useCommentHighlighter(loader);

const hasMoreThan2Char = async () => {
  const resp = await getUserCharStatus();
  charStatus.value = resp.characters_customization;
  charsCount.value = resp.characters_count;
};

const goToWorldNewTab = (slug: string) => {
  router.push({ name: 'world-details', params: { slug } });
};

const isDraft = computed(() => {
  return route.name === 'character-profile-draft-new' || route.name === 'char-draft-details';
});

const getCharCustomize = async () => {
  const type = isDraft.value ? 'characterdraft' : 'character';
  await loadCharacterStylize(get(character.value, 'id'), type);
};
const possibleSections = computed(() => {
  return omit(character?.value?.info, unreorderableEditorKeys as string[]);
});

const isCharacterOwner = computed(() => {
  try {
    return character.value?.author?.id === loggedInUser.value?.id;
  } catch (error) {
    return false;
  }
});

const reorderedSections = computed(() => {
  const order: string[] = character?.value?.info?.order || Object.keys(possibleSections.value);
  const reorderedSections = order.reduce((acc: Character['info'], current) => {
    return {
      ...acc,
      [current]: possibleSections.value[current],
    };
  }, {} as Character['info']);

  return reorderedSections;
});

const hasSections = computed(() => {
  return Object.keys(reorderedSections.value).length > 0;
});

const triggerWarning = async () => {
  if (
    character.value &&
    loggedInUser.value.id !== character.value.author?.id &&
    !isEmpty(character.value.info.warningMessage)
  ) {
    const alert = await alertController.create({
      backdropDismiss: false,
      cssClass: 'char-warning-alert',
      header: 'Warning',
      message: character.value.info.warningMessage,
      translucent: false,
      buttons: [
        {
          text: 'Go Back',
          handler: () => {
            router.go(-1);
          },
        },
        { text: 'Continue' },
      ],
    });
    await alert.present();

    await alert.onDidDismiss();
  }
};

const nsfwWarning = async () => {
  if (character.value) {
    if (!isAuthenticated.value && (character.value.is_nsfw || character?.value?.author?.is_nsfw)) {
      const alert = await alertController.create({
        cssClass: 'char-warning-alert',
        header: 'Warning',
        message: `You are about to see potentially mature content, by proceeding you confirm you are 18 years of age or older.`,
        buttons: [
          {
            text: 'Continue',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'mx-auto',
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        triggerWarning();
      }
    } else {
      triggerWarning();
    }
  }
};

const fetchPostsCount = async () => {
  if (!character.value) return;

  const resp = await getPostsCount({ character_in_post: character.value.id });
  blabsCount.value = resp;
};

const fetchCbCharStatus = async () => {
  if (!isAuthenticated.value) return;
  const resp = await getComplimentBombCharacterStatus(character.value!.slug as string);
  cbCharStatus.value = resp;
};

const fetchCharacterDetails = async () => {
  isDraft.value ? await loadDraftCharacterById(idAsString.value) : await loadCharacterBySlug(slugAsString.value);
};

const allowComplimentBomb = computed(() => {
  return character.value!.allow_comp_bomb && !cbCharStatus.value.compliment_bombed;
});

const fetchConsumableInventoryDetails = async () => {
  if (!isAuthenticated.value) return;
  const resp = await getInventoryDetails({ type: 'consumable', object: COMPLIMENT_BOMB });
  inventory.value = resp[0];
};

const fetchUserPosts = async (value: any, page = 1) => {
  if (!character.value) return;
  let res: any;
  if (!isEmpty(value)) {
    res = await getPosts(page, 2, {
      character_in_post: character.value.id,
      ordering: '-created',
      category: value,
    });
  } else {
    res = await getPosts(page, 2, {
      character_in_post: character.value.id,
      ordering: '-created',
    });
    mainPostCount.value = res.count;
  }

  countPost.value = res.count;
  userPosts.value = res.results;
};

await useChAsyncData(async () => {
  if (isDraft.value) {
    loader.value = true;
    const { id } = route.params;
    idAsString.value = id as string;
    await loadDraftCharacterById(idAsString.value);
    const charName = get(character.value, 'info.name');
    if (charName) pageTitle.value = `${charName} - CharacterHub`;
    if (process.client) await triggerWarning();
    loader.value = false;
    return;
  }
  loader.value = true;
  const { slug } = route.params;
  slugAsString.value = !isEmpty(charSlug.value) ? charSlug.value : (slug as string);

  try {
    await loadCharacterBySlug(slugAsString.value);
  } catch (error: any) {
    if (error.response?.status === 404) {
      loader.value = false;
      router.push({ name: 'home' });
    }
  }

  const charName = get(character.value, 'info.name');
  if (charName) pageTitle.value = `${charName} - CharacterHub`;
  description.value = `Read more about ${character?.value?.info?.name} on CharacterHub!`;
  title.value = `${character?.value?.info?.name}`;
  picture.value = character?.value?.info?.cropProfilePicture || character?.value?.info?.cover || '';
  allowCrawlers.value =
    character?.value?.privacy === 'P' && (character?.value?.reaction_counts?.total_count || 0) > 200;

  useChHead(pageTitle, title, description, url, picture, allowCrawlers, allowAiBots);

  await fetchCharacterOwnershipHistory();

  if (isCharacterOwner.value && !isDraft.value) {
    await fetchPendingCharacterTransferRequest();
  }

  if (process.client) nsfwWarning();

  loader.value = false;
});

watch(charSlug, async () => {
  if (charSlug.value && isComplimentBomb.value) {
    clearAllCustomization();
    reset();
    await loadCharacterBySlug(charSlug.value);
  }
});

watch(
  character,
  (value: any) => {
    if (value) {
      getCharCustomize();
      fetchUserPosts('');
      fetchPostsCount();
      if (!isDraft.value && character.value?.allow_comp_bomb) fetchCbCharStatus();
      if (isAuthenticated.value && !isDraft.value && allowComplimentBomb.value) fetchConsumableInventoryDetails();
      if (character.value?.author?.username === loggedInUser.value.username) {
        loadCharacterTemplate();
        hasMoreThan2Char();
      }
    }
  },
  { immediate: true }
);

onMounted(async () => {
  await loadCharacterRelationships();
  if (isAuthenticated.value && !isDraft.value) {
    await loadCurrencyBoxImage();
  }

  if (isSavedToast.value || isCreatedToast.value) {
    try {
      const toastControl = await toastController.create({
        message: `<strong>Template</strong> Successfully <strong> ${isSavedToast.value ? 'Saved' : 'Created'}</strong>`,
        duration: 2000,
        cssClass: 'toast-custom-class',
        position: 'top',
      });
      const toasts = document.getElementsByClassName('toast-custom-class');
      toasts[0].setAttribute('style', 'top:58px');
      toastControl.present();
    } catch (_err) {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
  } else {
    updatePreviousCustomize({
      previousCustomizeData: {},
      isUndo: false,
    });
  }
});

onBeforeRouteLeave((_, __, next) => {
  clearAllCustomization();
  next();
});
</script>

<style lang="sass" scoped>
.loading-content
  width: calc(100% - 1.5rem)
.character-profile-page
  overflow: hidden
  padding: 12px !important
  // margin-top: -40px !important
  min-height: 628px
.v-enter-active,
.v-leave-active
  transition: opacity 0.5s ease

.v-enter-from,
.v-leave-to
  opacity: 0
.profile-section
  border-radius: 20px
  overflow: hidden
  --ion-color-base: #E6E6E6
ion-item
  --inner-padding-end: 15px !important
  --inner-padding-start: 5px !important
  --inner-padding-top: 5px !important
  --inner-padding-bottom: 5px !important
  --ion-color-base: #E6E6E6 !important
  font-size: 24px
  font-weight: 800
  color: #333333
  &.dark
    --ion-color-base: #17074C !important
    overflow: hidden
  .content
    background: #E6E6E6
    padding: 5px 22px 15px 22px
    font-size: 16px
    color: #333333
.dark .profile-section .content
  background: #17074C !important
</style>
