<template>
  <div
    class="ring-card position-relative p-3 clickable-item-hov d-flex justify-content-center flex-column"
    :class="ringCardClass"
    :style="ringCardStyle"
    @click="openPurchaseModal"
  >
    <div class="position-absolute checkmark-circle" :class="checkmarkClass">
      <i class="ti-check check-icon" />
    </div>
    <vue-countdown
      v-if="iteminStoretimer"
      v-slot="{ days, hours, minutes, seconds }"
      :time="formatFromNowInMilliseconds(iteminStoretimer)"
      @end="fetchRingCategories"
      class="w-100"
    >
      <div v-if="whiteCardColor" class="timer mb-2" :style="{ color: timerClass }">
        <i class="ti-time mr-1 time-icon" />
        <span>
          <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
          ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
        </span>
      </div>
    </vue-countdown>
    <!-- <div class="rings mx-auto" style="border: 4px solid green" /> -->
    <ion-icon v-if="item.icon" class="mr-1 prf-ring-icon mx-auto" style="color: red" :icon="banOutline" />
    <div
      class="position-relative d-flex align-items-center justify-content-center mx-auto mb-3"
      :style="
        !iteminStoretimer && whiteCardColor && width > 320
          ? 'margin-top:36px'
          : !iteminStoretimer && whiteCardColor && width <= 320
          ? 'margin-top:24px'
          : ''
      "
      v-else
    >
      <img id="charimg" :src="profilePicture" class="user-prf-pic" />
      <img :src="item.image" class="prf-ring-img" />
    </div>
    <div :class="{ 'set-direction': !whiteCardColor }" class="mt-auto">
      <div
        class="align-items-center d-flex text-center justify-content-center position-relative"
        :style="isEmpty(get(item, 'virtual_item_type')) && whiteCardColor ? 'margin-top:24px' : ''"
      >
        <div class="truncate">{{ item.title }}</div>
      </div>
      <div class="d-flex text-center justify-content-center">
        <div v-if="get(item, 'virtual_item_type')" :style="{ color: vItemColor }" class="types truncate">
          {{ itemTypeName }}
        </div>
      </div>
      <div
        class="mt-auto align-items-center d-flex text-center justify-content-center flex-column mx-2 position-relative"
      >
        <div
          class="px-2 align-items-center d-flex text-center justify-content-center item-btn position-relative"
          v-if="amountSection"
          :class="{ 'item-btn-size': item.is_bought }"
        >
          <i v-if="item.is_bought" class="ti-check mr-1" style="color: #6adf32" />
          <div
            class="token mr-1"
            v-if="virtualItem && !item.is_bought"
            :style="prfaddOns ? 'height:21px !important' : ''"
          >
            <CurrencyImage />
            <inline-svg :src="virtualItem" class="token-svg" />
          </div>
          <span class="amount">{{ item.is_bought ? 'Purchased' : item.amount.toLocaleString() }}</span>
        </div>
      </div>
    </div>

    <RingBearerPurchaseModal
      :is-open="isOpenRingModal"
      @close="isOpenRingModal = false"
      @purchase="closeRingModal"
      :item="item"
      :token="virtualItem"
    />

    <RingBearerPurchaseModal
      :is-open="isOpenRingPurchaseModal"
      :item="item"
      :token="virtualItem"
      @updated="closePurchaseRingModal"
      :isPurchased="isPurchased"
    />
  </div>
</template>

<script lang="ts" setup>
import { banOutline } from 'ionicons/icons';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';
import RingBearerPurchaseModal from '@/shared/modals/ItemsPurchaseModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import NotEnoughCoinsPopover from '@/shared/pages/store/popovers/NotEnoughCoinsPopover.vue';
import { popovers } from '@/shared/native/popovers';
import { mainStore } from '@/shared/pinia-store/main';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';

const props = defineProps({
  whiteCardColor: { type: Boolean, default: false },
  amountSection: { type: Boolean, default: false },
  item: { type: Object, default: () => ({}) },
  virtualItem: { type: String, default: '' },
  selectedRing: { type: Boolean, default: false },
  selectedUserChar: { type: Object, default: {} },
  prfaddOns: { type: Boolean, default: false },
});
const { width } = useWindowSize();
const emits = defineEmits(['updated', 'fetch']);
const whiteCardColor = toRef(props, 'whiteCardColor');
const item = toRef(props, 'item');
const isPurchased = ref(false);
const isOpenRingPurchaseModal = ref(false);
const isOpenRingModal = ref(false);
const { usersCurrency, user } = authStore();
const selectedUserChar = toRef(props, 'selectedUserChar');
const { dark } = mainStore();
const ringCardClass = computed(() => ({
  'feature-clr': whiteCardColor.value,
  'border-clr': !props.selectedRing && !whiteCardColor.value,
  active: props.selectedRing && !whiteCardColor.value,
  'disabled-card': get(item.value, 'is_bought'),
}));

const iteminStoretimer = computed(() => {
  return get(item.value, 'in_store_till');
});
const ringCardStyle = computed(() => ({
  'min-height':
    whiteCardColor.value && width.value >= 400
      ? '260px'
      : whiteCardColor.value && width.value <= 320
      ? '200px'
      : whiteCardColor.value && width.value <= 400
      ? '220px'
      : !whiteCardColor.value && width.value >= 400
      ? '198px'
      : '160px',
}));

const checkmarkClass = computed(() =>
  props.selectedRing && !whiteCardColor.value ? 'checkmark-active' : 'checkmark-hidden'
);

const timerClass = computed(() => {
  if (!iteminStoretimer.value) return;
  const remainingHours = formatFromNowInMilliseconds(get(item.value, 'in_store_till')) / (1000 * 60 * 60);
  return remainingHours < 2
    ? '#ffab10'
    : dark.value && remainingHours >= 2
    ? '#fff'
    : remainingHours < 24
    ? '#451f8a'
    : '#214163';
});
const imageWdhAndHgt = computed(() => {
  return width.value >= 650
    ? 100
    : width.value <= 390 && width.value >= 320
    ? 60
    : width.value <= 320 && width.value >= 290
    ? 47
    : width.value <= 290
    ? 34
    : 80;
});

const itemTypeName = computed(() => {
  return get(item.value, 'virtual_item_type.title');
});
const vItemColor = computed(() => {
  return get(item.value, 'virtual_item_type.color_code', '#328FD2');
});

const fetchRingCategories = () => {
  emits('fetch');
};

const openPurchaseModal = (ev: MouseEvent) => {
  if (whiteCardColor.value) {
    usersCurrency.value.coins >= item.value.amount
      ? (isOpenRingModal.value = true)
      : popovers.open(ev, NotEnoughCoinsPopover);
  }
};
const profilePicture = computed(() => {
  return isEmpty(selectedUserChar.value)
    ? user.value.profile_picture_url
    : !isEmpty(get(selectedUserChar.value, 'slug')) || get(selectedUserChar.value, 'type') === 'draft'
    ? get(selectedUserChar.value, 'info.cropProfilePicture', '/empty.png')
    : get(selectedUserChar.value, 'profile_picture_url');
});

const ringMargin = computed(() => {
  const ring = Math.ceil((imageWdhAndHgt.value * (25 / 100)) / 2);
  return ring;
});

const ringBorderWidth = computed(() => {
  let ringImgSize: any = Math.ceil(imageWdhAndHgt.value + imageWdhAndHgt.value * (25 / 100));
  return ringImgSize;
});

const closeRingModal = () => {
  isOpenRingModal.value = false;
  setTimeout(() => {
    isPurchased.value = true;
    isOpenRingPurchaseModal.value = true;
  }, 100);
};

const closePurchaseRingModal = () => {
  isOpenRingPurchaseModal.value = false;
  setTimeout(() => emits('updated'), 150);
};
</script>

<style lang="sass" scoped>
.time-icon
  font-size: 14px
  font-weight: bold
  @media(max-width:383px)
    font-size: 0.7rem !important
  @media(max-width:335px)
    font-size: 0.5rem !important
.timer
  font-size: 15px
  font-weight: bold
  border-radius: 20px
  border: 2px solid
  display: flex
  align-items: center
  text-align: center
  justify-content: center
  margin-top: 0.15rem
  color: #214163
  @media(max-width:500px)
    font-size: 0.7rem !important
  @media(max-width:336px)
    font-size: 6px !important
.types
  font-weight: bold
  @media(max-width:450px)
    font-size: 13px
.user-prf-pic
  width: v-bind('`${imageWdhAndHgt}px`')
  height: v-bind('`${imageWdhAndHgt}px`')
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  object-fit: cover

.set-direction
  display: flex
  flex-direction: column-reverse
.disabled-card
  pointer-events: none !important
.active
  border: 3px solid #ae38e5 !important
.checkmark-circle
  border: 3px solid var(--ion-color-primary)
  background: white
  width: 25px
  height: 25px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 20px
  right: 5px
  top: 3px

.checkmark-active
  visibility: visible
.checkmark-hidden
  visibility: hidden
.check-icon
  font-size: 12px
  color: var(--ion-color-primary)
.amount
  font-weight: bold
  @media(max-width:560px)
    font-size: 12px
  @media(max-width:380px)
    font-size: 10px !important
  @media(max-width:350px)
    font-size: 8px !important
.dark .amount
  color: white
.prf-ring-icon
  width: 100px
  height: 94px
  object-fit: contain
  min-width: 100%
  max-width: 100%
  color: red
  @media(max-width:391px)
    height: 60px

.prf-ring-img
  position: absolute
  top: v-bind('`-${ringMargin}px`')
  left: v-bind('`-${ringMargin}px`')
  height: v-bind('`${ringBorderWidth}px`')
  width: v-bind('`${ringBorderWidth}px`')
  min-width: v-bind('`${ringBorderWidth}px`')
.token-svg
  width: 15px
  height: 15px
  @media(max-width:350px)
    width: 12px !important
    height: 12px
  @media(max-width:305px)
    width: 10px !important
    height: 10px

.token
  width: 15px
  height: 26px
  @media(max-width:350px)
    width: 12px !important
    height: 25px
  @media(max-width:305px)
    width: 10px !important
    height: 25px

.item-btn
  --background: transparent
  border: 2px solid #c6c8d6
  color: #214163
  height: 32px
  border-radius: 20px
  @media(max-width:350px)
    height: 22px !important
  @media(max-width:305px)
    height: 20px !important
.item-btn-size
  @media(max-width:380px)
    width:76px
.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  font-size: 16px

.rings
  margin: 8px
  border-radius: 50px
  width: 67px
  height: auto
  aspect-ratio: 1/1

.ring-section
  margin: 3px
  border-radius: 10px
  position: relative
  width: 145px
  padding-bottom: 10px
  @media(max-width: 500px)
    width: 135px
.border-clr
  border: 2px solid white !important
.feature-clr
  border: 2px solid #f5ebff
  background: white
  aspect-ratio: 1
  box-shadow: 1px 4px 1px 1px rgba(33, 65, 99, 0.19)
.dark .feature-clr
  background: #17074c
.ring-card
  height: 100%
  width: 100%
  border-radius: 17px
  position: relative
</style>
