import { chAxios } from '@/shared/lib/axios';

export async function getComments(objId: string, objType: string, page = 1) {
  const url = `/comments/`;
  try {
    const { data } = await chAxios().get(url, { params: { commented_on_id: objId, commented_on_type: objType, page } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteComment(id: string) {
  const url = `/comments/${id}/`;
  await chAxios().delete(url);
}

export async function updateComment(id: string, textcomment: string) {
  const url = `/comments/${id}/`;
  try {
    const { data } = await chAxios().patch(url, { text: textcomment });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createComment(commentData: {
  text: string;
  commenter_id: string;
  commenter_type: string;
  commented_on_type: string;
  commented_on_object_id: string;
  parent_comment?: string | null | undefined;
  compliment_bomb?: string | null | undefined;
}) {
  try {
    const { data } = await chAxios().post(`/comments/`, commentData);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getCommentsCount(objIds: string[], objType: string) {
  const url = `/comments/count/`;
  try {
    const { data } = await chAxios().post(url, { commented_on_object_ids: objIds, commented_on_type: objType });
    return data;
  } catch (e) {
    throw e;
  }
}
