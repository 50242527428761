import { IonicVue } from '@ionic/vue';
// @ts-ignore
import vClickOutside from 'click-outside-vue3';
// @ts-ignore
import { Chrome, create } from '@ckpack/vue-color';
import { Vue } from 'vue-class-component';
import { VueHammer } from './files/vue-hammer';

export default defineNuxtPlugin((nuxtApp) => {
  Vue.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);
  nuxtApp.vueApp
    .use(VueHammer)
    .use(IonicVue, { innerHTMLTemplatesEnabled: true })
    .use(vClickOutside)
    .use(
      create({
        components: [Chrome],
      })
    );
});
