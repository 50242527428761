<template>
  <client-only>
    <div>
      <UploadForm
        ref="uploader"
        class="d-none"
        :restrictGif="restrictGif"
        @loading="loadingCoverChanged"
        @uploaded="handleChangeCover"
        @dismiss="dismiss"
      />
      <div v-if="loadingCover" class="loading d-flex flex-column align-center">
        <div class="px-3 py-2">Uploading</div>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
      <UploadForm
        only-blob-url
        class="cover-uploader"
        :restrictGif="restrictGif"
        :class="{ hide: loadingCover }"
        @loading="loadingCoverChanged"
        @uploaded="handleChangeCover"
        @dismiss="dismiss"
      >
        <ion-item class="item-container pointer">
          <div class="d-flex">
            <i class="ti-layout-grid4-alt" />
            <div class="ml-2 menu-item d-flex align-items-center">Choose a photo</div>
          </div>
        </ion-item>
      </UploadForm>
      <ion-item
        v-if="isUserProfile ? userCover : isUserEditProfile ? userTempCover : cover"
        class="item-container d-flex pointer"
        @click="clearCover"
      >
        <i class="ti-eraser" />
        <div class="ml-2 menu-item d-flex align-items-center">Clear Cover Picture</div>
      </ion-item>

      <CropImageModal
        :image-url="cropImage"
        :is-open="isOpen"
        outline-style="cover"
        @dismiss-modal="closeCropPreview"
        @crop-done="cropCoverPicture"
      />
    </div>
  </client-only>
</template>

<script lang="ts" setup>
import UploadForm from '@/shared/components/upload-form.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import CropImageModal from '@/shared/modals/CropImageModal.vue';
import { profileStore } from '@/shared/pinia-store/profile';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  instance: {
    type: Object,
  },
  isUserProfile: {
    type: Boolean,
    default: false,
  },
  isUserEditProfile: {
    type: Boolean,
    default: false,
  },
  restrictGif: {
    type: Boolean,
    default: false,
  }
});

const instance = props.instance;
const isOpen = ref(false);
const cropImage = ref('');
const { character } = characterEditorStore();
const loadingCover = ref(false);
const uploader = ref(null);
const isUserProfile = toRef(props, 'isUserProfile');
const isUserEditProfile = toRef(props, 'isUserEditProfile');

const { currentProfile } = profileStore();
const {
  updateCurrentUserTempCoverPicture,
  userCurrentTempCoverPic,
  changeCoverWithNoAdditionalSteps,
  changeUserCoverPicture
} = authStore();

const changeUserEditCoverPicture = async (url: string) => {
  updateCurrentUserTempCoverPicture(url);
};

const handleChangeCover = (url: any) => {
  if (url && typeof url === 'string') {
    if (url.startsWith('blob')) openCropPreview(url);
    else {
      changeCoverWithNoAdditionalSteps(url, isUserProfile.value, isUserEditProfile.value);
      isOpen.value = false;
      cropImage.value = '';
      dismiss(true);
    }
  }
};

const openCropPreview = (imgUrl?: string) => {
  if (!imgUrl) return;
  cropImage.value = imgUrl;
  isOpen.value = true;
};

const closeCropPreview = async () => {
  try {
    if (cover.value.includes('blob') || userCover.value.includes('blob')) {
      const blob = await fetch(
        isUserProfile.value ? userCover.value : isUserEditProfile.value ? userTempCover.value : cover.value
      ).then((r) => r.blob());
      changeCoverWithNoAdditionalSteps(blob, isUserProfile.value, isUserEditProfile.value);
    }
  } catch (e) {
  } finally {
    isOpen.value = false;
    cropImage.value = '';
    dismiss();
  }
};

const cover = computed(() => {
  return character?.value?.info?.cover || '';
});
const userCover = computed(() => {
  return currentProfile.value?.cover_cropped_url || '';
});
const userTempCover = computed(() => {
  return userCurrentTempCoverPic.value || '';
});

const loadingCoverChanged = (loading: boolean) => {
  loadingCover.value = loading;
};

const cropCoverPicture = async (image: Blob) => {
  isOpen.value = false;
  dismiss(true);

  await changeCoverWithNoAdditionalSteps(image, isUserProfile.value, isUserEditProfile.value);

  cropImage.value = '';
};

const clearCover = () => {
  if (isUserProfile.value) {
    changeUserCoverPicture('');
  } else if (isUserEditProfile.value) {
    changeUserEditCoverPicture('');
  }

  dismiss(true);
};

const dismiss = (noContinue?: boolean) => {
  if (instance && noContinue) {
    instance.dismiss();
  }
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
.hide
  visibility: hidden
</style>
