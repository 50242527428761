import { chAxios } from '@/shared/lib/axios';
import type { Paging } from '@/shared/types/static-types';
import { sendAnalyticsEvent } from '@/shared/services/analytics';

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}
export async function characterEventTime() {
  const url = '/events/tasks/character/poll/event-start-remaining-time/';
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getPastEvents() {
  const url = `/events/expired/`;
  const { data } = await chAxios().get(url, { params: { page: 1 } });
  return data;
}

export async function getActiveEvents() {
  const url = `/events/`;
  const { data } = await chAxios().get(url, { params: { page: 1 } });
  return data;
}

export async function getActiveEventTasks() {
  const url = `/events/tasks/active/`;
  const { data } = await chAxios().get(url, { params: { page: 1 } });
  return data;
}

export async function getEventTasksCount() {
  const url = `/events/tasks/new-event-count/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventDetails(slug: string) {
  const url = `/events/slug/?slug=${slug}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventTask(id: string) {
  const url = `/events/tasks/${id}/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventTasks(id: string) {
  const url = `/events/tasks/event/?id=${id}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventTasksWithSubmissions(id: string) {
  const url = `/events/tasks/event/?id=${id}&submitted=1`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getAllEventTasks(id: string) {
  const url = `/events/tasks/event-all/?id=${id}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventTaskPoll(id: string) {
  const url = `/events/tasks/polls/${id}/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventTaskPollResults(id: string) {
  const url = `/events/tasks/polls/results/?id=${id}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserTaskSubmissions(id: string, all?: boolean) {
  const url = `/events/submissions/task/`;
  const { data } = await chAxios().get(url, { params: { id, page: 1, all } });
  return data;
}
export async function getCharactersEventPollCounts() {
  const url = `/events/tasks/character/poll/my-oc-count/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getPublicTaskSubmissions(id: string, s?: string, params = {}) {
  const url = `/events/submissions/task-public/`;
  const { data } = await chAxios().get(url, { params: { id, s, page: 1, page_size: 30, ...params } });
  return data;
}

export async function getPublicTaskSubmissionsFilters(id: string) {
  const url = `/events/submissions/default-sorting/`;
  const { data } = await chAxios().get(url, { params: { id } });
  return data;
}

export async function getAllInPoolChar() {
  const url = `/events/tasks/character/poll/all-in/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function submitToEventTask(id: string, payload: any) {
  const url = `/events/submissions/?task=${id}`;
  const response = await chAxios().post(url, payload);
  if (response?.status > 0 && response?.status <= 299) {
    sendAnalyticsEvent('Quest submission created');
  }
  const { data } = response;
  return data;
}
export async function fetchMyOcCharacters(params = {}) {
  const url = `/characters/my-oc-chars/`;
  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}

export async function fetchDecorCharacters(id: string, page = 1) {
  const url = `/events/tasks/character/poll/decorate-char/?eventtask_id=${id}&page=${page}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function submitCharacterPoll(payload: any) {
  const url = `/events/tasks/character/poll/submit-character/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Character submitted for Decoration');
  return data;
}

export async function fetchSubmissionStatus() {
  const url = `/events/tasks/character/poll/my-oc-submission/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function fetchCommunityFanArt(params = {}) {
  const url = `/fanworks/decorated-fanart/`;
  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}

export async function getActiveEventGroups() {
  const url = `/events/groups/active/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getEventGroup(groupId: string) {
  const url = `/events/groups/${groupId}/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getAllActiveEvents() {
  const url = `/events/groups/active-events/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getCharacterRouletteProgress() {
  const url = `/events/tasks/cr-progress/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getRepeatedEventProgress(key: string) {
  const url = `/events/tasks/progress/?key=${key}`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function fetchHasSubmittedTaskFeedback(taskId: string) {
  const url = `/events/tasks/${taskId}/feedback/`;
  const { data } = await chAxios().get(url);
  return data; // returns a boolean
}

export async function submitTaskFeedback(taskId: string, value: number) {
  const url = `/events/tasks/${taskId}/feedback/`;
  await chAxios().post(url, { value });
  return true;
}
