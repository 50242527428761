<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content">
      <ion-header translucent>
        <ion-toolbar>
          <ion-buttons v-if="image?.user !== user.id" slot="start">
            <ion-button class="" @click="dropDownToggle($event, getImage)">
              <i class="ti-more-alt" />
            </ion-button>
          </ion-buttons>
          <ion-title class="title d-flex justify-content-center text-center align-items-center">{{
            modalTitle
          }}</ion-title>

          <ion-buttons slot="end">
            <ion-button class="close-button" @click="dismissModal()">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <div class="d-flex justify-content-center align-items-center pb-3 px-3 wrapper">
        <div class="h-100 w-100 position-relative">
          <div class="d-flex justify-content-center align-items-center w-100 mb-3 image-wrapper">
            <div
              v-if="allImages && allImages.length"
              class="w-100 h-100 d-flex position-relative justify-content-center align-items-center"
            >
              <Swiper
                :modules="[SwiperAutoplay, SwiperPagination]"
                class="visuals mt-3 h-100 w-100"
                :navigation="{ enabled: true, nextEl: '#nextpg', prevEl: '#prevpg' }"
                :initial-slide="activeIndex"
                @slideChange="changeSwiperIndex($event)"
                @reachEnd="fetchMoreVisuals"
                @swiper="onSwiperInstance"
              >
                <SwiperSlide
                  v-for="(image, index) in allImages"
                  :key="index"
                  class="d-flex align-items-center justify-content-center"
                  :style="{
                    marginLeft: activeIndex === index ? '2px' : null,
                    marginRight: activeIndex === index ? '2px' : null,
                  }"
                >
                  <img
                    @click="pressImage(image)"
                    v-if="usedKey"
                    v-image-resizer
                    v-image
                    :class="{ 'clickable-item': image.is_spoiled }"
                    class="image-contain no-select"
                    :src="
                      resizeUpload(
                        image?.image ||
                          (usedKey === 'outfits'
                            ? '/images/Outfits.png'
                            : usedKey === 'favorites'
                            ? '/images/Favorites.png'
                            : usedKey === 'inventory'
                            ? '/images/Inventory.png'
                            : '/images/LifeStages.png') ||
                          '/empty.png',
                        '1280w'
                      )
                    "
                    :style="image.is_spoiled && !image?.spoiled_images?.user ? 'filter:blur(50px)' : ''"
                  />
                  <img
                    @click="pressImage(image)"
                    :class="{ 'clickable-item': image.is_spoiled }"
                    :style="image.is_spoiled && !image?.spoiled_images?.user ? 'filter:blur(50px)' : ''"
                    v-else
                    v-image-resizer
                    v-image
                    class="image-contain no-select"
                    :src="resizeUpload(isFeatured ? image.visual_url : image.image, '1280w')"
                  />
                  <div
                    @click="pressImage(image)"
                    v-if="image?.is_spoiled && !image?.spoiled_images?.user"
                    class="image-blur"
                    :class="{ 'clickable-item': image.is_spoiled }"
                  >
                    {{ image?.spoiled_text ? image?.spoiled_text : 'Spoiler' }}
                  </div>
                </SwiperSlide>
              </Swiper>
              <div class="navbtns">
                <div
                  id="prevpg"
                  class="d-flex-column clickable-area mx-2"
                  :class="{ disabled: activeIndex === 0 }"
                  @click="prevPage"
                >
                  <i class="ti-angle-left" />
                </div>
                <div
                  id="nextpg"
                  class="d-flex-column clickable-area mx-2"
                  :class="{ disabled: activeIndex === allImages.length - 1 }"
                  @click="nextPage"
                >
                  <i class="ti-angle-right" />
                </div>
              </div>
            </div>
            <img loading="lazy" v-else :src="resizeUpload(imageUrl, '1280w')" class="visual-prev-img mt-3" />
          </div>
          <div v-if="isCreate || editable" class="h-25 w-100 d-flex align-items-center">
            <div class="w-100">
              <div class="label"><b>Caption:</b></div>
              <ion-input
                class="c-input"
                placeholder="Write a caption for this image"
                :value="caption"
                maxlength="150"
                @input="$emit('update:caption', $event.target.value)"
              />
              <div class="label mt-2"><b>Source:</b></div>
              <ion-input
                class="c-input"
                placeholder="Image credits/source"
                :value="source"
                maxlength="150"
                @input="$emit('update:source', $event.target.value)"
              />
            </div>
          </div>
          <div v-else class="text-center w-100 d-flex-column align-items-center justify-content-center">
            <i v-if="caption" class="d-block mb-2">{{ caption }} </i>

            <i v-if="sourceUser && sourceUser.username" class="d-block mb-2"
              >Source: Submitted with ❤️ by
              <strong
                ><router-link
                  :to="{
                    name: 'profile',
                    params: { username: sourceUser.username },
                  }"
                  target="_blank"
                  class="text-black"
                  >@{{ sourceUser.username }}</router-link
                ></strong
              ></i
            >
            <i v-else-if="source" class="d-block mb-2">Source: {{ source }}</i>
            <div class="d-flex justify-content-center">
              <Reaction
                type="visual"
                v-if="
                  ($route.name === 'character-profile' && !isMoodBoard) ||
                  ($route.name === 'character-profile-new' && !isMoodBoard) ||
                  $route.name === 'world-details' ||
                  $route.name === 'social-space-details' ||
                  $route.name === 'reactions' ||
                  $route.name === 'character-profile-new'
                "
                :image="image"
                @onReaction="(reactionResp) => onVisualReaction(reactionResp)"
              />

              <bookmark-picker
                v-if="imageId && isAuthenticated"
                :object-id="imageId"
                content-type="image"
                class="text-center bookmark-btn"
                in-button
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import BookmarkPicker from '@/shared/components/BookmarkPicker.vue';
import ImagePopover from '@/shared/components/storage/popovers/ImagePopover.vue';
import { Image } from '@/shared/types/static-types';
import Reaction from '@/shared/components/Reaction/index.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { popovers } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  isCreate: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  imageUrl: {
    type: String,
    default: '',
  },
  caption: {
    type: String,
    default: '',
  },
  imageId: {
    type: String,
    default: '',
  },
  source: {
    type: String,
    default: '',
  },
  sourceUser: {
    type: Object,
    default: {},
  },
  isFeatured: {
    type: Boolean,
    default: false,
  },
  image: {
    type: Object,
    default: {},
  },
  isMoodBoard: {
    type: Boolean,
    default: false,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  modalTitle: {
    type: String,
    default: '',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  allImages: {
    type: Array,
    default: () => [],
  },
  noActions: {
    type: Boolean,
    default: false,
  },
  usedKey: {
    type: String,
    default: '',
  },
  reportUser: {
    type: Object,
    default: {},
  },
});

const allImages = computed(() => props.allImages as Image[]);
const isOpen = computed(() => props.isOpen as boolean);
const noActions = computed(() => props.noActions as boolean);
const reportUser = computed(() => props.reportUser as any);
const activeIndex = computed(() => props.activeIndex as any);
const imageUrl = computed(() => props.imageUrl as any);

const isAuthenticated = computed(() => {
  const { isAuthenticated } = authStore();
  return isAuthenticated.value;
});

const dropDownToggle = (ev: CustomEvent, imgObj: any) => {
  if (
    (!isEmpty(imageUrl.value) && isEmpty(allImages.value)) ||
    !isEmpty(get(allImages.value[activeIndex.value], 'visual_url'))
  ) {
    popovers.open(ev, ImagePopover, { imageUrl: imgObj, user: reportUser.value });
  } else {
    popovers.open(ev, ImagePopover, { image: imgObj, user: reportUser.value });
  }
};
const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const wrapperHeight = computed(() => {
  return noActions.value ? '100%' : 'calc(100% - 100px)';
});

const swiperRef = ref(null);
const onSwiperInstance = (v: any) => {
  swiperRef.value = v;
};

watch(allImages, () => {
  setTimeout(() => {
    swiperRef.value?.update();
  }, 200);
});
watch(isOpen, () => {
  setTimeout(() => {
    swiperRef.value?.update();
  }, 200);
});
onMounted(() => {
  nextTick(() => {
    swiperRef.value?.update();
  });
});

const currentIndex = ref(0);

const emits = defineEmits([
  'dismissModal',
  'updated',
  'loadmore',
  'onReaction',
  'update:caption',
  'update:source',
  'updatedImage',
]);

const dismissModal = () => {
  emits('dismissModal');
  currentIndex.value = 0;
};

const pressImage = (img: any) => {
  emits('updatedImage', { ...img, is_spoiled: false });
};

const getImage = computed(() => {
  return get(allImages.value[activeIndex.value], 'visual_url') || allImages.value[activeIndex.value] || imageUrl.value;
});
const changeSwiperIndex = ($event: any) => {
  currentIndex.value = $event.activeIndex as number;
  emits('updated', currentIndex.value);
};

const nextPage = () => {
  swiperRef.value?.slideNext();
  emits('updated', currentIndex.value);
};

const prevPage = () => {
  swiperRef.value?.slidePrev();
  emits('updated', currentIndex.value);
};

const fetchMoreVisuals = () => {
  emits('loadmore');
};

const onVisualReaction = (reactionResp: any) => {
  emits('onReaction', reactionResp);
};

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});

const { currentRoute } = useRouter();

onMounted(() => {
  window.addEventListener('popstate', dismissModal);
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', dismissModal);
});

watch(currentRoute, () => {
  dismissModal();
});
</script>

<style lang="sass" scoped>
.image-blur
  font-size: 12px
  top: 50%
  background: rgb(85 84 84)
  color: white
  border-radius: 21px
  padding: 15px
  height: 24px
  display: flex
  align-items: center
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  z-index: 2
  position: absolute
.disabled
  *
    pointer-events: none !important
    color: gray !important
    opacity: 0.2
.bookmark-btn
  ::v-deep
    ion-button
      height: 34px
.navbtns
  display: flex
  position: absolute
  justify-content: space-between
  align-items: center
  height: 100% !important
  width: 100% !important
  top: 0
  bottom: 0
  z-index: 11
  pointer-events: none
  .clickable-area
    // full height of the enclosing div
    height: 2rem
    width: 2rem
    border-radius: 50%
    background-color: rgba(0, 0, 0, 0.75)
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    pointer-events: all
    color: white
    &:hover
      color: var(--ion-color-secondary)
  i
    pointer-events: none
    font-size: 1rem
@media (hover: none)
  .clickable-area
    &:hover
      color: white !important
.controls
  z-index: 11
.image-wrapper
  height: v-bind(wrapperHeight)
.image-contain
  max-height: 100%
  height: auto
  width: auto !important
  max-width: 100% !important
.wrapper
  height: calc(100% - 50px)
.visuals
  position: relative
  height: 350px
@media (max-width: 480px)
  .visuals
    height: 200px

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 98vh
  overflow: auto
  overflow-x: hidden

.reaction-count
  font-size: 18px
  font-weight: bold
.visual-prev-img
  max-width: 100%
  max-height: 400px
.close-bottom
  position: absolute
  bottom: 1.5rem
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  width: 100%
  text-align: center
  div
    display: inline-block
.close-button
  width: 50px
.modal-big
  --width: 90%
  --height: 90%
  ::v-deep
    .modal-inner-content
      height: 100%
  @media(max-width: 576px)
    --width: 100%
</style>
