<template>
  <div class="inline-gallery">
    <div v-if="isEditor">
      <ion-card class="p-3 d-flex flex-column" style="height: 275px; background: #f8f3f3">
        <span class="text-primary">TUTORIAL:</span>
        <div class="text-center">
          {{
            usedKey === 'outfits'
              ? 'What does your Character wear?'
              : usedKey === 'favorites'
              ? 'What’re your Character’s favorite things?'
              : usedKey === 'inventory'
              ? 'What’s in your Character’s bag?'
              : 'How does your Character look throughout their life?'
          }}
        </div>
        <div
          class="mt-2 mx-auto p-2"
          style="border: 3px solid; border-radius: 10px; color: #c6c8d6"
          :style="isMobSmallScreen ? 'width: 100%' : 'width: 60%'"
        >
          <div class="" style="font-size: 18px">
            {{
              usedKey === 'outfits'
                ? 'Outfits'
                : usedKey === 'favorites'
                ? 'Favorite'
                : usedKey === 'inventory'
                ? 'Inventory'
                : 'Life Stage'
            }}
          </div>
          <div class="mt-2 d-flex justify-content-between">
            <div
              v-for="(item, index) in usedKey === 'outfits'
                ? outfits
                : usedKey === 'favorites'
                ? favorites
                : usedKey === 'inventory'
                ? inventory
                : lifeStages"
              :key="index"
              class="p-2"
              style="border: 3px solid; border-radius: 10px; width: 32%; height: 140px"
            >
              <div
                class="text-center d-flex align-items-center justify-content-center"
                style="border: 3px solid; border-radius: 10px; width: 100%; height: 100px"
              >
                <img loading="lazy" :src="item.url" alt="" style="width: 95%; height: 78px" />
              </div>
              <div class="text-center mt-1">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="ml-auto d-flex">
          Add Your Own!
          <div class="mt-1 text-primary" style="rotate: 90deg">
            <i class="ti-back-right mr-2" />
          </div>
        </div>
      </ion-card>
    </div>
    <div class="loading-overlay" :class="{ visible: isUploading }">
      <div class="d-flex inner-overlay">
        <div class="loading-indicator">
          <i class="ti-reload spin" />
        </div>
        <strong>Uploading...</strong>
      </div>
    </div>
    <div
      v-if="
        route.name === 'character-editor' ||
        route.name === 'draft-character-editor' ||
        route.name === 'character-creator' ||
        route.name === 'preset-editor'
      "
      class="d-flex justify-content-between"
    >
      <div
        v-if="
          usedKey === 'outfits'
            ? !!charOutfits.length
            : usedKey === 'favorites'
            ? !!charFav.length
            : usedKey === 'inventory'
            ? !!charInventory.length
            : !!charLifeStages.length
        "
        class="left"
      >
        <ion-button class="watermark-add" @click="addWatermarks">
          <!-- Show icon instead of text on mobile-->
          <inline-svg src="/icons/watermark-on.svg" width="18px" height="22px" />
          <span class="ml-1 mobile-only no-select">All</span>
          <span class="ml-1 desktop-only no-select">Watermark All</span>
        </ion-button>
        <ion-button class="watermark-remove no-select" @click="removeWatermarks">
          <inline-svg src="/icons/watermark-off.svg" width="18px" height="22px" />
          <span class="ml-1 mobile-only no-select">Clear</span>
          <span class="ml-1 desktop-only no-select">Clear Watermarks</span>
        </ion-button>
      </div>
      <div class="right ml-auto">
        <ion-button :disabled="isUploading" :loading="isUploading" class="upload-button" @click.prevent="addItem">
          <i class="ti-plus mr-2" />
          Add
          {{
            usedKey === 'outfits'
              ? 'Outfit'
              : usedKey === 'favorites'
              ? 'Favorite'
              : usedKey === 'inventory'
              ? 'Inventory'
              : 'Life Stage'
          }}
        </ion-button>
      </div>
    </div>
    <div class="images-wrapper">
      <div class="py-1 position-relative spacing">
        <CharacterOutfitsImages
          :is-editor="isEditor"
          :used-key="usedKey"
          :loaders="loaders"
          :is-compliment-bomb="isComplimentBomb"
          :customize="customize"
          :added-images="allImages"
          :watermark-all="watermarkAll"
          :disabled="isUploading"
          @uploadingImage="setUploading"
          @removeLoader="removeLoader"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CharacterOutfitsImages from './CharacterOutfitsImages.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { Image } from '@/shared/types/static-types';
import { getImageObject } from '@/shared/services/upload';


const { addOutfits, charOutfits, charLifeStages, charInventory, charFav } = characterEditorStore();

const route = useRoute();
const isUploading = ref(false);
const allImages: Ref<Image[]> = ref([]);
const watermarkAll = ref(false);
const outfits = ref([
  { url: '/images/autumn.svg', name: 'AUTUMN' },
  { url: '/images/winter.svg', name: 'WINTER' },
  { url: '/images/summer.svg', name: 'SUMMER' },
]);

const favorites = ref([
  { url: '/images/hamburger.svg', name: 'FOOD' },
  { url: '/images/axe.svg', name: 'WEAPON' },
  { url: '/images/toy-duck.svg', name: 'TOY' },
]);

const inventory = ref([
  { url: '/images/glasses.svg', name: 'GLASSES' },
  { url: '/images/lipstick.svg', name: 'LIPSTICK' },
  { url: '/images/wallet.svg', name: 'WALLET' },
]);
const lifeStages = ref([
  { url: '/images/baby.svg', name: 'BABY' },
  { url: '/images/adult.svg', name: 'ADULT' },
  { url: "/images/70's.svg", name: "70'S" },
]);

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  isEditor: {
    type: Boolean,
    default: () => true,
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  isComplimentBomb: { type: Boolean, default: () => true },
});

const usedKey = toRef(props, 'usedKey');

const setUploading = (val: boolean) => {
  isUploading.value = val;
};

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 580;
});

const loaders: any = ref([]);

const addItem = async () => {
  const item = { loader: true };
  loaders.value.push(item);

  const image = await getImageObject();

  if (image) {
    allImages.value = [image];
    addOutfits([image], false, usedKey.value);
  }
};

const removeLoader = () => {
  if (loaders.value.length) {
    loaders.value.pop();
  }
};

const addWatermarks = () => {
  watermarkAll.value = true;
};

const removeWatermarks = () => {
  watermarkAll.value = false;
};
</script>

<style lang="sass" scoped>
.inline-gallery
  position: relative
  min-height: 200px
.watermark-add
  --background: #7050B7 !important
  color: white
  font-size: 12px
.watermark-remove
  --background: #A7AABE !important
  color: #41476C
  font-size: 12px
.name-input
  border-radius: 8px
.msg
  font-size: 14px
  font-weight: 400

.view-btn
  text-transform: unset
  width: 150px
  --border-radius: 10px
.sub-title
  font-size: 20px
  font-weight: bold
.edit-btn
  text-transform: unset
  width: 100px
  width: 150px !important
  height: 30px !important
  ::v-deep
    .c-button
      height: 30px !important
      text-transform: unset
      font-size: 15px !important
.upload-button
  width: 150px
  max-width: 130px
  border-radius: 10px
  height: 40px
  ::v-deep
    .c-button
      font-size: 15px !important
      --border-radius: 10px !important
      height: 40px
      text-transform: unset
.bottom-bar
  font-size: 14px
  font-weight: 400
.no-data
  color: #000
  opacity: 0.6 !important
.images-actions
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 1rem
  margin-bottom: 1rem
  .left
    display: flex
    align-items: center
  .right
    display: flex
    align-items: center
    .upload-button
      margin-right: 1rem
      width: 150px
      max-width: 120px
      border-radius: 10px
      ::v-deep
        .c-button
          font-size: 15px !important
          --border-radius: 10px !important
          height: 40px
          text-transform: unset
.loading-overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4)
  display: flex
  justify-content: center
  align-items: flex-start
  z-index: -1
  cursor: not-allowed
  border-radius: 20px
  opacity: 0
  transition: opacity 0.3s ease-in-out, z-index 0.05s step-end 0.08s
  &.visible
    opacity: 1
    z-index: 10
  .inner-overlay
    background: rgba(255, 255, 255, 1.0)
    padding: 1rem
    border-radius: 0.5rem
    display: flex
    justify-content: top
    align-items: center
    flex-direction: column
    margin-top: 50px
  .loading-indicator
    animation: spin 3s linear infinite
    margin: 0.75rem
    i
      font-size: 2em
      color: #50c8ff !important
@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
.mobile-only
  display: none !important
.desktop-only
  display: block
@media(max-width: 500px)
  .desktop-only
    display: none !important
  .mobile-only
    display: block !important
</style>
