import { default as fandom9oF9EwYCrtMeta } from "/app/pages/fandoms/fandom.vue?macro=true";
import { default as indexQ34tu46kgCMeta } from "/app/pages/fandoms/index.vue?macro=true";
export default [
  {
    name: "fandoms-fandom",
    path: "/fandoms/fandom",
    component: () => import("/app/pages/fandoms/fandom.vue").then(m => m.default || m)
  },
  {
    name: "fandoms",
    path: "/fandoms",
    component: () => import("/app/pages/fandoms/index.vue").then(m => m.default || m)
  }
]