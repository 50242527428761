export const useElementUtils = (selector: string) => {
  const el = document.querySelector(selector);

  const scrollToTop = () => {
    if (!el) return;

    el.scrollBy({ top: -9999999 });
  };

  return { scrollToTop };
};
