<template>
  <client-only>
    <ion-content ref="modal" scroll-y="false">
      <div class="content d-flex flex-column">
        <!-- <ion-header>
          <ion-toolbar>
            <div class="w-100 px-2 text-center title ion-text-center" mode="ios">{{ modalTitle }}</div>
          </ion-toolbar>
        </ion-header> -->
        <div
          v-auto-animate
          class="inner-content p-3 flex-grow-1"
          :style="isStepActive(12) ? 'max-height: calc(100% - 82px)' : ''"
        >
          <!-- STEP 1 -->
          <div v-if="isStepActive(1)">
            <div class="w-100 text-center"><img class="hub-1" src="/onboarding/hub-hi.svg" /></div>
            <div class="msg px-3 py-2 mt-3">“Hi, I'm&nbsp;<span class="color-pink">Hubert</span>!”</div>
          </div>
          <!-- END OF STEP 1 -->

          <!-- STEP 2 -->
          <div v-if="isStepActive(2)">
            <div class="w-100 text-center"><img class="hub-2" src="/onboarding/hub.svg" /></div>
            <div class="msg px-3 py-2 mt-3">
              “I’m here to&nbsp;<span class="color-pink">show you around</span>, you ready?”
            </div>
          </div>
          <!-- END OF STEP 2 -->

          <!-- STEP 3 -->
          <div v-if="isStepActive(3)">
            <div class="w-100 text-center ch-img"><img src="/onboarding/ch2.png" /></div>
            <div class="msg d-flex px-3 py-2 mt-3">
              <div>“Showcase your characters with&nbsp;<span class="color-pink">Customizable Profiles</span>.”</div>
              <img class="ml-2" src="/onboarding/hub-c.svg" />
            </div>
          </div>
          <!-- END OF STEP 3 -->

          <!-- STEP 4 -->
          <div v-if="isStepActive(4)">
            <div class="w-100 text-center ch-edit-img"><img src="/onboarding/ch-edit.png" /></div>
            <div class="msg ch-edit-msg d-flex px-3 py-2">
              <div>
                <div class="main">“Look at all the possibilities!”</div>
                <div class="sub mt-3">Traits, colors, relationships & so many more options!</div>
              </div>
              <img class="ml-2" src="/onboarding/hub-c.svg" />
            </div>
          </div>
          <!-- END OF STEP 4 -->

          <!-- STEP 5 -->
          <div v-if="isStepActive(5)">
            <div class="w-100 text-center"><img src="/onboarding/chs2.png" /></div>
            <div class="msg chs-msg chs-msg d-flex px-3 py-2">
              <div>“Imagine how&nbsp;<span class="color-pink">epic</span> your characters will look!”</div>

              <img class="ml-2" src="/onboarding/hub-c-happy.svg" />
            </div>
          </div>
          <!-- END OF STEP 5 -->

          <!-- <div v-if="isStepActive(6)">
            <div class="msg d-flex px-3 py-2">
              <div>“What else are&nbsp;<span class="color-pink">you looking for</span> in CharacterHub?”</div>

              <img class="ml-2" src="/onboarding/hub-c-kis.svg" />
            </div>
            <div class="options mt-2">
              <ion-button
                v-for="(option, index) of triviaOptions"
                :key="index"
                color="light"
                @click="handleTrivia(option.name)"
                class="mt-2 selection-btn d-flex align-items-center p-0 gotcha0"
              >
                <div class="h-46 d-flex align-items-center justify-content-start w-92">
                  <inline-svg :src="option.icon" class="mr-2 icon" /> {{ option.name }}
                </div>
                <div class="w-8 h-100 popover-icon" @click.stop="showTriviaPopover($event, index + 1)">
                  <i class="ti-info-alt mr-2" @mouseover="showTriviaPopover($event, index + 1)" />
                </div>
              </ion-button>
            </div>
          </div> -->
          <!-- <div v-if="isStepActive(7)">
            <div class="msg d-flex px-3 py-2">
              <div>“Gotcha! We actually have&nbsp;<span class="color-pink">all of them</span>!”</div>

              <img class="ml-2" src="/onboarding/hub-c-col.svg" />
            </div>
            <div class="options mt-2">
              <ion-button
                v-for="(option, index) of triviaOptions"
                :key="index"
                @click="stepForward"
                class="mt-2 selection-btn d-flex align-items-center gotcha animate__animated animate__bounceInLeft"
                disabled
              >
                <div class="h-46 d-flex align-items-center justify-content-start w-92">
                  <inline-svg :src="option.icon" class="mr-2 icon white-svg" />
                  <span class="text-white">{{ option.name }}</span>
                </div>
                <div class="w-8 h-100 mr-n2 ml-3">
                  <inline-svg src="/onboarding/ico-chk.svg" />
                </div>
              </ion-button>
            </div>
          </div> -->

          <!-- STEP 6 -->
          <div class="h-100" v-if="isStepActive(6)">
            <div class="msg d-flex px-3 py-2">
              <div>
                <div>
                  “Serious character lovers get &nbsp;<span class="color-pink">superpowers</span> with<br /><span
                    class="color-pink"
                    >Plus</span
                  >
                  and&nbsp;<span class="color-pink">Plus Ultra</span>!”
                </div>
                <img class="mt-2" src="/onboarding/plus-tags.svg" />
              </div>
              <img class="ml-2" src="/onboarding/hub-c-happy.svg" />
            </div>
            <div class="plus-content ml-2">
              <div class="plus-text mt-3 d-flex align-items-center">
                <img class="mr-2" src="/onboarding/big-str.svg" />
                It’s the red carpet character experience:
              </div>
              <div class="plus-text mt-2 ml-4 d-flex align-items-center">
                <img class="mr-2" src="/onboarding/sml-str.svg" />
                Make your characters shine extra!
              </div>
              <div class="plus-text mt-2 ml-4 d-flex align-items-center">
                <img class="mr-2" src="/onboarding/sml-str.svg" />
                Unlock special features you can't get anywhere else!
              </div>
              <div class="plus-text mt-2 ml-4 d-flex align-items-center">
                <img class="mr-2" src="/onboarding/sml-str.svg" />
                More tools to help you create your life’s work
              </div>
            </div>
          </div>
          <!-- END OF STEP 6 -->

          <!-- STEP 7 -->
          <div v-if="isStepActive(7)">
            <div class="w-100 text-center"><img class="hub-1" src="/onboarding/hub-rlx.svg" /></div>
            <div class="msg px-3 py-2 mt-3">
              <div>“Okay &nbsp;<span class="color-pink">3 more questions</span> and we'll get you set up!”</div>
            </div>
          </div>
          <!-- END OF STEP 7 -->

          <!-- STEP 8 -->
          <div class="h-100" v-if="isStepActive(8)">
            <div class="msg d-flex px-3 py-2">
              <div>“How did you hear about &nbsp;<span class="color-pink">CharacterHub</span>?”</div>

              <img class="ml-2" src="/onboarding/hub-c-kis.svg" />
            </div>
            <div class="mt-2 options-scroll" id="heardAboutOptions">
              <ion-radio-group :value="onboarding?.heard_about" @ionChange="(e) => changeHeardAbout(e.detail.value)">
                <div v-for="(option, index) of heardAboutOptions" :key="index" class="mt-1 option">
                  <div class="d-flex align-items-center">
                    <ion-radio
                      class="w-100 option px-2"
                      mode="md"
                      :value="option"
                      label-placement="end"
                      justify="start"
                    >
                      <div class="ml-2 w-100 no-select">{{ option }}</div>
                    </ion-radio>
                  </div>
                  <textarea
                    id="heardAboutTextarea"
                    v-if="onboarding?.heard_about === 'Other' && option === 'Other'"
                    class="c-textarea mt-2 w-100"
                    v-model="heardAboutOther"
                    rows="3"
                    placeholder="How did you hear about CharacterHub?"
                  />
                </div>
              </ion-radio-group>
            </div>
          </div>
          <!-- END OF STEP 8 -->

          <!-- STEP 9 -->
          <div class="h-100" v-if="isStepActive(9)">
            <div class="msg d-flex px-3 py-2">
              <img class="mr-2 flip" src="/onboarding/hub-c-kis.svg" />

              <div>“What are you looking to&nbsp;<span class="color-pink">accomplish</span> here?”</div>
            </div>
            <div class="mt-2 options-scroll" id="accomplishmentOptions">
              <ion-checkbox
                v-for="(option, index) of lookingForOptions"
                :key="index"
                mode="md"
                :checked="onboarding?.accomplishments.includes(option)"
                @ionChange="(e) => changeAccomplishments(option, e.detail.checked)"
                label-placement="end"
                justify="start"
                class="d-flex align-items-center mt-2 w-100 p-2 option"
              >
                <div class="ml-2 no-overflow no-select">{{ option }}</div>
              </ion-checkbox>

              <textarea
                rows="3"
                id="accomplishmentsTextarea"
                v-if="onboarding?.accomplishments.includes('Other')"
                class="c-textarea mt-2 w-100"
                v-model="lookingForOther"
                placeholder="Tell us more!"
              />
            </div>
          </div>
          <!-- END OF STEP 9 -->

          <!-- STEP 10-->
          <div class="h-100" v-if="isStepActive(10)">
            <div class="msg d-flex px-3 py-2">
              <img class="mr-2 flip" src="/onboarding/hub-c-kis.svg" />

              <div>
                <div>“Tell us what you’re &nbsp;<span class="color-pink">obsessed about</span>!</div>
                <div class="sub mt-3">Art styles, fandoms, species, anything!”</div>
              </div>
            </div>
            <div>
              <div class="title-small mt-3 mb-1">Find your interests:</div>
              <form @submit.prevent>
                <multiselect
                  v-model="selectedTags"
                  placeholder="Look up tags"
                  :show-no-options="false"
                  :options="tags"
                  :custom-label="formatSearchLabel"
                  :multiple="true"
                  :taggable="false"
                  open-direction="bottom"
                  :loading="areTagsLoading"
                  option-height="80"
                  :close-on-select="true"
                  :show-no-results="false"
                  class="tags"
                  @tag="clickTag"
                  @search-change="debouncedLoadTags"
                  ><span slot="noResult">No matching tags found</span></multiselect
                >
              </form>
            </div>
            <div class="mt-2 tags d-flex justify-content-center flex-wrap tags-scroll">
              <div v-for="(tag, index) of tagsToShow" :key="index" class="m-0 tag-container">
                <ion-button class="tag" @click="() => selectTag(tag)" :color="isTagSelected(tag) ? 'primary' : 'light'">
                  {{ truncateEasy(tag.name, 20) }}</ion-button
                >
              </div>
            </div>
          </div>
          <!-- END OF STEP 10 -->

          <!-- STEP 11-->
          <div v-if="isStepActive(11)">
            <div class="msg d-flex px-3 py-2">
              <div>
                “Nice!! Last step!!<br /><br />Want to know what our&nbsp;<span class="color-pink"
                  >Secret Ingredient</span
                >
                is?”
              </div>

              <img class="ml-2" src="/onboarding/hub-c-happy.svg" />
            </div>
            <div class="flex-column d-flex">
              <ion-button @click="stepForward" color="light" class="selection-btn mt-3">Yes!!</ion-button>
              <ion-button @click="stepForward" color="light" class="selection-btn mt-2">Spill the tea!!!</ion-button>
            </div>
          </div>
          <!-- END OF STEP 11 -->

          <!-- STEP 12 -->
          <div class="h-100" v-if="isStepActive(12)">
            <div class="msg d-flex px-3 py-2">
              <div>
                <span class="color-pink">“It's community!</span><br /><br />Like 2 characters and see what happens!”
              </div>

              <img class="ml-2" src="/onboarding/hub-c-happy.svg" />
            </div>
            <div class="mt-2 ch-scroller">
              <Grid :xl="2" :lg="2" :md="2" :sm="2" :scrollSm="false">
                <CharacterCard
                  v-for="(character, index) of characters"
                  :key="index"
                  class="grid-item"
                  :character="character"
                  :openBlank="true"
                  @react="(e) => react(character?.id || '', e)"
                />
              </Grid>
            </div>
          </div>
          <!-- END OF STEP 12 -->

          <!-- STEP 13 -->
          <div v-if="isStepActive(13)">
            <div class="w-100 text-center"><img src="/onboarding/hub-mk.svg" /></div>
            <div class="msg mt-3 px-3 py-2">
              <div>“You just made somebody's day! Wasn't that&nbsp;<span class="color-pink">awesome</span>?”</div>
            </div>
          </div>
          <!-- END OF STEP 13 -->
          <!-- STEP 14 -->
          <div v-if="isStepActive(14)">
            <OnboardingProfilePictureCard @skip="stepForward(1)" />
          </div>
          <!-- END OF STEP  14 -->
          <!-- STEP 15 -->
          <div v-if="isStepActive(15)">
            <OnboardingProfileCoverPicCard @skip="stepForward(1)" />
          </div>
          <!-- END OF STEP  15 -->
          <!-- STEP 16 -->
          <div v-if="isStepActive(16)">
            <OnboardingPrfAge18PlusCard
              @skip="stepForward(2)"
              @forward="stepForward(1)"
            />
          </div>
          <!-- END OF STEP  16 -->
          <!-- STEP 17 -->
          <div v-if="isStepActive(17)">
            <OnboardingPrfAgeRangeCard @forward="stepForward(1)" />
          </div>
          <!-- END OF STEP  17-->
          <!-- STEP 18-->
          <div class="h-100" v-if="isStepActive(19)">
            <div class="msg d-flex px-3 py-2">
              <div>
                <div>“<span class="color-pink">Perfect</span>! Setting up everything for you!!”</div>
              </div>
              <img v-if="visible" class="ml-2" src="/onboarding/hub-c-l-1.svg" />
              <img v-if="!visible" class="ml-2" src="/onboarding/hub-c-l-2.svg" />
            </div>
          </div>
          <!-- END OF STEP 18 -->
        </div>

        <div
          v-if="modalActionName"
          class="footer pr-2 pl-3 d-flex align-items-center justify-content-end"
          :style="isStepActive(12) ? 'height:50px;box-shadow:0px -2px 2px 0px rgba(1, 1, 35, 0.2)' : ''"
        >
          <div v-if="isStepActive(12)" class="no-data justify-content-start no-select">
            Like 2 characters to continue
          </div>
          <div v-if="isStepActive(8)" class="no-data justify-content-start no-select">Select one to continue</div>
          <div v-if="isStepActive(9)" class="no-data justify-content-start no-select">
            Select one or more to continue
          </div>
          <div v-if="isStepActive(10)" class="no-data justify-content-start no-select">
            Pick one or more to continue
          </div>
          <ion-button :disabled="disableAction" color="primary" class="footer-btn" @click="stepForward">
            <span class="no-select">{{ modalActionName }}</span>
          </ion-button>
        </div>
        <div v-if="isStepActive(12)" class="d-flex align-items-center justify-content-center" style="margin-top: -3px">
          <ion-button color="transparent" class="skip-btn" @click="stepForward(2)">Skip</ion-button>
        </div>
      </div>
    </ion-content>
  </client-only>
</template>
<script lang="ts" setup>
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import Multiselect from 'vue-multiselect';
import { popovers } from '@/shared/native';
import OnboardingPopover from './OnboardingPopover.vue';
import { truncateEasy } from '@/shared/utils/string';
import OnboardingProfilePictureCard from './OnboardingProfilePictureCard.vue';
import OnboardingProfileCoverPicCard from './OnboardingProfileCoverPicCard.vue';
import OnboardingPrfAge18PlusCard from './OnboardingPrfAge18PlusCard.vue';
import OnboardingPrfAgeRangeCard from './OnboardingPrfAgeRangeCard.vue';

const visible = ref(false);
const interval = ref<any>(null);

onMounted(() => {
  const { loadWithModal } = onboardingStore();
  loadWithModal();
  interval.value = setInterval(() => {
    visible.value = !visible.value;
  }, 500);
});

onBeforeUnmount(() => {
  clearInterval(interval.value);
});

const {
  isStepActive,
  onboarding,
  debouncedLoadTags,
  selectedTags: selectedTagsGet,
  modalActionName,
  stepForward,
  currentStep,
  disableAction,
  react,
  characters,
  heardAboutOptions,
  heardAboutOptionChanged,
  heardAboutOther,
  lookingForOptions,
  lookingForOptionChanged,
  lookingForOther,
  triviaOptions,
  tags,
  trendingTags,
  areTagsLoading,
  setSelectedTags,
  isTagSelected,
  selectTag,
  handleTrivia,
} = onboardingStore();

const formatSearchLabel = (option: any) => option.name;

const selectedTags = computed({
  get() {
    return selectedTagsGet.value || [];
  },
  set(value) {
    setSelectedTags(value);
  },
});

const tagsToShow = computed(() => {
  const s = selectedTags.value;
  const t = trendingTags.value;
  const combinedTags = s.concat(t).filter((tag, index, self) => self.findIndex((t) => t.name === tag.name) === index);
  return combinedTags;
});

const showTriviaPopover = throttle(
  (ev: any, index: number) => {
    if (!ev || (!index && index !== 0)) return;
    popovers.open(ev, OnboardingPopover, { index }, 'onboarding-popover');
  },
  500,
  { leading: true, trailing: false }
);

const clickTag = (newTag: any) => {
  if (!newTag) return;
  const tags = selectedTags.value || [];
  if (
    find(tags, (t) => {
      return t.name === newTag.name;
    })
  )
    setSelectedTags([...tags, newTag]);
};

const changeAccomplishments = (x: any, y: any) => {
  lookingForOptionChanged(x, y);
  if (x === 'Other') {
    setTimeout(() => {
      try {
        const div1 = document.getElementById('accomplishmentsOptions');
        if (div1) div1.scrollTop = div1.scrollHeight;
        const textarea = document.getElementById('accomplishmentsTextarea');
        if (textarea) textarea.focus();
      } catch (e) {}
    }, 200);
  }
};

const changeHeardAbout = (x: string) => {
  heardAboutOptionChanged(x);
  if (x === 'Other') {
    setTimeout(() => {
      try {
        const div1 = document.getElementById('heardAboutOptions');
        if (div1) div1.scrollTop = div1.scrollHeight;
        const textarea = document.getElementById('heardAboutTextarea');
        if (textarea) textarea.focus();
      } catch (e) {}
    }, 200);
  }
};
</script>

<style lang="sass" scoped>
.skip-btn
  text-decoration: underline
  color: #637a91
  height: 20px
  font-size: 15px
.ch-edit-img
  width: 280px
  img
    margin-bottom: -50px
.no-data
  text-align: start
.plus-text
  background: linear-gradient(111.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 60%, #4FBDE5 85%, #6DFFE5 180%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-clip: text
  font-weight: bold
  font-size: 14px
.tags-scroll
  max-height: calc(100% - 190px)
  overflow-x: hidden
  overflow-y: auto
  margin-right: -1rem
  padding-right: 0.7rem
.tag-container
  max-height: 47px
.tags
  ::v-deep
    .multiselect__tags
      height: 41px
      border-radius: 12px
.tag
  --height: 25px
  --padding-top: 10px
  --padding-bottom: 10px
  --ion-color-light: #FFF
  border: 1px solid rgba(242, 242, 242, 1)
  overflow: hidden
  --ion-color-light-contrast: var(--ch-black-color)
  border-radius: var(--border-radius-sm)
.title-small
  font-weight: bold
  color: var(--ch-black-color)
  font-size: 14px
.h-46
  height: 46px
  white-space: normal
  text-align: left
  color: var(--ch-black-color)

.flip
  transform: scaleX(-1)
.flip-h
  transform: scaleX(-1)
.option
  min-height: 41px
  border-radius: var(--border-radius-sm)
  border: 1px solid rgba(242, 242, 242, 1)
  font-size: 14px
  color: var(--ch-black-color)
  cursor: pointer
.options-scroll
  height: calc(100% - 116px)
  overflow-x: hidden
  overflow-y: auto
  margin-right: -1rem
  padding-right: 0.7rem
.ch-scroller
  height: calc(100% - 116px)
  overflow-x: hidden
  overflow-y: auto
  margin-right: -1rem
  padding-right: 0.7rem
.selection-btn
  font-size: 14px
  --width: 100%
  --height: 46px
  max-height: 46px
  overflow: hidden
  --ion-color-light: #FFF
  color: var(--ch-black-color)
  box-shadow: 0px 2px 2px 0px rgba(33, 65, 99, 0.1)
  border-radius: var(--border-radius-sm)
  border: 1px solid rgba(242, 242, 242, 1)
  .icon
    min-width: 21px

.hub-pro-msg
  margin-top: -160px
.hub-pro
  width: 280px
.chs-msg
  margin-top: -20px
.ch-edit-msg
  margin-top: -195px
.ch-img
  margin-bottom: -130px
.color-pink
  color: var(--ch-primary-color)
.msg
  position: relative
  z-index: 2
  font-size: 14px
  color: var(--ch-black-color)
  font-weight: bold
  text-align: center
  background: rgba(251, 242, 201, 1)
  border-radius: var(--border-radius-sm)
  min-height: 40px
  align-items: center
  justify-content: center
  display: flex
  .sub
    font-size: 12px
    font-style: italic
.title
  font-size: 18px
  display: flex
  align-items: center
  justify-content: center
  color: var(--ch-black-color)
.content
  width: 100%
  height: 100%
  .inner-content
    max-height: calc(100% - 50px)
  ::v-deep
    .text-center
      justify-content: center
  .footer
    z-index: 11
    position: relative
    background: #FFF
    height: 56px
    box-shadow: 0px -2px 5px 0px rgba(1, 1, 35, 0.2)
    .footer-btn
      font-weight: bold
      --padding-top: 8px
      --padding-bottom: 8px
      --height: 32px
      --border-radius: var(--border-radius-sm)
.no-overflow
  overflow: hidden
  white-space: pre-wrap
textarea
  border-radius: 8px
  padding: 0.5rem
  border: none
  background-color: rgba(242, 242, 242, 1)
  resize: none
::v-deep
  .multiselect__tag
    display: none
.gotcha0
  --padding-end: 0 !important
.gotcha
  --background-color: linear-gradient(to right, #D0DE2F, #17A561) !important
  --background: linear-gradient(to right, #D0DE2F, #17A561) !important
  --color: linear-gradient(to right, #D0DE2F, #17A561) !important
  background-size: 200% 100%
  // animation: gradient 2s linear infinite
  opacity: 1 !important

@keyframes gradient
  0%
    background-position: 100% 0
  100%
    background-position: 0 0
.white-svg
  filter: brightness(0) saturate(100%) invert(100%) sepia(3%) hue-rotate(207deg) brightness(119%) contrast(100%)
.w-92
  width: 85% !important
.w-8
  width: 15% !important
.popover-icon
  position: relative
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  padding-top: 20px
  padding-bottom: 20px
</style>
