<template>
  <div class="wrapper d-flex align-items-center justify-content-center">
    <div class="image-box position-absolute">
      <img
        loading="lazy"
        v-image
        class="position-absolute"
        :src="userImage"
        :style="currentStep === 15 ? 'left:0px' : ''"
      />
    </div>

    <div class="box"></div>
  </div>
</template>

<script lang="ts" setup>
import { onboardingStore } from '@/shared/pinia-store/onboarding';
const { currentStep } = onboardingStore();
defineProps({
  userImage: {
    type: String,
    default: '',
  },
});
</script>

<style scoped lang="sass">
.wrapper
  width: 100px
  height: 100px
  &.md
    width: 100px
    height: 100px
    .box
      width: 100px
      height: 100px
    .image-box
      width: 96px
      height: 96px
      min-width: 96px
      min-height: 96px
      max-height: 96px
      max-width: 96px
  &.lg
    width: 110px
    height: 110px
    .box
      width: 110px
      height: 110px
    .image-box
      width: 106px
      height: 106px
      min-width: 106px
      min-height: 106px
      max-width: 106px
      max-height: 106px
  img
    object-fit: cover
    border-radius: 50%
    width: 100%
    height: 100%
    filter: brightness(50%)
.box
  --border-size: 20px
  --border-angle: 0turn
  border-radius: 50%
  background-image: conic-gradient(from var(--border-angle), #213, #112 50%, #213),conic-gradient(from var(--border-angle), transparent 20%, var(--ion-color-primary), var(--ion-color-primary))
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover
  background-position: center center
  background-repeat: no-repeat

  animation: bg-spin 3s linear infinite
  @keyframes bg-spin
    to
      --border-angle: 1turn
  &:hover
    animation-play-state: paused
@property --border-angle
  syntax: '<angle>'
  inherits: true
  initial-value: 0turn
</style>
