<template>
  <div class="p-3">
    <div class="title d-flex justify-content-center align-items-center text-center mx-auto">Are you over 18?</div>
    <div class="d-flex mt-2">
      <ion-button class="w-100 action-btn" :class="{ selected: isOver18 === true }" @click="handleYes">Yes</ion-button>
      <ion-button class="w-100 action-btn" :class="{ selected: isOver18 === false }" @click="handleNo">No</ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserProfile } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';

const { updateUserProfile } = profileStore();
const { user } = authStore();
const isOver18: any = ref(null);

const emits = defineEmits(['skip', 'forward']);

watch(
  user,
  () => {
    if (user.value) {
      isOver18.value = get(user.value, 'over_18');
    }
  },
  { immediate: true }
);
const handleYes = async () => {
  isOver18.value = true;
  await save();
  emits('forward');
};

const handleNo = async () => {
  isOver18.value = false;
  await save();
  emits('skip');
};

const save = () => {
  const data: Partial<UserProfile> = {
    over_18: !!isOver18.value,
  };
  setTimeout(() => {
    updateUserProfile(data);
  }, 1000);
};
</script>

<style lang="sass" scoped>
.action-btn
  border-radius: 12px
  --background: transparent
  color: #000000
  border: 2px solid #b642dd
.dark .action-btn
  color: #fff
.selected
  --background: #ae38e5 !important
  color: #ffffff

.title
  font-size: 22px
  font-weight: bold

.max-w-s
  max-width: 150px
</style>
