<template>
  <ion-badge
    class="sub-plan-badge"
    :class="isPlusPlan ? 'plus-plan-bg' : 'ultra-plan-bg'"
    :style="withBorder ? 'border: 1px solid white' : ''"
    >{{ isPlusPlan ? '+plus' : 'ULTRA' }}</ion-badge
  >
</template>

<script lang="ts" setup>
const props = defineProps({
  plan: { type: String, default: 'plus' },
  withBorder: { type: Boolean, default: false },
});

const isPlusPlan = computed(() => {
  return props.plan === 'plus';
});
</script>

<style scoped lang="sass">
.sub-plan-badge
  border-radius: 20px
  height: 14px
  display: flex
  align-items: center
  padding: 7px
  font-size: 10px
  justify-content: center
  text-align: center

.plus-plan-bg
  background: #00b4c5
.ultra-plan-bg
  background: linear-gradient(111.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 100%)
</style>
