<template>
<client-only>
  <ion-content ref="modal" @willDismiss="dismissModal" scroll-y="false">
    <ion-header>
      <ion-toolbar>
        <ion-title slot="start" class="text-white"><span class="title">All Set!</span></ion-title>
        <ion-buttons slot="end">
          <ion-button size="large" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="inner-content">
      <section class="info w-100">
        <div class="success d-flex justify-content-center align-items-center">
          <img src="/icons/check-contained.svg"><span class="ml-3">Order Complete</span>
        </div>
        <div class="d-flex justify-content-center align-items-center position-relative">
          <ion-button
            v-if="postAction === COMPLIMENT_BOMB"
            class="mt-2 w-auto"
          >
            <a class="white" @click.prevent="doAction">{{ helperText }}</a>
          </ion-button>
          <div v-else-if="postAction === 'email'"><p>Check your email for further instructions!</p></div>
          <div v-else-if="receivedItems?.length" class="received-items align-items-center">
            <h4>You Received:</h4>
            <div v-for="(item, index) in receivedItems" :key="index" class="d-flex justify-content-start align-items-left">
              <img v-if="item[0] === 'gem'" src="/gem.svg" class="icon">
              <img v-if="item[0] === 'hubux'" src="/hubuck.svg" class="icon">
              <img v-if="item[0] === 'cbomb'" src="/store/cbomb.svg" class="icon">
              <span v-if="item[0] === 'gem'">&nbsp;Gems</span>
              <span v-if="item[0] === 'hubux'">&nbsp;Hubux</span>
              <span v-if="item[0] === 'cbomb'">&nbsp;Compliment Bombs</span>
              <span class="ml-1">x{{ +item[1] * firstPurchaseOfferMultiplier}}</span>
            </div>
            <h5 v-if="firstPurchaseOffer && firstPurchaseOfferMultiplier === 1">With a first-purchase bonus of:</h5>
            <div v-if="firstPurchaseOffer && firstPurchaseOfferMultiplier === 1"><span>{{firstPurchaseOffer}}</span></div>
          </div>
        </div>
      </section>
    </div>
    <div class="bottom-area">
      <ion-button size="large" class="done" @click="dismissModal">Close</ion-button>
    </div>
  </ion-content>
</client-only>
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import { COMPLIMENT_BOMB } from '@/shared/statics/constants';

const HELPER_TEXT: Record<string, string> = {
  [COMPLIMENT_BOMB]: 'View your Compliment Bomb inventory'
};
const props = defineProps({
  postAction: { type: String, default: ''},
  productCode: { type: String, default: ''},
  firstPurchaseOffer: { type: String, default: '' },
});
const postAction = toRef(props, 'postAction');
const productCode = toRef(props, 'productCode');
const firstPurchaseOffer = toRef(props, 'firstPurchaseOffer');

const firstPurchaseOfferMultiplier = computed(() => {
  try {
    if (!firstPurchaseOffer.value?.includes('x')) return 1;
    return parseInt(firstPurchaseOffer.value.split('x')[0]);
  } catch (e) {
    return 1;
  }
});

const helperText = computed(() => {
  if (!productCode.value) return '';
  return HELPER_TEXT[productCode.value] || '';
});

const receivedItems = computed(() => {
  const items = [] as any[];
  if (!postAction.value) return;
  postAction.value.split(',').forEach((item) => {
    if (item?.startsWith('amethyst')) {
      let amt = item.split('amethyst')[1];
      items.push(['gem', amt]);
    }
    if (item?.startsWith('cbomb')) {
      let amt = item.split('cbomb')[1];
      items.push(['cbomb', amt]);
    }
    if (item?.startsWith('hubux')) {
      let amt = item.split('hubux')[1];
      items.push(['hubux', amt]);
    }
  });
  return items;
});

const doAction = () => {
  const router = useRouter();
  if (postAction.value === COMPLIMENT_BOMB) {
    router.push({path: 'home', query: { modal: 'compliment-bomb-hub' }});
    dismissModal();
    return;
  }
};

const dismissModal = (code?: string) => {
  modalController.dismiss(code, 'cancel');
};
</script>

<style lang="sass" scoped>
.dark 
  ion-toolbar
    --background: rgba(23, 7, 76, 1)
    color: white
  ion-title
    --background: rgba(23, 7, 76, 1)
    color: white
ion-skeleton-text
  --background: rgba(255, 255, 255, 0.065)
ion-title
  --background: rgba(23, 7, 76, 1)
  color: white
.title
  font-family: Roboto Slab, sans-serif
  color: #214163
.inner-content
  position: relative
  overflow: hidden
  height: 100%
  padding: 1rem
.icon
  width: 20px
  height: 20px
  margin-bottom: -4px
.success
  background-color: #43B701
  border-radius: 16px
  height: 100px
  color: white
  font-weight: bold
  font-family: Roboto, sans-serif
  span
    font-size: 2rem
.bottom-area
  position: absolute
  bottom: 0
  width: 100%
  height: 70px !important
  box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.1)
.done
  position: absolute
  top: 11px
  left: 0
  right: 0
  margin: auto
  height: 3rem
  width: 8rem
  --border-radius: 12px
.white
  color: white !important
h4
  font-size: 1.25rem
  font-weight: bold
  margin-top: 0.5rem
  margin-bottom: 0.25rem
h5
  font-size: 1.1rem
  font-weight: bold
  margin-top: 0.5rem
  margin-bottom: 0.25rem
.received-items
  line-height: 2.5rem
  align-text: left
  justify-content: left
  .icon
    width: 2rem
    height: 2rem
    margin-bottom: -4px
</style>
