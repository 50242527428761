<template>
  <div class="p-3">
    <div class="title d-flex justify-content-center align-items-center text-center mx-auto">
      Which age group are you in?
    </div>
    <ion-list class="bg-transparent">
      <ion-radio-group v-model="ageRange">
        <div class="d-flex flex-column">
          <ion-item
            :class="{ selected: ageRange === '18 - 25' }"
            class="bg-transparent d-flex action-btn"
            lines="none"
            @click="save('18 - 25')"
          >
            <ion-radio slot="start" value="18 - 25" style="margin-inline-end: 15px" class="radio-btn" />
            <div
              class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
              :class="{ 'selected-text': ageRange === '18 - 25' }"
            >
              18 - 25 Yrs
            </div>
          </ion-item>

          <ion-item
            class="bg-transparent d-flex action-btn"
            lines="none"
            :class="{ selected: ageRange === '26 - 30' }"
            @click="save('26 - 30')"
          >
            <ion-radio slot="start" value="26 - 30" style="margin-inline-end: 15px" class="radio-btn" />
            <div
              class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
              :class="{ 'selected-text': ageRange === '26 - 30' }"
            >
              26 - 30 Yrs
            </div>
          </ion-item>
          <ion-item
            class="bg-transparent d-flex action-btn"
            :class="{ selected: ageRange === '31 - 35' }"
            lines="none"
            @click="save('31 - 35')"
          >
            <ion-radio slot="start" value="31 - 35" style="margin-inline-end: 15px" class="radio-btn" />
            <div
              :class="{ 'selected-text': ageRange === '31 - 35' }"
              class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
            >
              31 - 35Yrs
            </div>
          </ion-item>
          <ion-item
            class="bg-transparent d-flex action-btn"
            :class="{ selected: ageRange === '36 - 40' }"
            lines="none"
            @click="save('36 - 40')"
          >
            <ion-radio slot="start" value="36 - 40" style="margin-inline-end: 15px" class="radio-btn" />
            <div
              :class="{ 'selected-text': ageRange === '36 - 40' }"
              class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
            >
              36 - 40 Yrs
            </div>
          </ion-item>
          <ion-item
            class="bg-transparent d-flex action-btn"
            :class="{ selected: ageRange === '41+' }"
            lines="none"
            @click="save('41+')"
          >
            <ion-radio slot="start" value="41+" style="margin-inline-end: 15px" class="radio-btn" />
            <div
              :class="{ 'selected-text': ageRange === '41+' }"
              class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
            >
              41+ Yrs
            </div>
          </ion-item>
          <ion-item
            class="bg-transparent d-flex action-btn clickable-item-hov"
            :class="{ selected: ageRange === '' }"
            lines="none"
            @click="save('')"
          >
            <ion-radio slot="start" value="" style="margin-inline-end: 15px" class="radio-btn clickable-item-hov" />
            <div
              :class="{ 'selected-text': ageRange === '' }"
              class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
            >
              Prefer not to say
            </div>
          </ion-item>
        </div>
      </ion-radio-group>
    </ion-list>
  </div>
</template>

<script lang="ts" setup>
import { UserProfile } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';

const { updateUserProfile } = profileStore();
const { user } = authStore();
const emits = defineEmits(['forward']);
const ageRange = ref<any>(null);
watch(
  user,
  () => {
    if (user.value) {
      ageRange.value = get(user.value, 'age_range');
    }
  },
  { immediate: true }
);
const save = (age: any) => {
  ageRange.value = age;
  const data: Partial<UserProfile> = {
    age_range: ageRange.value || '',
  };
  setTimeout(() => {
    updateUserProfile(data);
    emits('forward');
  }, 1000);
};
</script>

<style lang="sass" scoped>
.selected-text
  color: #fff !important
.privacy-text
  font-size: 16px
  font-weight: bold
  color: #333333
.action-btn
  border-radius: 30px
  --background: transparent
  color: #000000
  margin:5px
  border: 2px solid #ae38e5
  display: flex
  justify-content: center
  align-items: center


.radio-btn
  display: none

.selected
  --background: #ae38e5 !important
  background: #ae38e5 !important
  color: #ffffff

.title
  font-size: 22px
  font-weight: bold
  margin-bottom: 20px

.d-flex
  display: flex
  justify-content: space-around

.flex-column
  flex-direction: column
</style>
