<template>
  <client-only>
    <ion-content ref="modal" @willDismiss="dismissModal" scroll-y="false">
      <div class="loading-overlay" :class="{ visible: loadingProfilePicture }">
        <div class="d-flex inner-overlay">
          <div class="loading-indicator">
            <i class="ti-reload spin" />
          </div>
          <strong>Uploading...</strong>
        </div>
      </div>
      <UploadForm
        id="modaluploader"
        ref="uploader"
        class="d-none"
        :auto="!isUserProfile && !isUserEditProfile"
        only-blob-url
        :restrictGif="restrictGif"
        @loading="loadingProfilePictureChanged"
        @uploaded="handleChangeProfilePicture"
        @blob="cropProfilePicture"
        @dismiss="dismissModal"
      />
      <ion-header>
        <ion-toolbar>
          <ion-title mode="md" slot="start">Select Image</ion-title>
          <ion-buttons slot="end">
            <ion-button size="large" @click="dismissModal">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <section class="content">
        <div class="rounded-squares-container mt-3">
          <div class="rounded-square shaded d-flex align-items-center justify-content-center" @click="pressUpload">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <inline-svg src="/icons/upload-image.svg" class="mb-2 s" />
              <strong class="text-black" :class="[!!subscriptionLevel ? 'mb-2' : 'mb-4']">Upload Image</strong>
            </div>
          </div>
          <div class="rounded-square shaded d-flex align-items-center justify-content-center" @click="pressUploadGif">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <inline-svg src="/icons/gif.svg" class="mb-2 s" />
              <strong class="text-black class center-text" :class="[!!subscriptionLevel && 'mb-2']">Upload GIF</strong>
              <div class="d-flex justify-content-center mt-1" v-if="!subscriptionLevel">
                <PlusTag size="md" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <crop-image-modal
        :image-url="cropImage"
        :is-open="cropModalIsOpen"
        outline-style="round"
        @dismiss-modal="closeCropPreview"
        @crop-done="cropProfilePicture"
      />
    </ion-content>
  </client-only>
</template>

<script lang="ts" setup>
import UploadForm from '@/shared/components/upload-form.vue';
import { modalController } from '@ionic/vue';
import { openSubscribeModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';
import PlusTag from '@/shared/components/PlusTag.vue';
import CropImageModal from '@/shared/modals/CropImageModal.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { upload } from '@/shared/services/upload';

const { trackEvent } = useAnalytics();
const { subscriptionLevel, changeProfilePictureWithNoAdditionalSteps, userCurrentTempProfilePic } = authStore();
const cropModalIsOpen = ref(false);
const cropImage = ref('');
const { character, changeProfilePicture } = characterEditorStore();
const { currentProfile } = profileStore();

const props = defineProps({
  isUserProfile: {
    type: Boolean,
    default: false,
  },
  isUserEditProfile: {
    type: Boolean,
    default: false,
  },
  restrictGif: {
    type: Boolean,
    default: false,
  },
});
const uploader = ref<UploadForm | null>(null);
const loadingProfilePicture = ref(false);
const isUserProfile = toRef(props, 'isUserProfile');
const isUserEditProfile = toRef(props, 'isUserEditProfile');
const restrictGif = toRef(props, 'restrictGif');

const dismissModal = (res?: any) => {
  try {
    if (!res) return modalController.dismiss(null, 'cancel');
    modalController.dismiss(res, 'done');
  } catch (e) {}
};

const pressUpload = () => {
  uploader.value?.getPhoto();
};

const pressUploadGif = () => {
  if (subscriptionLevel.value) {
    trackEvent('Premium feature used: Gif profile picture', null, true);
    uploader.value?.getPhoto();
  } else {
    openSubscribeModal(null, 'imageUploadPlusModal');
  }
};

const handleChangeProfilePicture = async (dataIn: any) => {
  if (dataIn.target) return;
  // return URL.createObjectURL(file);
  // accepted arg list is either [url, event], [url, 'gif', event]
  // or [event] if file upload picker response is rejected
  if (!dataIn) return;
  if (typeof dataIn === 'string') {
    if (dataIn.includes('blob')) {
      openCropPreview(dataIn);
    } else {
      changeProfilePicture(dataIn);
      dismissModal();
    }
    return;
  }
  const [noContinue, url, imageType] = dataIn;

  if (imageType === 'gif') {
    if (url) {
      if (!isUserProfile.value && !isUserEditProfile.value) {
        changeProfilePicture(url);
        return dismissModal();
      }
    }
    return dismissModal();
  }
  if (url && typeof url === 'string') openCropPreview(url);
};

const openCropPreview = (imgUrl?: string) => {
  if (!imgUrl) return;
  cropImage.value = imgUrl;
  cropModalIsOpen.value = true;
};

const closeCropPreview = async () => {
  if (
    profilePicture.value.includes('blob') ||
    userImage.value.includes('blob') ||
    userTempImage.value.includes('blob')
  ) {
    const blob = await fetch(
      isUserProfile.value ? userImage.value : isUserEditProfile.value ? userTempImage.value : profilePicture.value
    ).then((r) => r.blob());

    if (!isUserProfile.value && !isUserEditProfile.value) {
      const url = await upload(blob);
      changeProfilePicture(url);

      return;
    }

    changeProfilePictureWithNoAdditionalSteps(blob, isUserProfile.value, isUserEditProfile.value);
  }

  cropModalIsOpen.value = false;
  cropImage.value = '';
  nextTick(() => {
    dismissModal();
  });
};

const cropProfilePicture = async (image: Blob) => {
  cropModalIsOpen.value = false;

  if (!isUserProfile.value && !isUserEditProfile.value) {
    const url = await upload(image);
    changeProfilePicture(url);

    return;
  }

  changeProfilePictureWithNoAdditionalSteps(image, isUserProfile.value, isUserEditProfile.value);

  cropImage.value = '';
};

const profilePicture = computed(() => {
  return character?.value?.info?.profilePicture || '';
});

const userImage = computed(() => {
  return currentProfile.value?.profile_picture_url || '';
});

const userTempImage = computed(() => {
  return userCurrentTempProfilePic.value || '';
});

const loadingProfilePictureChanged = (loading: boolean) => {
  loadingProfilePicture.value = loading;
};
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: row
  justify-content: space-between
  padding: 0 20px

.rounded-squares-container
  width: 100%
  margin-left: auto
  margin-right: auto
  display: flex
  justify-content: space-between
  align-items: center
  gap: 0.5rem
  .rounded-square
    position: relative
    height: 130px !important
    width: 150px !important
    color: #214163
    border-radius: 15px
    padding: 15px, 0px, 15px, 0px
    div span
      user-select: none !important
    &:hover
      cursor: pointer !important
      opacity: 0.8
.shaded
  background: #EAECF6
.s
  width: 40px
  height: 40px
  background: white
  padding: 0.2rem
  border-radius: 8px
.dark
  .s
    background: #444
.loading-overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.4)
  display: flex
  justify-content: center
  align-items: flex-start
  z-index: -1
  cursor: not-allowed
  border-radius: 20px
  opacity: 0
  transition: opacity 0.3s ease-in-out, z-index 0.05s step-end 0.08s
  &.visible
    opacity: 1
    z-index: 10
  .inner-overlay
    background: rgba(255, 255, 255, 1.0)
    padding: 1rem
    border-radius: 0.5rem
    display: flex
    justify-content: top
    align-items: center
    flex-direction: column
    margin-top: 250px
  .loading-indicator
    animation: spin 3s linear infinite
    margin: 0.75rem
    i
      font-size: 2em
      color: #50c8ff !important
@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
