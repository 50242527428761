import type { RouterConfig } from '@nuxt/schema';

const LoginPage = () => import('@/shared/pages/login/index.vue');
const CustomizCharacterProfilePage = () =>
  import('@/shared/pages/character-profile-new/character-customize-profile.vue');
const SignupPage = () => import('@/shared/pages/signup/signup-invite.vue');
const ForgetPasswordPage = () => import('@/shared/pages/forget-password/index.vue');
const ResetPasswordPage = () => import('@/shared/pages/reset-password/index.vue');
const AuthenticatedHomePreview = () => import('@/shared/pages/home/components/AuthenticatedHomePreview.vue');
const FeedContentPreview = () => import('@/shared/pages/home/components/FeedContentPreview.vue');
const EmailVerification = () => import('@/shared/pages/email-verification.vue');
const PublicHomePage = () => import('@/shared/pages/home/PublicHome.vue');
const ProfilePage = () => import('@/shared/pages/profile/index.vue');
const NotificationsPage = () => import('@/shared/pages/notifications/index.vue');
const CharacterProfileNewPage = () => import('@/shared/pages/character-profile-new/index.vue');
const AccountPage = () => import('@/shared/pages/account/index.vue');
const TermsPage = () => import('@/shared/pages/terms.vue');
const UpgradePage = () => import('@/shared/pages/upgrade-inter.vue');
const PurchaseCheckoutPageStripe = () => import('@/shared/pages/checkout-inter.vue');
const PrivacyPage = () => import('@/shared/pages/privacy.vue');
const CookiesPage = () => import('@/shared/pages/cookies.vue');
const EmailSettingsPage = () => import('@/shared/pages/account/email-settings.vue');
const AccountSettingsPage = () => import('@/shared/pages/account/account-settings.vue');
const BlockedUsersPage = () => import('@/shared/pages/account/blocked-users.vue');
const LogoutPage = () => import('@/shared/pages/logout.vue');
const DmcaPage = () => import('@/shared/pages/dmca.vue');
const CharactersPage = () => import('@/shared/pages/characters/index.vue');
const PresetsPage = () => import('@/shared/pages/presets/index.vue');
const RolechatsPage = () => import('@/shared/pages/rolechat/index.vue');
const RolechatRoomPage = () => import('@/shared/pages/rolechat/components/RolechatRoom.vue');
const RolechatRequestDetailsPage = () => import('@/shared/pages/rolechat/components/RolechatRequestDetails.vue');
const FeaturedCharactersPage = () => import('@/shared/pages/featured-characters/index.vue');
const FeaturedVisualsPage = () => import('@/shared/pages/featured-visuals/index.vue');
const InventoryPage = () => import('@/shared/pages/inventory/index.vue');
const UserPage = () => import('@/shared/pages/account/user-settings.vue');
const MarketplacePage = () => import('@/shared/pages/marketplace-listings/index.vue');
const ManageListingsPage = () => import('@/shared/pages/marketplace-listings/ManageListings.vue');
const PostListingPage = () => import('@/shared/pages/marketplace-listings/PostListing.vue');
const ListingDetailsPage = () => import('@/shared/pages/marketplace-listings/ListingDetailsPage.vue');
const WorldDetails = () => import('@/shared/pages/story-worlds/index.vue');
const ManageWorldsPage = () => import('@/shared/pages/worlds/components/ManageWorldMembers.vue');
const WorldsPage = () => import('@/shared/pages/story-worlds-home/index.vue');
const WorldChatsPage = () => import('@/shared/pages/worlds/components/WorldChatRoom.vue');
const UserListingsPage = () => import('@/shared/pages/marketplace-listings/UserListings.vue');
const CreateCollectionPage = () => import('@/shared/pages/folder/components/CreateCollectionPage.vue');
const CollectionPage = () => import('@/shared/pages/folder/index.vue');
const CreateBookmarkCollectionPage = () =>
  import('@/shared/pages/bookmark-collections/components/CreateBookmarkCollectionPage.vue');
const BookmarkCollectionPage = () => import('@/shared/pages/bookmark-collections/index.vue');
const SectionDetailsPage = () => import('@/shared/pages/home-section/index.vue');
const MyCreationsPage = () => import('@/shared/pages/my-creations/index.vue');
const DraftPage = () => import('@/shared/pages/draft/index.vue');
const ReactionPage = () => import('@/shared/pages/reactions/index.vue');
// const CreatePostPage = () => import('@/shared/pages/blabs/components/CreateBlab.vue');  // old
const CreatePostPage = () => import('@/shared/pages/posts/create.vue'); // new
const BlabsTabsPage = () => import('@/shared/pages/blabs-page/index.vue');
const UserBlabs = () => import('@/shared/pages/user-blabs/Userindex.vue');
const BlabDetailsPage = () => import('@/shared/pages/blabs/BlabDetails.vue');
const CharacterBlabs = () => import('@/shared/pages/character-blabs/index.vue');
const SearchPage = () => import('@/shared/pages/search/index.vue');
// const EventsPage = () => import('@/shared/pages/events/index.vue');
const EventsPagePreview = () => import('@/shared/pages/events/indexPreview.vue');
const EventGroupPage = () => import('@/shared/pages/events/event-group.vue');
const EventDetailPagePreview = () => import('@/shared/pages/events/event-detail-preview.vue');
const EventTaskPagePreview = () => import('@/shared/pages/events/event-task-preview.vue');
const EventTaskPollPage = () => import('@/shared/pages/events/event-poll.vue');
const EventRewardsPage = () => import('@/shared/pages/events/event-rewards.vue');
const OriginalCharacterPage = () => import('@/shared/pages/original-character/index.vue');
const OriginalCharacterCategoryPage = () => import('@/shared/pages/original-character/category.vue');
const OriginalCharacterArtPage = () => import('@/shared/pages/original-character-art/index.vue');
const CharacterEditorPage = () => import('@/shared/pages/character-editor/index.vue');
const FanWorkPage = () => import('@/shared/pages/fan-work/index.vue');
const FandomIndexPage = () => import('@/shared/pages/fandoms/index.vue');
const FandomPage = () => import('@/shared/pages/fandoms/fandom.vue');
const EnterCodePage = () => import('@/shared/pages/code.vue');
const SocialSpacesPage = () => import('@/shared/pages/social-spaces/index.vue');
const UserSpacesPage = () => import('@/shared/pages/social-spaces/components/UserSocialSpaces.vue');
const CreateStoryWorld = () => import('@/shared/pages/create-story-world/index.vue');
const CreateSocialSpacePage = () => import('@/shared/pages/social-space-creation/index.vue');
const SocialSpaceChatroomPage = () => import('@/shared/pages/social-spaces-chatrooms/index.vue');
const ChatRoomDetailsPage = () => import('@/shared/pages/social-spaces-chatrooms/components/chatroomDetails.vue');
const SocialSpaceDetails = () => import('@/shared/pages/social-space-profile/index.vue');
const SocialSpaces = () => import('@/shared/pages/social-spaces/components/VerticalSocialSpaces.vue');
const SocialSpacesChatRooms = () => import('@/shared/pages/social-spaces/components/VerticalSocialSpacesChatrooms.vue');
const StoryWorldTabs = () => import('@/shared/pages/worlds-mainpage/index.vue');
const ManageSocialSpacePage = () =>
  import('@/shared/pages/social-space-profile/components/ManageSocialSpaceMembers.vue');
const GlobalErrorPage = () => import('@/shared/pages/global-error/index.vue');
const RafflesPage = () => import('@/shared/pages/raffles/index.vue');
const SubmitRaffle = () => import('@/shared/pages/raffles/components/SubmitRaffle.vue');
const ManageRaffles = () => import('@/shared/pages/raffles/components/ManageRaffles.vue');
const RaffleDetailsPage = () => import('@/shared/pages/raffles/components/RaffleDetailsPage.vue');
const QuizzesPage = () => import('@/shared/pages/quizzes/index.vue');
const UsersQuizzesPage = () => import('@/shared/pages/quizzes/users-quizzes.vue');
const TheWallPage = () => import('@/shared/pages/wall/index.vue');
const ModerationPanelIndex = () => import('@/shared/pages/moderation/index.vue');
const TestOnboardingPage = () => import('@/shared/pages/onboarding/index.vue');
const TestAdsPage = () => import('@/shared/pages/ads/index.vue');
const OnboardingPage = () => import('@/shared/pages/onboarding/index.vue');
const StorePage = () => import('@/shared/pages/store.vue');

const otherRoutes: any[] = [
  {
    path: '/email-verification',
    name: 'email-verification',
    component: EmailVerification,
    meta: {
      layout: 'no-auth',
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
];

const mainPages = [
  {
    path: '/shop',
    name: 'shop',
    component: StorePage,
    meta: {
      layout: 'default',
      requireAuth: true,
      isPublic: false,
      back: true,
    },
  },
  {
    path: '/',
    name: 'public-home',
    component: PublicHomePage,
    meta: {
      layout: 'no-auth',
      requireAuth: false,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/test-onboarding-sa332ds',
    name: 'test-onboarding',
    component: TestOnboardingPage,
    meta: {
      layout: 'default',
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/test-ads-sa332ds',
    name: 'test-ads',
    component: TestAdsPage,
    meta: {
      layout: 'default',
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingPage,
    meta: {
      layout: 'default',
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/home',
    name: 'home',
    component: AuthenticatedHomePreview,
    meta: {
      layout: 'default',
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/home-tst',
    name: 'home-tst',
    component: FeedContentPreview,
    meta: {
      layout: 'default',
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/hubert-museum',
    name: 'hubert-museum',
    component: TheWallPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/oops',
    name: 'oops',
    component: GlobalErrorPage,
    meta: {
      layout: 'empty',
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/profile/:username/posts',
    name: 'user-posts',
    component: UserBlabs,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/profile',
    name: 'profile-self',
    component: ProfilePage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/profile/:username',
    name: 'profile',
    component: ProfilePage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/marketplace',
    name: 'marketplace',
    component: MarketplacePage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },

  {
    path: '/marketplace/manage-listings',
    name: 'manage-mp-listings',
    component: ManageListingsPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/marketplace/listings/create',
    name: 'create-mp-listing',
    component: PostListingPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/marketplace/listings/edit/:id',
    name: 'edit-mp-listing',
    component: PostListingPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/marketplace/listings/details/:slug',
    name: 'mp-listing-details',
    component: ListingDetailsPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/story-worlds/create',
    name: 'create-story-world',
    component: CreateStoryWorld,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/story-worlds/create/draft/:id',
    name: 'draft-story-world',
    component: CreateStoryWorld,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/story-worlds/edit/:id',
    name: 'edit-world',
    component: CreateStoryWorld,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/story-worlds/manage/:id',
    name: 'manage-world',
    component: ManageWorldsPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/story-worlds/:slug',
    name: 'world-details',
    component: WorldDetails,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/story-worlds/drafts/:id',
    name: 'world-draft-details',
    component: WorldDetails,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/story-worlds',
    name: 'worlds',
    component: WorldsPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/story-worlds/explore',
    name: 'worlds-tabs',
    component: StoryWorldTabs,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    // is this still used?
    path: '/story-worlds/chat/:id',
    name: 'world-chat-room',
    component: WorldChatsPage,
    meta: { requireAuth: true, isPublic: false, layout: 'chat' },
  },
  {
    path: '/posts',
    name: 'posts-tabs',
    component: BlabsTabsPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/posts/create2',
    name: 'create-post2',
    component: CreatePostPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/posts/edit2/:id',
    name: 'edit-post2',
    component: CreatePostPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/posts/create',
    name: 'create-post',
    component: CreatePostPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/posts/edit/:id',
    name: 'edit-post',
    component: CreatePostPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/posts/:id',
    name: 'post-details',
    component: BlabDetailsPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/quizzes',
    name: 'quizzes',
    component: QuizzesPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/quizzes/user-history',
    name: 'quizzes-history',
    component: UsersQuizzesPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/folders/create',
    name: 'create-collection',
    component: CreateCollectionPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/folders/edit/:id',
    name: 'edit-collection',
    component: CreateCollectionPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/folders/:slug',
    name: 'collection-details',
    component: CollectionPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/raffles',
    name: 'raffles',
    component: RafflesPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/raffles/create',
    name: 'create-raffle',
    component: SubmitRaffle,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/raffles/edit/:id',
    name: 'edit-raffle',
    component: SubmitRaffle,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/raffles/manage',
    name: 'manage-raffle',
    component: ManageRaffles,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/raffles/details/thank-you/:id',
    name: 'thanks-raffle',
    component: RaffleDetailsPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/raffles/:slug',
    name: 'raffle-details',
    component: RaffleDetailsPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/bookmarks/create',
    name: 'create-bookmark-collection',
    component: CreateBookmarkCollectionPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/bookmarks/edit/:id',
    name: 'edit-bookmark-collection',
    component: CreateBookmarkCollectionPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },

  {
    path: '/bookmarks/details/:slug',
    name: 'bookmark-collection-details',
    component: BookmarkCollectionPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },

  {
    path: '/sections/:slug',
    name: 'homesection-details',
    component: SectionDetailsPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },

  {
    path: '/marketplace/user-listings/:username',
    name: 'mp-user-listings',
    component: UserListingsPage,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/events',
    name: 'events',
    component: EventsPagePreview,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/events',
    name: 'events-preview',
    component: EventsPagePreview,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/events/group/:id',
    name: 'event-group',
    component: EventGroupPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/customize/character/:id',
    name: 'character-stylize',
    component: CustomizCharacterProfilePage,
    meta: { requireAuth: true, isPublic: false, back: false },
  },
  {
    path: '/customize/character-draft/:id',
    name: 'character-draft-stylize',
    component: CustomizCharacterProfilePage,
    meta: { requireAuth: true, isPublic: false, back: false },
  },

  {
    path: '/events/event/:slug',
    name: 'event-details-preview',
    component: EventDetailPagePreview,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/events/task/:id',
    name: 'event-task',
    component: EventTaskPagePreview,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/events/poll/:id',
    name: 'event-poll',
    component: EventTaskPollPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },

  {
    path: '/events/rewards',
    name: 'event-rewards',
    component: EventRewardsPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/social-spaces',
    name: 'social-space',
    component: SocialSpacesPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/social-spaces/trending',
    name: 'social-space-trending',
    component: SocialSpaces,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/top',
    name: 'social-space-top',
    component: SocialSpaces,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/latest',
    name: 'social-space-latest',
    component: SocialSpaces,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/manage/:id',
    name: 'manage-social-space',
    component: ManageSocialSpacePage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/social-spaces/chatroom/:id',
    name: 'social-space-chatroom',
    component: SocialSpaceChatroomPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
      layout: 'chat',
    },
  },
  {
    path: '/social-spaces/chatroom/trending',
    name: 'social-space-chatroom-trending',
    component: SocialSpacesChatRooms,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/chatroom/top',
    name: 'social-space-chatroom-top',
    component: SocialSpacesChatRooms,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/chatroom/latest',
    name: 'social-space-chatroom-latest',
    component: SocialSpacesChatRooms,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/chatroom/details/:id',
    name: 'chatroom-details',
    component: ChatRoomDetailsPage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/social-spaces/user-spaces',
    name: 'user-spaces',
    component: UserSpacesPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/social-spaces/create',
    name: 'create-social-space',
    component: CreateSocialSpacePage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/social-spaces/create/:id',
    name: 'edit-social-space',
    component: CreateSocialSpacePage,
    meta: { requireAuth: true, isPublic: false, back: false, layout: 'default' },
  },
  {
    path: '/social-spaces/user-chatrooms',
    name: 'user-chatrooms',
    component: UserSpacesPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  // This is for maintaining old links
  {
    path: '/social-spaces/details/:slug',
    redirect: (to: any) => {
      return { name: 'social-space-details', params: { slug: to.params.slug } };
    },
    name: 'social-space-details-old',
    component: SocialSpaceDetails,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/social-spaces/:slug',
    name: 'social-space-details',
    component: SocialSpaceDetails,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/account',
    name: 'account',
    component: AccountPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/account/email/:token',
    name: 'email-settings',
    component: EmailSettingsPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/account/user-settings',
    name: 'user-settings',
    component: UserPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/account/settings',
    name: 'account-settings',
    component: AccountSettingsPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/account/blocked-users',
    name: 'blocked-users',
    component: BlockedUsersPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/presets',
    name: 'presets',
    component: PresetsPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/fanwork',
    name: 'fan-work',
    component: FanWorkPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/characters',
    name: 'characters',
    component: CharactersPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/my-creations',
    name: 'my-creations',
    component: MyCreationsPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/drafts',
    name: 'drafts',
    component: DraftPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/my-reactions',
    name: 'reactions',
    component: ReactionPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/drafts/characters/:id',
    name: 'char-draft-details',
    component: CharacterProfileNewPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: InventoryPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: true,
    },
  },

  {
    path: '/characters/featured',
    name: 'featured-characters',
    component: FeaturedCharactersPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/characters/featured-visuals',
    name: 'featured-visuals',
    component: FeaturedVisualsPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },

  {
    path: '/character/:slug/posts',
    name: 'char-posts',
    component: CharacterBlabs,
    meta: { requireAuth: false, isPublic: true, back: false, layout: 'default' },
  },
  {
    path: '/rolechats',
    name: 'rolechats',
    component: RolechatsPage,
    meta: { requireAuth: true, layout: 'default' },
  },

  {
    path: '/rolechats/requests/:id',
    name: 'rolechat-req-details',
    component: RolechatRequestDetailsPage,
    meta: { requireAuth: true, layout: 'default' },
  },

  {
    path: '/rolechats/:id',
    name: 'rolechat-room',
    component: RolechatRoomPage,
    meta: { requireAuth: true, layout: 'chat' },
  },

  {
    path: '/original-character',
    name: 'original-character',
    component: OriginalCharacterPage,
    meta: {
      isPublic: true,
      layout: 'no-auth',
    },
  },
  {
    path: '/original-character/:slug',
    name: 'original-character-category',
    component: OriginalCharacterCategoryPage,
    meta: {
      isPublic: true,
      layout: 'no-auth',
    },
  },
  {
    path: '/original-character-art',
    name: 'original-character-art',
    component: OriginalCharacterArtPage,
    meta: {
      isPublic: true,
      layout: 'no-auth',
    },
  },
  {
    path: '/fandoms',
    name: 'fandoms',
    component: FandomIndexPage,
    meta: {
      isPublic: true,
      layout: 'no-auth',
    },
  },
  {
    path: '/fandoms/:slug',
    name: 'fandom',
    component: FandomPage,
    meta: {
      isPublic: true,
      layout: 'no-auth',
    },
  },
  {
    path: '/giveaway',
    redirect: () => '/code',
    meta: { requireAuth: true, layout: 'default' },
  },
  {
    path: '/code',
    name: 'code',
    component: EnterCodePage,
    meta: { requireAuth: true, layout: 'default' },
  },
];

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      layout: 'no-auth',
      requireAuth: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage,
    meta: {
      isPublic: true,
      requireAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: {
      requireAuth: false,
      layout: 'default',
      title: 'Sign Up',
    },
  },
  {
    path: '/signup/s4p9igf0to',
    name: 'signup-invite0',
    component: SignupPage,
    meta: {
      requireAuth: false,
      layout: 'default',
      title: 'Sign Up',
    },
  },
  {
    path: '/signup/j89fp3wq2n',
    name: 'signup-invite',
    component: SignupPage,
    meta: {
      requireAuth: false,
      layout: 'no-auth',
      title: 'Sign Up',
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPasswordPage,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordPage,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsPage,
    meta: {
      isPublic: true,
      requireAuth: false,
    },
  },
  {
    path: '/upgrade-inter',
    name: 'upgrade-inter',
    component: UpgradePage,
    meta: {
      layout: 'empty',
      isPublic: false,
      requireAuth: true,
    },
  },
  {
    path: '/checkout-inter',
    name: 'checkout-inter',
    component: PurchaseCheckoutPageStripe,
    meta: {
      layout: 'empty',
      isPublic: false,
      requireAuth: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage,
    meta: {
      isPublic: true,
      requireAuth: false,
    },
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiesPage,
    meta: {
      isPublic: true,
      requireAuth: false,

      title: 'CharacterHub Cookie Settings',
    },
  },
  {
    path: '/dmca',
    name: 'dmca',
    component: DmcaPage,
    meta: {
      isPublic: true,
      requireAuth: false,
    },
  },
];

const newCharacterProfileRoutes = [
  {
    path: '/character/:slug',
    name: 'character-profile-new',
    component: CharacterProfileNewPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
  {
    path: '/character/draft/:id',
    name: 'character-profile-draft-new',
    component: CharacterProfileNewPage,
    meta: {
      requireAuth: false,
      isPublic: true,
      back: false,
    },
  },
];

const newCharacterEditorRoutes = [
  {
    path: '/drafts/characters/edit/:id',
    name: 'edit-char-draft',
    component: CharacterEditorPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/editor/character/:id',
    name: 'character-editor',
    component: CharacterEditorPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/editor/character/draft/:id',
    name: 'draft-character-editor',
    component: CharacterEditorPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/editor/character',
    name: 'character-creator',
    component: CharacterEditorPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/editor/character/preset',
    name: 'preset-creator',
    component: CharacterEditorPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
  {
    path: '/editor/character/preset/:id',
    name: 'preset-editor',
    component: CharacterEditorPage,
    meta: {
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
];

const moderationRoutes = [
  {
    path: '/moderation',
    name: 'moderation',
    component: ModerationPanelIndex,
    meta: {
      layout: 'empty',
      requireAuth: true,
      isPublic: false,
      back: false,
    },
  },
];

export default <RouterConfig>{
  routes: (_routes) => [
    ...mainPages,
    ...authRoutes,
    ...newCharacterEditorRoutes,
    ...newCharacterProfileRoutes,
    ...otherRoutes,
    ...moderationRoutes,
  ],
};
