<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div></div>
          <div>
            <div class="title">Character Profile Ring</div>
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <ProfileAddOnsUserRing :character="character" :isDraft="isDraft" :profileRing="ring" @selected="userRing" />
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button class="save-btn" @click="save"> Save </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import { addCharacterProfileRing } from '@/shared/actions/characters';
import ProfileAddOnsUserRing from '@/shared/pages/profile/components/ProfileAddOnsUserRing.vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  isDraft: {
    type: Boolean,
    default: false,
  },
  character: {
    type: Object,
    default: {},
  },
});
const ring: any = ref({});
const character = toRef(props, 'character');
const emits = defineEmits(['close', 'updated']);
const loading = ref(true);
const isOpen = toRef(props, 'isOpen');
const { updateProfileRingStatus } = authStore();
const dismissModal = (val: any) => {
  emits('close', val);
};

watch(
  isOpen,
  () => {
    if (!isEmpty(character.value)) {
      ring.value = get(character.value, 'profile_ring');
    }
    loading.value = false;
  },
  { immediate: true }
);

const userRing = (value: any) => {
  ring.value = value;
};
const toggleProfileRingStatus = async () => {
  updateProfileRingStatus(true);
  await new Promise((resolve) => setTimeout(resolve, 200));
  updateProfileRingStatus(false);
  emits('updated');
};
const save = async () => {
  const id = get(ring.value, 'virtual_item.id') || ring.value.id;
  const payload = get(ring.value, 'icon')
    ? get(character.value, 'type') === 'draft'
      ? { char_draft_id: character.value.id }
      : { char_id: character.value.id }
    : get(character.value, 'type') === 'draft'
    ? { id: id, char_draft_id: character.value.id }
    : { id: id, char_id: character.value.id };
  await addCharacterProfileRing(payload);
  dismissModal('');
  toggleProfileRingStatus();
};
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
.save-btn
  --background: #00b4c5
  --border-radius: 11px
  text-transform: none
  color: white
.dark .save-btn
  color: white
.save-btn::part(native)
  color: white !important
.modal-inner-content
  background: var(--ion-background-color, #e6e6e6)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 16px
  font-weight: bold
  color: #214163
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
