<template>
  <div id="infoSection" class="character-profile-sections position-relative">
    <div v-for="(value, key) in reorderedSections" :key="key">
      <CollapsableSection
        v-if="sectionHasItems(key, value)"
        class="mb-4"
        :title="titleGetter(key, value)"
        :customize="customize"
        :item="key"
        :show-box="get(character, 'show_box')"
        :progress-id="get(character, 'box_progress_id')"
        :showBoxItem="randomShowBoxItem"
      >
        <div v-if="value.type === 'text'">
          <ContentSeeMore :text="value.body" :customize="customize" />
        </div>
        <div v-if="key === 'collections'">
          <InlineGallery :is-editor="false" parent-type="character" :parent-id="character?.id" :customize="customize" />
        </div>
        <div v-else-if="isHtml(value)">
          <ContentSeeMore :text="value" :customize="customize" />
        </div>
        <div v-else-if="key === 'spotifyLink'">
          <ClientOnly>
            <div v-for="(link, index) in musicLinks" :key="index">
              <div class="music-label" v-if="get(link, 'label')">
                {{ get(link, 'label') }}
              </div>
              <iframe
                v-if="get(link, 'link').includes('spotify.com/')"
                style="border-radius: 12px"
                :src="getSpotifyEmbedLink(get(link, 'link'))"
                width="100%"
                height="100"
                frameBorder="0"
                :allowfullscreen="false"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              ></iframe>
              <iframe
                v-else-if="isYoutubeVideoLinkValid(get(link, 'link'))"
                style="border-radius: 8px"
                class="mb-2"
                width="100%"
                height="120"
                :src="`https://www.youtube.com/embed/${getYoutubeVideoId(get(link, 'link'))}?rel=0&fs=0`"
                title="YouTube player"
                frameborder="0"
                donotallowfullscreen
                allow="clipboard-write; encrypted-media; web-share"
              >
              </iframe>
            </div>
          </ClientOnly>
        </div>
        <div v-else-if="key === 'featuredIn'">
          <FeaturedIn :value="value" :customize="customize" />
        </div>
        <div v-else-if="key === 'colors'">
          <Colors :value="colors" :customize="customize" />
        </div>
        <div v-else-if="key === 'moodboard'">
          <Moodboard :value="moodboard" :char-auth="character.author" />
        </div>
        <div v-else-if="key === 'about'">
          <About
            :char-auth="character.author"
            :about="about"
            :about-config="aboutConfig"
            :customize="customize"
            @fetch="fetchCharProf"
          />
        </div>
        <div v-else-if="isMoreAboutSection(key)">
          <About
            :char-auth="character.author"
            :about="aboutGetter(key)"
            :about-config="aboutConfigGetter(key)"
            :customize="customize"
            @fetch="fetchCharProf"
          />
        </div>
        <div v-else-if="key === 'stats'">
          <Stats :stats="stats" :stats-config="statsConfig" :customize="customize" />
        </div>
        <div v-else-if="key === 'description'">
          <ContentSeeMore :text="value" :customize="customize" />
        </div>
        <div v-else-if="key === 'relationshipsSection'">
          <Relationships :customize="customize" />
        </div>
        <div v-else-if="key === 'timeline'">
          <Timeline :customize="customize" :timeline="timeline" />
        </div>
        <div v-else-if="key === 'outfits'">
          <CharacterOutfits
            :is-editor="false"
            :used-key="key"
            parent-type="character"
            :parent-id="character?.id"
            :customize="customize"
          />
        </div>
        <div v-else-if="key === 'favorites'">
          <CharacterOutfits
            :is-editor="false"
            :used-key="key"
            parent-type="character"
            :parent-id="character?.id"
            :customize="customize"
          />
        </div>
        <div v-else-if="key === 'inventory'">
          <CharacterOutfits
            :is-editor="false"
            :used-key="key"
            parent-type="character"
            :parent-id="character?.id"
            :customize="customize"
          />
        </div>
        <div v-else-if="key === 'life_stages'">
          <CharacterOutfits
            :is-editor="false"
            :used-key="key"
            parent-type="character"
            :parent-id="character?.id"
            :customize="customize"
          />
        </div>
        <div v-else-if="key === 'qualities'">
          <Qualities :customize="customize" :qualities="qualities" :qualities-config="qualitiesConfig" />
        </div>
        <div v-else-if="key === 'trivia'">
          <Trivia :customize="customize" />
        </div>

        <div v-else-if="key === 'alignment_charts'">
          <CharacterAlignmentCharts :customize="customize" :charts="alignmentCharts" />
        </div>
      </CollapsableSection>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InlineGallery from '@/shared/components/Gallery/InlineGallery.vue';
import CollapsableSection from './CollapsableSection.vue';
import Colors from './Colors.vue';
import Qualities from './Qualities.vue';
import FeaturedIn from './FeaturedIn.vue';
import Moodboard from './Moodboard.vue';
import About from './About.vue';
import Stats from './Stats.vue';
import Relationships from './Relationships.vue';
import Timeline from './CharacterTimeline.vue';
import Trivia from './CharacterTrivia.vue';
import CharacterAlignmentCharts from './CharacterAlignmentCharts.vue';
import CharacterOutfits from '@/shared/components/Gallery/CharacterOutfits.vue';
import { Character } from '@/shared/types/static-types';
import { possibleSectionsLabelMap } from '@/shared/statics/new-character-profile';
import { unreorderableEditorKeys } from '@/shared/statics/constants';
import ContentSeeMore from '@/shared/components/ContentSeeMore.vue';
import { isYoutubeVideoLinkValid } from '@/shared/utils/string';

const props = defineProps({
  character: {
    type: Object,
    default: () => ({}),
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
});
const { getDefaultColor, getDefaultFont, getDefaultItalic, getDefaultBold } = useCharCustDefault(props);

const isMoreAboutSection = (key: string) => {
  return key.includes('moreAbout');
};

const character = toRef(props, 'character');
const emits = defineEmits(['fetch']);

const aboutGetter = (key: string) => {
  return character.value.info[key].body;
};

const aboutConfigGetter = (key: string) => {
  return character.value.info[`${key}Config`];
};

const titleGetter = (key: string, value: any) => {
  return possibleSectionsLabelMap[key] || value.title || '';
};

const aboutConfig = computed(() => {
  return character?.value?.info?.aboutConfig || {};
});

const qualitiesConfig = computed(() => {
  return character?.value?.info?.qualitiesConfig || {};
});

const statsConfig = computed(() => {
  return character?.value?.info?.statsConfig || {};
});

const imagesCount = computed(() => {
  try {
    return character.value.visual_collections_page.results.reduce(
      (acc: any, curr: any) => (acc += curr.images_page.results.length),
      0
    );
  } catch (error) {
    return 0;
  }
});

const sectionHasItems = (key: string, value: any) => {
  if (key === 'collections' && !imagesCount.value) return false;
  if (!value) return false;
  if (key === 'spotifyLink' && !musicLinks.value) return false;
  if (!Array.isArray(value) && typeof value !== 'object' && value) return true;
  if (Array.isArray(value) && value.length) return true;
  if (typeof value === 'object' && Object.keys(value).length > 0) {
    if (value.type === 'text' && !value.body) {
      return false;
    }
    return true;
  }

  return false;
};

const possibleSections = computed(() => {
  return omit(character?.value?.info, unreorderableEditorKeys as string[]);
});

const filterEmptyValues = (obj: any, curr: any) => {
  if (obj === null || obj === '') {
    return null;
  }
  if (Array.isArray(obj) && isEmpty(obj)) {
    return null;
  }
  if (curr === 'collections' && !imagesCount.value) {
    return null;
  }
  if (typeof obj === 'object' && isEmpty(obj)) {
    return null;
  }
  return obj;
};

const reorderedSections = computed(() => {
  // Move description to top in default ordering
  const defaultPossibleSections = Array.from(Object.keys(possibleSections.value));
  const index = defaultPossibleSections.indexOf('description');
  defaultPossibleSections.unshift(defaultPossibleSections.splice(index, 1)[0]);

  const order: string[] = character?.value?.info?.order || defaultPossibleSections;

  let reorderedSections: any = order.reduce((acc: Character['info'], current) => {
    return {
      ...acc,
      [current]: filterEmptyValues(possibleSections.value[current], current),
    };
  }, {} as Character['info']);

  reorderedSections = Object.fromEntries(Object.entries(reorderedSections).filter((item) => item[1] !== null));
  return reorderedSections;
});

const isHtml = (content: string) => {
  return `${content}`.includes('<');
};

const randomShowBoxItem = computed(() => {
  if (get(character.value, 'show_box')) {
    const keys = Object.keys(reorderedSections.value);
    const randomItem = keys[Math.floor(Math.random() * keys.length)];
    return randomItem;
  }
});

const colors = computed(() => {
  return character.value.info.colors;
});

const moodboard = computed(() => {
  return character.value.info.moodboard;
});

const about = computed(() => {
  return character.value.info.about;
});

const qualities = computed(() => {
  return character.value.info.qualities;
});

const timeline = computed(() => {
  return character.value.info?.timeline;
});

const alignmentCharts = computed(() => {
  return character.value.info?.alignment_charts;
});

const stats = computed(() => {
  return character.value.info.stats;
});

const fetchCharProf = () => {
  emits('fetch');
};
const musicLinks = computed(() => {
  const links = character.value?.info?.spotifyLink || [];
  return links;
});

const getSpotifyEmbedLink = (link: any) => {
  return link.replace(/^https:\/\/\w+.spotify.com/gi, 'https://open.spotify.com/embed') + '?utm_source=generator';
};

const getYoutubeVideoId = (link: any) => {
  const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  const r = link.match(rx);
  return r ? r[1] : '';
};
</script>

<style lang="sass" scoped>
.music-label
  font-weight: bold
  font-size: 16px
  margin-bottom: 5px
  color: v-bind(getDefaultColor)
  font-family: v-bind(getDefaultFont)
  font-weight: v-bind(getDefaultBold)
  font-style: v-bind(getDefaultItalic)
.character-profile-header
  color: #FFF
  border-radius: 20px
  overflow: hidden
  .profile-picture
    width: 191px
    height: 191px
    border-radius: 100px
    overflow: hidden
    border: 10px solid #FFF
    margin: -95px auto 0 auto
    z-index: 1
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .cover
    height: 241px
    overflow: hidden
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .rest
    text-align: center
    background: rgba(10, 9, 40, 0.95)
    margin-top: -95px
    padding: 95px 24px 12px 24px
  .edit-character
    margin-top: -95px
    width: calc(100% - 24px)
    .button
      text-transform: unset
      font-size: 14px
      font-weight: 500
  .owner
    margin-top: 24px
    text-align: start
    .weight-500
      font-weight: 700
  .actions
    margin-top: 24px
    width: 85%
    .one-button
      margin: 0 10px
      flex-grow: 1
      width: 100%
      ::v-deep
        .reacts, .like-btn
          width: 100%
        .button
          text-transform: unset !important
          --background: #AC7BE2 !important
  .tags
    margin-top: 24px
    width: 80%
  .quote
    margin-top: 24px
    font-weight: 700
    font-size: 16px
    font-style: italic
  .description
    margin-top: 15px
    font-size: 20px
    font-weight: 500
  .name
    margin-top: 24px
    font-size: 24px
    font-weight: 700
  .tags
    .tag
      background-color: #1A1A1A
      border-radius: 5px
      height: 20px
      margin: 0 2.5px
      font-size: 10px
      padding: 0 8px
</style>
