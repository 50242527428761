<template>
  <div id="imageupform" class="upload-form">
    <input id="imageupinput" ref="uploader" type="file" accept="image/*" class="d-none" :multiple="isMultiple" />

    <div @click="getPhoto">
      <slot>
        <ion-custom-button :loading="loading" class="upload-button" type="submit"> Upload </ion-custom-button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options, Emit, Prop, Watch } from 'vue-property-decorator';
import IonCustomButton from '@/shared/components/ion-custom-button.vue';
import { toast } from '@/shared/native';
import { Image } from '@/shared/types/static-types';
import { requestUploadImageUrl, sendImageToS3, sendImageUrlToBackend } from '@/shared/services/upload';
import { openSubscribeModal } from '@/shared/utils/modals';
import { watermarkImage, patchImage, getImageStorage } from '@/shared/actions/imagesCollections';
import { cameraFileToJsFile, cameraPhotoToJsFile } from '../../apps/mobile/utils/files';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Options({
  name: 'UploadForm',
  components: {
    IonCustomButton,
  },
})
export default class UploadForm extends Vue {
  @Prop({ default: constants.uploadMaxFileSizeMB }) maxFileSizeMb!: number;
  @Prop({ default: false }) fullImageInfo!: boolean;
  @Prop({ default: false }) isMultiple!: boolean;
  @Prop({ default: false }) fanwork!: boolean;
  @Prop({ default: false }) outfits!: string;
  @Prop({ default: true }) auto!: boolean;
  @Prop({ default: false }) saveWatermark!: boolean;
  @Prop({ default: false }) silent!: boolean;
  @Prop({ default: false }) restrictGif!: boolean; // can anyone upload a gif here? (if true, only premium users can)
  @Prop({ default: '' }) usedKey!: string;
  @Prop({ type: Boolean, default: false }) onlyBlobUrl!: boolean;

  public loading = false;
  public showPassword = false;
  public nextState = '/home/';
  public startUpload() {
    const document = useDocument();
    const elem = document.value?.getElementById('imageupinput');
    if (elem) elem.click();
  }

  @Watch('loading')
  @Emit('loading')
  loadingChanged(val: boolean) {
    return val;
  }

  public async getPhoto() {
    let fakeEvent = null as any;
    if (this.isMultiple) {
      try {
        const images = await Camera.pickImages({
          quality: 90,
        });
        const files = await Promise.all(images.photos.map((image) => cameraFileToJsFile(image)));
        fakeEvent = {
          target: {
            files,
          },
        };
      } catch (e) {
        if (String(e).includes('User cancelled photos app')) return;
        toast.show('Could not access camera roll.', 'nonative', 'danger');
      }
      if (fakeEvent) this.upload(fakeEvent);

      return;
    }
    try {
      const image = await Camera.getPhoto({ source: CameraSource.Photos, resultType: CameraResultType.DataUrl });
      const file = await cameraPhotoToJsFile(image);
      fakeEvent = {
        target: {
          files: [file],
        },
      };
    } catch (e) {
      if (String(e).includes('User cancelled photos app')) return;
      toast.show('Could not access camera roll.', 'nonative', 'danger');
    }
    if (fakeEvent) this.upload(fakeEvent);
  }

  public async uploadFile(file: File, uploadingSingleGif = false) {
    try {
      if (this.onlyBlobUrl && !uploadingSingleGif) {
        return URL.createObjectURL(file);
      }

      if (!this.auto) return;

      const threePostRequests = !isEmpty(this.usedKey)
        ? await requestUploadImageUrl({ image_type: this.usedKey, image_size: file.size })
        : await requestUploadImageUrl();
      // Returns {s, m, l}; these are three pre-signed requests
      // so you can upload a max of three sizes of an image

      // first upload to S3
      const statusCode = await sendImageToS3(threePostRequests.l.url, threePostRequests.l.fields, file);
      if (statusCode >= 400) throw new Error('Encountered an error while uploading image.');
      // piece together the CDN URL from the S3 key
      // then send the Image model information to server after confirming successful upload to S3

      if (this.outfits) {
        const imageUrl = `${threePostRequests.l.url}${threePostRequests.l.fields.key}`;
        !isEmpty(this.usedKey)
          ? await patchImage({ id: this.outfits, image: imageUrl, image_type: this.usedKey, image_size: file.size })
          : await patchImage({ id: this.outfits, image: imageUrl });
        let imageData;
        if (this.saveWatermark) {
          imageData = await watermarkImage(this.outfits);
        }

        if (this.$refs.uploader) {
          (this.$refs.uploader as HTMLInputElement).files = null;
        }

        return imageData;
      } else if (!this.fanwork) {
        let imageData: Image = !isEmpty(this.usedKey)
          ? await sendImageUrlToBackend(threePostRequests.l.fields.key, threePostRequests.l.url, {
              image_type: this.usedKey,
              image_size: file.size,
            })
          : await sendImageUrlToBackend(threePostRequests.l.fields.key, threePostRequests.l.url);

        if (this.saveWatermark) imageData = await watermarkImage(imageData.id || '');

        if (this.$refs.uploader) {
          (this.$refs.uploader as HTMLInputElement).files = null;
        }

        if (this.fullImageInfo) {
          return imageData;
        }

        return imageData.image;
      } else {
        const imageUrl = `${threePostRequests.l.url}${threePostRequests.l.fields.key}`;
        return imageUrl;
      }
    } catch (e: any) {
      if (!get(e.response.data, 'allow_upload', true)) {
        this.onStorageFullError();
        throw e;
      }
      if (e instanceof TypeError) {
        // Do nothing
        await toast.show(e.message, 'nonative', 'danger');
      } else {
        // Do nothing
        const error: any = e;
        const body = 'Could not upload file(s).';
        const message = error ? error.message || body : body;
        await toast.show(message, 'nonative', 'danger');
      }
    }
  }

  public toastShow(...args: any) {
    if (this.silent) return;
    return toast.show.apply(null, args);
  }

  public async fetchStorageDetails() {
    const data = await getImageStorage();
    if (!isEmpty(data)) {
      const { updateImageStorage } = authStore();
      updateImageStorage(data);
    }
  }

  @Emit('dismiss')
  public close(noContinue = false, url = '', fileType = '') {
    // if noContinue is true, skip post-upload behavior (ex: CropImageModal etc)
    // if it is false, do not close popover
    //    since the modal won't close properly if its containing popover is dismissed
    return [noContinue, url, fileType];
  }

  @Emit('storageFullError')
  public onStorageFullError() {}

  @Emit('uploaded')
  public async upload(e: any) {
    let uploadingSingleGif = false;
    const target = e.target as HTMLInputElement;
    if (!target.files || !target.files.length) {
      this.close(true);
      return;
    }
    if (target.files.length > 200) {
      await toast.show(`Too many images were selected for upload.`, 'nonative', 'danger');
      this.close(true);
      return;
    }

    this.loading = true;

    let files = target.files;
    const notUploaded = [] as File[];
    for (const imageFile of files) {
      if (this.maxFileSizeMb && this.maxFileSizeMb * 1024 * 1024 < imageFile.size) {
        notUploaded.push(imageFile);
        toast.show(`Image exceeds max size (${this.maxFileSizeMb}MB): ${imageFile.name}`, 'nonative', 'danger');
      }
      if (!imageFile.type.startsWith('image/')) {
        notUploaded.push(imageFile);
        toast.show(`File should be an image: ${imageFile.name}`, 'nonative', 'danger');
      }
      if (imageFile.type === 'image/gif') {
        const { subscriptionLevel } = authStore();
        if (this.restrictGif && !subscriptionLevel.value) {
          toast.show('Upgrade to Plus or Plus Ultra to use a GIF here!', 'nonative', 'primary');
          openSubscribeModal(null, 'uploadFormGifAttempted');
          notUploaded.push(imageFile);
        } else {
          uploadingSingleGif = true;
        }
      }
    }
    // Since FileList is read-only, we cannot filter it directly. Instead, we create a new array of Files.
    const filteredFilesArray = Array.from(files).filter((file) => !notUploaded.includes(file));
    // Convert the array back to a FileList object using the DataTransfer constructor.
    const dataTransfer = new DataTransfer();
    filteredFilesArray.forEach((file) => dataTransfer.items.add(file));
    files = dataTransfer.files;
    if (!files.length) {
      this.loading = false;
      this.close(true);
      return;
    }

    try {
      const imgUploads = map(files, (file) => this.uploadFile(file, uploadingSingleGif));
      const images = await Promise.all(imgUploads);
      const notUploadedLength = notUploaded.length;
      const filesLength = files.length;
      if (!notUploadedLength) {
        // all images were uploaded
        // multi-select = no crop modal
        if (filesLength > 1) this.toastShow(`Upload complete`, 'nonative', 'primary');
        else if (images[0]?.id || (typeof images[0] === 'string' && !images[0]?.includes('blob'))) {
          // single-select & not blob => not going to crop
          this.toastShow(`Upload complete`, 'nonative', 'primary');
        } // if cropping, do not show upload complete message
      } else {
        // not all images were uploaded
        this.toastShow(
          `${filesLength}/${filesLength + notUploadedLength} images were uploaded.`,
          'nonative',
          'primary'
        );
      }
      if (files.length === 1 && files[0].type === 'image/gif') {
        if (!this.auto) {
          files[0].arrayBuffer().then((arrayBuffer) => {
            const blob = new Blob([new Uint8Array(arrayBuffer)], { type: files[0].type });
            this.$emit('blob', blob);
          });
          this.loading = false;
          this.close(true);
          return;
        }
        this.loading = false;
        return images[0];
      }
      if (!isEmpty(this.usedKey)) this.fetchStorageDetails();
      if (!images.includes(undefined)) return this.isMultiple ? images : images[0];
    } catch (err: any) {
      console.error(err);
      await toast.show('Could not complete upload. Please try again later.', 'nonative', 'danger');
      this.close(true);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="sass" scoped>
.upload-button
  ::v-deep
    .button
      --border-radius: 5px !important
      --width: 100px
      height: 35px
      font-size: 13px !important
</style>
