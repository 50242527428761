import { ActionTree } from 'vuex';
import { RolechatState } from './types';
import { RootState } from '@/shared/store';
import { getRolechatNextPage, getRolechats, hasUnreadRolechatMsgs } from '@/shared/actions/rolechats';

export const actions: ActionTree<RolechatState, RootState> = {
  async getRolechats({ commit }) {
    const { results, ...paging } = await getRolechats();
    commit('SET_ROLECHATS', { rolechats: results, paging });
  },

  async rolechatsNextPage({ commit, state }) {
    const { rolechatsPaging } = state;
    const { results, ...paging } = await getRolechatNextPage(rolechatsPaging);
    commit('APPEND_ROLECHATS', { results, paging });
  },

  async getUnreadRolechatStatus({ commit }) {
    const resp = await hasUnreadRolechatMsgs();
    commit('SET_UNREAD_STATUS', { hasUnreadRolechats: get(resp, 'has_unread', false) });
  },

  async updateUnreadRolechatStatus({ commit }, status) {
    const resp = await hasUnreadRolechatMsgs();
    commit('SET_UNREAD_STATUS', { hasUnreadRolechats: status });
  },
};
